import {Button, Table} from "semantic-ui-react";
import React from "react";
import {Loading} from "../../../utils/components/Loading";
import {useAppSelector} from "../../../utils/store/store";
import {getUser} from "../../../utils/store/userSlice";
import Utils from "../../../utils/utils/Utils";
import {MySelect} from "../../../utils/components/MySelect";
import {MyInput} from "../../../utils/components/MyInput";
import {ApiRequest} from "../../../utils/ApiRequest";
import {ToastsStore} from "react-toasts";
import {MyDateInput} from "../../../utils/components/MyDateInput";
import {DisplayPDF} from "../../../utils/components/DisplayPDF";
import {Branch} from "../../settings/BranchManagement";
import {SaleOrder, SaleOrderItem, SystemUser} from "../../../utils/utils/Models";


interface DataStock {
    name: string
    id: number
    cost: number
    quantity: number
}

interface DataPerson {
    id: number
    name: string
}

interface Search {
    branch_id: number
    ordered_by: number
    min_date: string
    max_date: string
}

export default function SalesOrders(params: { branches: Branch[], users: SystemUser[] }) {
    const user = useAppSelector(getUser)
    const [showPDF, setShowPDF] = React.useState({show: false, url: ''})
    const [loading, setLoading] = React.useState({show: false, message: ""})

    const [data, setData] = React.useState<{ clients: DataPerson[], stocks: DataStock[] }>({clients: [], stocks: []})

    const initialSale: SaleOrder = {
        items: [], total_discount: '', sale_id: 0, total_price: 0,  ordered_by: user.user_id, username:user.user_name,
        client_id: 0, client_name: 'Cash Customer', sale_type: 'Cash', time_ordered: ''
    }
    const [search, setSearch] = React.useState<Search>({branch_id: user.branch_id, max_date: Utils.today(), min_date: Utils.today(), ordered_by: user.user_id})
    const [orders, setOrders] = React.useState(Array<SaleOrder>())
    const handle_search = (name: string, value: string | number) => {
        setSearch({...search, [name]: value})
    }

    const [order, setOrder] = React.useState<SaleOrder>(initialSale)
    const handle_sale = (name: string, value: string | number | SaleOrderItem[]) => {
        setOrder({...order, [name]: value})
    }

    const load_sales = () => {
        setLoading({message: "Loading sale orders, please wait", show: true})
        ApiRequest.get_sales_orders({branch_id: search.branch_id, min_date: search.min_date, max_date: search.max_date, ordered_by: search.ordered_by})
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setOrders(response.data['orders'])
                } else {
                    ToastsStore.error("Error while loading sales, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while loading sales, please retry")
            })
    }

    const make_sale = () => {
        if (order.items.length === 0) {
            ToastsStore.error("No items have been added in cart")
        } else if (order.total_discount !== "" && !Utils.is_valid_number(order.total_discount.toString().trim())) {
            ToastsStore.error("Enter a valid discount")
        } else if (parseFloat(order.total_discount.toString().trim()) < 0) {
            ToastsStore.error("Discount cannot be less than 0")
        } else {
            let error = ""
            order.items.forEach((aItem) => {
                if (error !== "") {
                    const aStock = data.stocks.filter((aStock) => aStock.id === aItem.stock_id)[0]
                    if (!Utils.is_valid_number(aItem.quantity.toString()) || parseFloat(aItem.quantity.toString()) <= 0) {
                        error = `Enter a valid stock quantity for ${aItem.name}`
                    } else if (parseFloat(aItem.quantity.toString()) > aStock.quantity) {
                        error = `There are only ${aStock.quantity} items available for ${aItem.name}`
                    }
                }
            })

            if (error !== "") {
                alert(error)
            } else if (order.total_price === "INVALID") {
                ToastsStore.error("Total sale price is invalid")
            } else if (parseFloat(order.total_price.toString()) < 0) {
                ToastsStore.error("Total sale price cannot be less than 0")
            } else {
                setLoading({show: true, message: 'Making sale, please wait'})
                ApiRequest.make_sale({
                    sale_id: order.sale_id,
                    client_id: order.client_id,
                    sale_type: order.sale_type,
                    total_price: parseFloat(order.total_price.toString().trim()),
                    total_discount: order.total_discount.toString().trim() == "" ? 0 : parseFloat(order.total_discount.toString().trim()),
                    items: JSON.stringify(order.items.map((aItem) => ({id: aItem.stock_id, quantity: aItem.quantity, price: aItem.unit_price, name: aItem.name}))),
                })
                    .then((response) => {
                        setLoading({message: "", show: false})
                        if (response.data.hasOwnProperty("code")) {
                            if (response.data.code === 1) {
                                setOrders([{...order, sale_id: response.data['sale_id'], time_ordered: response.data['time_ordered']}, ...orders,])
                                setOrder(initialSale);

                                let stocks: DataStock[] = [...data.stocks];
                                (response.data['stocks'] as DataStock[]).forEach((aStock) => stocks = stocks.map((bStock) => bStock.id === aStock.id ? aStock : bStock))
                                setData({...data, stocks: stocks})

                                ToastsStore.success("Sales order has been successfully made")
                            } else {
                                alert(response.data['msg'])
                            }
                        } else {
                            ToastsStore.error("Error while making sales order, please retry")
                        }
                    })
                    .catch(() => {
                        setLoading({message: "", show: false})
                        ToastsStore.error("Error while making sales order, please retry")
                    })
            }
        }
    }

    const initialise_sale = () => {
        setLoading({show: true, message: 'Initialising data, please wait'})
        ApiRequest.initialise_sale()
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setData({clients: response.data['clients'], stocks: response.data['stocks']})
                } else {
                    ToastsStore.error("Error while initialising data, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while initialising data, please retry")
            })
    }

    React.useEffect(() => {
        let total_price = "0"
        order.items.forEach((item) => {
            if (total_price !== "INVALID" && Utils.is_valid_number(item.quantity.toString()) && parseFloat(item.quantity.toString()) > 0) {
                total_price = (parseFloat(total_price.toString()) + (parseFloat(item.quantity.toString()) * item.unit_price)).toString()
            } else {
                total_price = "INVALID"
            }
        })

        if (total_price.toString() !== "INVALID" && Utils.is_valid_number(order.total_discount.toString())) {
            total_price = (parseFloat(total_price.toString()) - parseFloat(order.total_discount.toString())).toString()
        }

        setOrder({...order, total_price: total_price})
    }, [order.items, order.total_discount])

    React.useEffect(() => {
        initialise_sale()
    }, [])

    const display_sale = (aSale: SaleOrder, index: number) => {
        const rowCount = aSale.items.length
        return aSale.items.map((aItem, aIndex) =>
            aIndex === 0 ?
                <Table.Row key={`${aSale.sale_id}_${aIndex}`} onClick={() => setOrder(aSale)}>
                    <Table.Cell rowSpan={rowCount} style={{width: '40px'}} textAlign="center">{index + 1}</Table.Cell>
                    <Table.Cell rowSpan={rowCount} style={{width: '110px'}}>{aSale.username}</Table.Cell>
                    <Table.Cell rowSpan={rowCount} style={{width: '110px'}}>{aSale.client_name}</Table.Cell>

                    <Table.Cell style={{width: '100px'}}>{aSale.items[aIndex].name}</Table.Cell>
                    <Table.Cell style={{width: '40px'}}>{aSale.items[aIndex].quantity}</Table.Cell>
                    <Table.Cell style={{width: '60px'}}>
                        {
                            Utils.is_valid_number(aSale.items[aIndex].quantity.toString()) ?
                                (aSale.items[aIndex].unit_price * (parseFloat(aSale.items[aIndex].quantity.toString()))).toLocaleString() : '-'
                        }
                    </Table.Cell>

                    <Table.Cell rowSpan={rowCount} style={{width: '90px'}}>
                        {parseFloat(aSale.total_discount.toString()).toLocaleString()}
                    </Table.Cell>
                    <Table.Cell rowSpan={rowCount} style={{width: '90px'}}>
                        {parseFloat(aSale.total_price.toString()).toLocaleString()}
                    </Table.Cell>
                </Table.Row>
                :
                <Table.Row key={`${aSale.sale_id}_${aIndex}`}>
                    <Table.Cell style={{width: '100px'}}>{aSale.items[aIndex].name}</Table.Cell>
                    <Table.Cell style={{width: '40px'}}>{aSale.items[aIndex].quantity}</Table.Cell>
                    <Table.Cell style={{width: '60px'}}>
                        {
                            Utils.is_valid_number(aSale.items[aIndex].quantity.toString()) ?
                                (aSale.items[aIndex].unit_price * (parseFloat(aSale.items[aIndex].quantity.toString()))).toLocaleString() : '-'
                        }
                    </Table.Cell>
                </Table.Row>
        )
    }

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>
            <DisplayPDF show={showPDF.show} url={showPDF.url} close={() => setShowPDF({url: '', show: false})}/>

            <div className="content_bar">
                <div className="search_bar">
                    <div style={{width: '250px'}}>
                        <MySelect
                            name="branch_id" value={search.branch_id} placeholder="Search by branches"
                            change={(value) => setSearch({...search, branch_id: value as number, ordered_by: 0})}
                            options={[...params.branches.map((user) => ({text: user.branch_name, value: user.branch_id}))]}/>
                    </div>

                    <div style={{width: '250px'}}>
                        <MySelect
                            name="ordered_by" value={search.ordered_by} placeholder="Search by ordered by"
                            change={(value) => handle_search('ordered_by', value as string)}
                            options={[
                                ...params.users
                                    .filter((branch) => branch.branch_id === search.branch_id)
                                    .map((client) => ({text: client.username, value: client.user_id}))
                            ]}/>
                    </div>

                    <div>
                        <MyDateInput value={search.min_date} name="min_date" placeholder="Min Date" change={handle_search} maxDate={search.max_date}/>
                    </div>

                    <div>
                        <MyDateInput value={search.max_date} name="max_date" placeholder="Min Date" change={handle_search}
                                     minDate={search.min_date} maxDate={Utils.today()}/>
                    </div>
                </div>
                <div className="content_buttons">
                    <Button primary size='mini' icon='refresh' labelPosition="left" content="Initialise" onClick={initialise_sale}/>
                    <Button primary size='mini' icon='search' labelPosition="left" content="Search Sales" onClick={() => load_sales()}/>
                </div>
            </div>

            <div className="content_container">
                <div className="row h-100 m-0 w-100">
                    <div className="col-md-8 p-1 h-100">
                        <div className="window_content">
                            <Table celled striped compact={false} size='small' inverted color='grey' selectable structured>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell rowSpan={2} style={{width: '40px'}} textAlign="center">No.</Table.HeaderCell>
                                        <Table.HeaderCell rowSpan={2} style={{width: '110px'}}>Ordered By</Table.HeaderCell>
                                        <Table.HeaderCell rowSpan={2} style={{width: '110px'}}>Customer Name</Table.HeaderCell>
                                        <Table.HeaderCell colSpan={3} style={{width: '200px'}} textAlign="center">Items Sold</Table.HeaderCell>
                                        <Table.HeaderCell rowSpan={2} style={{width: '90px'}}>Discount</Table.HeaderCell>
                                        <Table.HeaderCell rowSpan={2} style={{width: '90px'}}>Sale Price</Table.HeaderCell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '100px'}}>Item Name</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '40px'}}>Quantity</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '60px'}}>Sale Price</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {orders.map((aSale, index) => display_sale(aSale, index))}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>

                    <div className="col-md-4 p-1 h-100">
                        <div className="window_content">
                            <div className="form_container" style={{display: "flex", flexDirection: 'column'}}>
                                <div className={'row mx-0'}>
                                    <div className={'col-12 px-0'}>
                                        {/*CLIENT NAME*/}
                                        <span className="label mt-0">Client Name</span>
                                        {
                                            order.sale_id === 0 ?
                                                <MySelect
                                                    name="client_id" value={order.client_id} placeholder="Select a customer"
                                                    change={(value, text) => setOrder({...order, client_name: text, client_id: value as number})}
                                                    options={[
                                                        {text: "Cash Customer", value: 0}, ...data.clients.map((client) => ({text: client.name, value: client.id}))]
                                                    }/>
                                                :
                                                <MyInput placeholder="Client Name" name="client_name"
                                                         value={order.client_id === 0 ? 'Cash Customer' : order.client_name}/>
                                        }
                                    </div>
                                </div>

                                <div className={'row mx-0'}>
                                    <div className={'col-6 pl-0 pr-1'}>
                                        {/*SOLD BY*/}
                                        <span className="label">Sale Type</span>
                                        <MySelect
                                            name="sale_type" value={order.sale_type} placeholder="Select sale type"
                                            change={(value) => handle_sale('sale_type', value as string)}
                                            options={[
                                                {text: "Cash Sale", value: 'Cash'},
                                                {text: "Credit Sale", value: 'Credit'},
                                                {text: "Insurance Sale", value: 'Insurance'}
                                            ]}/>
                                    </div>
                                    <div className={'col-6 pl-1 pr-0'}>
                                        <span className="label">Total Discount on Sales</span>
                                        <MyInput placeholder="Enter total discount added" name="total_discount"
                                                 value={order.total_discount.toString()} change={handle_sale}/>
                                    </div>
                                </div>

                                <span className="label">Sale item to add to cart</span>
                                <MySelect
                                    options={[
                                        {text: "Select an item", value: 0},
                                        ...data.stocks
                                            .filter((aItem) => !order.items.map((bItem) => (bItem.stock_id)).includes(aItem.id))
                                            .map((stock) => ({text: stock.name, value: stock.id}))
                                    ]}
                                    name="item_id" value={0} placeholder="Select an item"
                                    change={(value) => {
                                        if (value !== undefined && value > 0) {
                                            const stock = data.stocks.filter((aStock) => aStock.id === value)[0]
                                            setOrder({
                                                ...order, items: [
                                                    {name: stock.name, stock_id: stock.id, quantity: 1, unit_price: stock.cost, available: stock.quantity},
                                                    ...order.items
                                                ]
                                            })
                                        }
                                    }}/>

                                <div style={{flex: 1}} className={'pt-3 pb-2'}>
                                    <div className="window_content" style={{background: 'whitesmoke'}}>
                                        <Table celled striped compact={false} size='small' inverted color='grey' selectable>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell style={{width: '35px'}} textAlign="center"></Table.HeaderCell>
                                                    <Table.HeaderCell style={{width: '100px'}}>Item Name</Table.HeaderCell>
                                                    <Table.HeaderCell style={{width: '45px'}} textAlign="center">Available</Table.HeaderCell>
                                                    <Table.HeaderCell style={{width: '65px'}} textAlign="center">Quantity</Table.HeaderCell>
                                                    <Table.HeaderCell style={{width: '65px'}} textAlign="center">Sale Price</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                {
                                                    order.items.map((aItem) =>
                                                        <Table.Row key={aItem.stock_id}>
                                                            <Table.Cell style={{width: '35px'}} textAlign="center">
                                                                <Button
                                                                    negative size='mini' icon='minus' compact
                                                                    onClick={() => setOrder({
                                                                        ...order, items: order.items.filter((bItem) => bItem.stock_id !== aItem.stock_id)
                                                                    })}/>
                                                            </Table.Cell>
                                                            <Table.Cell style={{width: '100px'}}>{aItem.name}</Table.Cell>
                                                            <Table.Cell style={{width: '45px'}} textAlign="center">{aItem.available}</Table.Cell>
                                                            <Table.Cell style={{width: '65px'}}>
                                                                <MyInput
                                                                    placeholder="Quantity" name="quantity" value={aItem.quantity.toString()}
                                                                    style={{textAlign: 'center'}}
                                                                    change={(name, value) =>
                                                                        setOrder({
                                                                            ...order,
                                                                            items: order.items.map((bItem) => bItem.stock_id === aItem.stock_id ?
                                                                                {...bItem, quantity: value} : bItem
                                                                            )
                                                                        })
                                                                    }/>
                                                            </Table.Cell>
                                                            <Table.Cell style={{width: '65px'}} textAlign="center">
                                                                {
                                                                    Utils.is_valid_number(aItem.quantity.toString()) ?
                                                                        (parseFloat(aItem.quantity.toString()) * aItem.unit_price).toLocaleString() : "-"
                                                                }
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    )
                                                }
                                            </Table.Body>
                                        </Table>
                                    </div>
                                </div>

                                <div className='total_sale_price'>
                                    {order.total_price === "INVALID" ? order.total_price : (parseFloat(order.total_price.toString())).toLocaleString()}
                                </div>
                            </div>

                            <div className="button_container">
                                <div className="row m-0">
                                    <div className="col-6 pl-0 pr-1">
                                        <Button negative icon="trash" labelPosition="left" size="mini" fluid
                                                content="Clear Data" onClick={() => setOrder(initialSale)}/>
                                    </div>
                                    <div className="col-6 pl-1 pr-0">
                                        <Button positive icon="save" labelPosition="left" size="mini" fluid content="Place Order"
                                                disabled={order.sale_id > 0} onClick={make_sale}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
