import {MyDateInput} from "../../../utils/components/MyDateInput";
import Utils from "../../../utils/utils/Utils";
import React from "react";
import {MySelect} from "../../../utils/components/MySelect";
import {useAppSelector} from "../../../utils/store/store";
import {getUser} from "../../../utils/store/userSlice";
import {Branch} from "../../settings/BranchManagement";
import {Button, Form, Modal, Table, TextArea} from "semantic-ui-react";
import {SaleOrder} from "../../../utils/utils/Models";
import {ApiRequest} from "../../../utils/ApiRequest";
import {ToastsStore} from "react-toasts";
import {Loading} from "../../../utils/components/Loading";
import {DisplayPDF} from "../../../utils/components/DisplayPDF";
import moment from "moment";
import {MyInput} from "../../../utils/components/MyInput";

interface ShowOrder {
    cancel?: {
        sale_id: number
        client_name: string
        time_ordered: string
        cancel_reason: string
    }
    verify?: {
        sale_id: number
        client_name: string
        total_price: number
        time_ordered: string
        cash_collected: number
        total_discount: number | string
        sale_type: 'Cash' | 'Credit' | 'Insurance'
    }
}

export default function OrdersPayment(params: { branches: Branch[] }) {
    const user = useAppSelector(getUser)

    const [saving, setSaving] = React.useState(false)
    const [showPDF, setShowPDF] = React.useState({show: false, url: ''})

    const [search, setSearch] = React.useState<{ branch_id: number, ordered_by: number, min_date: string, max_date: string }>({
        branch_id: user.branch_id, max_date: Utils.today(), min_date: Utils.today(), ordered_by: 0
    })
    const handle_search = (name: string, value: string | number) => {
        setSearch({...search, [name]: value})
    }

    const [loading, setLoading] = React.useState({show: false, message: ""})
    const [orders, setOrders] = React.useState(Array<SaleOrder>())
    const [showOrder, setShowOrder] = React.useState<ShowOrder>({})

    const search_orders = () => {
        setLoading({message: "Loading pending orders, please wait", show: true})
        ApiRequest.get_sales_orders({branch_id: search.branch_id, min_date: search.min_date, max_date: search.max_date, ordered_by: search.ordered_by})
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setOrders(response.data['orders'])
                } else {
                    ToastsStore.error("Error while pending orders, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while loading pending orders, please retry")
            })
    }

    const cancel_order = () => {
        if (showOrder.cancel !== undefined) {
            if (showOrder.cancel.cancel_reason.trim().length < 5) {
                ToastsStore.error("Enter a valid reason for cancelling order")
            } else {
                setSaving(true)
                ApiRequest.cancel_order({cancel_reason: showOrder.cancel.cancel_reason, sale_id: showOrder.cancel.sale_id})
                    .then((response) => {
                        setSaving(false)
                        if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                            setOrders(orders.filter((order) => order.sale_id !== showOrder.cancel?.sale_id))
                            ToastsStore.success("Order cancelled successfully")
                            setShowOrder({})
                        } else {
                            ToastsStore.error("Error while cancelling order, please retry")
                        }
                    })
                    .catch(() => {
                        setSaving(false)
                        ToastsStore.error("Error while cancelling order, please retry")
                    })
            }
        }
    }

    const verify_order = () => {
        if (showOrder.verify !== undefined) {
            if (showOrder.verify.total_discount !== "" && !Utils.is_valid_number(showOrder.verify.total_discount.toString().trim())) {
                ToastsStore.error("Enter a valid discount")
            } else if (parseFloat(showOrder.verify.total_discount.toString().trim()) < 0) {
                ToastsStore.error("Discount cannot be less than 0")
            } else {
                setSaving(true)
                ApiRequest.verify_order({
                    sale_id: showOrder.verify.sale_id,
                    sale_type: showOrder.verify.sale_type,
                    total_discount: showOrder.verify.total_discount.toString().trim() == "" ? 0 : parseFloat(showOrder.verify.total_discount.toString().trim()),
                })
                    .then((response) => {
                        setSaving(false)
                        if (response.data.hasOwnProperty("code")) {
                            if (response.data.code === 1) {
                                setOrders(orders.filter((order) => order.sale_id !== showOrder.verify?.sale_id))
                                ToastsStore.success("Order dispatched successfully")
                                setShowOrder({})
                            } else {
                                ToastsStore.error(response.data['msg'] ?? "Error while dispatching order, please retry")
                            }
                        } else {
                            ToastsStore.error("Error while dispatching order, please retry")
                        }
                    })
                    .catch(() => {
                        setSaving(false)
                        ToastsStore.error("Error while dispatching order, please retry")
                    })
            }
        }
    }

    const display_order = (aSale: SaleOrder, index: number) => {
        const rowCount = aSale.items.length
        return aSale.items.map((aItem, aIndex) =>
            aIndex === 0 ?
                <Table.Row key={`${aSale.sale_id}_${aIndex}`}>
                    <Table.Cell rowSpan={rowCount} style={{width: '60px'}} textAlign="center">
                        <Button negative size='mini' icon='ban' compact
                                onClick={() => setShowOrder({
                                    cancel: {
                                        cancel_reason: '',
                                        sale_id: aSale.sale_id,
                                        client_name: aSale.client_name,
                                        time_ordered: aSale.time_ordered,
                                    }
                                })}/>
                        <Button primary size='mini' icon='info circle' compact
                                onClick={() => setShowOrder({
                                    verify: {
                                        sale_id: aSale.sale_id,
                                        sale_type: aSale.sale_type,
                                        time_ordered: aSale.time_ordered,
                                        client_name: aSale.client_name,
                                        total_discount: aSale.total_discount,
                                        cash_collected: aSale.total_price as number,
                                        total_price: aSale.total_price as number
                                    }
                                })}/>
                    </Table.Cell>
                    <Table.Cell rowSpan={rowCount} style={{width: '30px'}} textAlign="center">{index + 1}</Table.Cell>
                    <Table.Cell rowSpan={rowCount} style={{width: '120px'}}>{moment(aSale.time_ordered).format("ll")}</Table.Cell>
                    <Table.Cell rowSpan={rowCount} style={{width: '150px'}}>{aSale.client_id === 0 ? 'Cash Customer' : aSale.client_name}</Table.Cell>
                    <Table.Cell rowSpan={rowCount} style={{width: '150px'}}>{aSale.username}</Table.Cell>
                    <Table.Cell rowSpan={rowCount} style={{width: '60px'}}>{aSale.sale_type}</Table.Cell>

                    <Table.Cell style={{width: '120px'}}>{aSale.items[aIndex].name}</Table.Cell>
                    <Table.Cell style={{width: '40px'}}>{aSale.items[aIndex].quantity}</Table.Cell>
                    <Table.Cell style={{width: '60px'}}>
                        {
                            Utils.is_valid_number(aSale.items[aIndex].quantity.toString()) ?
                                (aSale.items[aIndex].unit_price * (parseFloat(aSale.items[aIndex].quantity.toString()))).toLocaleString() : '-'
                        }
                    </Table.Cell>

                    <Table.Cell rowSpan={rowCount} style={{width: '90px'}}>{parseFloat(aSale.total_discount.toString()).toLocaleString()}</Table.Cell>
                    <Table.Cell rowSpan={rowCount} style={{width: '90px'}}>{parseFloat(aSale.total_price.toString()).toLocaleString()}</Table.Cell>
                </Table.Row>
                :
                <Table.Row key={`${aSale.sale_id}_${aIndex}`}>
                    <Table.Cell style={{width: '120px'}}>{aSale.items[aIndex].name}</Table.Cell>
                    <Table.Cell style={{width: '40px'}}>{aSale.items[aIndex].quantity}</Table.Cell>
                    <Table.Cell style={{width: '60px'}}>
                        {
                            Utils.is_valid_number(aSale.items[aIndex].quantity.toString()) ?
                                (aSale.items[aIndex].unit_price * (parseFloat(aSale.items[aIndex].quantity.toString()))).toLocaleString() : '-'
                        }
                    </Table.Cell>
                </Table.Row>
        )
    }

    React.useEffect(() => {
        if (showOrder.verify !== undefined) {
            let cash_collected = showOrder.verify.total_price
            if (Utils.is_valid_number(showOrder.verify.total_discount.toString())) {
                cash_collected = cash_collected - parseFloat(showOrder.verify.total_discount.toString())
            }
            setShowOrder({verify: {...showOrder.verify, cash_collected: cash_collected}})
        }
    }, [showOrder.verify?.total_discount])

    React.useEffect(() => {
        search_orders()
    }, [])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>
            <DisplayPDF show={showPDF.show} url={showPDF.url} close={() => setShowPDF({url: '', show: false})}/>

            <div className="content_bar">
                <div className="search_bar">
                    <div>
                        <MyDateInput value={search.min_date} name="min_date" placeholder="Min Date" change={handle_search} maxDate={search.max_date}/>
                    </div>

                    <div>
                        <MyDateInput value={search.max_date} name="max_date" placeholder="Min Date" change={handle_search}
                                     minDate={search.min_date} maxDate={Utils.today()}/>
                    </div>

                    <div style={{width: '250px'}}>
                        <MySelect
                            name="branch_id" value={search.branch_id} placeholder="Search by branch"
                            change={(value) => handle_search('branch_id', value as string)}
                            options={params.branches.map((aBranch) => ({text: aBranch.branch_name, value: aBranch.branch_id}))}/>
                    </div>
                </div>

                <div className="content_buttons">
                    <Button primary size='mini' icon='search' labelPosition="left" content="Search Orders" onClick={() => search_orders()}/>
                </div>
            </div>

            <div className="content_container">
                <div className="row h-100 m-0 w-100">
                    <div className="col-md-12 p-1 h-100">
                        <div className="window_content">
                            <Table celled striped compact={false} size='small' inverted color='grey' selectable structured>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell rowSpan={2} style={{width: '60px'}} textAlign="center"></Table.HeaderCell>
                                        <Table.HeaderCell rowSpan={2} style={{width: '30px'}} textAlign="center">No.</Table.HeaderCell>
                                        <Table.HeaderCell rowSpan={2} style={{width: '120px'}}>Time Ordered</Table.HeaderCell>
                                        <Table.HeaderCell rowSpan={2} style={{width: '150px'}}>Client Name</Table.HeaderCell>
                                        <Table.HeaderCell rowSpan={2} style={{width: '150px'}}>Ordered By</Table.HeaderCell>
                                        <Table.HeaderCell rowSpan={2} style={{width: '60px'}}>Order Type</Table.HeaderCell>
                                        <Table.HeaderCell colSpan={3} style={{width: '220px'}} textAlign="center">Items Sold</Table.HeaderCell>
                                        <Table.HeaderCell rowSpan={2} style={{width: '90px'}}>Discount</Table.HeaderCell>
                                        <Table.HeaderCell rowSpan={2} style={{width: '90px'}}>Sale Price</Table.HeaderCell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '120px'}}>Item Name</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '40px'}}>Quantity</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '60px'}}>Sale Price</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {orders.map((aSale, index) => display_order(aSale, index))}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>

            <Modal open={showOrder.cancel !== undefined} basic size='mini' centered={false} closeOnDimmerClick={false}
                   closeOnDocumentClick={false}>
                <div className="modal_container">
                    <span className="form_header" dangerouslySetInnerHTML={{__html: 'Cancel Order'}}/>

                    <div className="form_container has_header">
                        <span className="label mt-0">Customer Name</span>
                        <span className="label_text">{showOrder.cancel?.client_name}</span>

                        <span className="label">Time Ordered</span>
                        <span className="label_text">{moment(showOrder.cancel?.time_ordered).format('lll')}</span>

                        <Form className="mt-3">
                            <span className="label">Enter reason for cancelling</span>
                            <TextArea
                                placeholder='Enter reason for cancelling' value={showOrder.cancel?.cancel_reason} rows={5}
                                onChange={(event, data) => setShowOrder({cancel: {...showOrder.cancel!, cancel_reason: data.value as string}})}/>
                        </Form>
                    </div>

                    <div className="button_container">
                        <div className="row m-0">
                            <div className="col-6 pl-0 pr-1">
                                <Button negative icon="close" labelPosition="left" size="mini" fluid content='Close Window'
                                        onClick={() => setShowOrder({})} disabled={saving}/>
                            </div>
                            <div className="col-6 pl-1 pr-0">
                                <Button positive icon="save" labelPosition="left" size="mini" fluid
                                        content="Cancel Order" onClick={cancel_order} disabled={saving} loading={saving}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal open={showOrder.verify !== undefined} basic size='mini' centered={false} closeOnDimmerClick={false}
                   closeOnDocumentClick={false}>
                <div className="modal_container">
                    <span className="form_header" dangerouslySetInnerHTML={{__html: 'Verify Order'}}/>

                    <div className="form_container has_header">
                        <span className="label mt-0">Customer Name</span>
                        <span className="label_text mb-2">{showOrder.verify?.client_name}</span>

                        <span className="label">Time Ordered</span>
                        <span className="label_text">{moment(showOrder.verify?.time_ordered).format('lll')}</span>

                        <span className="label">Sale Type</span>
                        <MySelect
                            name="sale_type" value={showOrder.verify?.sale_type} placeholder="Select sale type"
                            change={(value) => setShowOrder({verify: {...showOrder.verify!, sale_type: value as 'Cash' | 'Credit' | 'Insurance'}})}
                            options={[{text: "Cash Sale", value: 'Cash'}, {text: "Credit Sale", value: 'Credit'}, {text: "Insurance Sale", value: 'Insurance'}]}/>

                        <span className="label">Total Price</span>
                        <span className="label_text">{(showOrder.verify?.total_price ?? '').toLocaleString()}</span>

                        <span className="label">Enter sale discount</span>
                        <MyInput placeholder="Enter discount amount" name="amount" value={(showOrder.verify?.total_discount ?? '').toString()}
                                 change={(name, value) => setShowOrder({verify: {...showOrder.verify!, total_discount: value}})}/>

                        <div style={{textAlign: 'center', fontSize: '30px', fontWeight: 'bold', marginTop: '30px', marginBottom: '5px', color: '#000000'}}>
                            {(showOrder.verify?.cash_collected ?? '').toLocaleString()}
                        </div>
                    </div>

                    <div className="button_container">
                        <div className="row m-0">
                            <div className="col-6 pl-0 pr-1">
                                <Button negative icon="close" labelPosition="left" size="mini" fluid content='Close Window'
                                        onClick={() => setShowOrder({})} disabled={saving}/>
                            </div>
                            <div className="col-6 pl-1 pr-0">
                                <Button positive icon="save" labelPosition="left" size="mini" fluid
                                        content="Dispatch Order" onClick={verify_order} disabled={saving} loading={saving}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
