import {Measurement} from "./DoctorMeasurements";
import React from "react";
import {Loading} from "../../../utils/components/Loading";
import {Button, Form, Modal, TextArea} from "semantic-ui-react";
import {MyInput} from "../../../utils/components/MyInput";
import Utils from "../../../utils/utils/Utils";
import {ApiRequest} from "../../../utils/ApiRequest";
import {ToastsStore} from "react-toasts";

export default function AddMeasurement(params: {
    show: boolean, close: () => void, update: (measurement: Measurement) => void, measurement: Measurement
}) {
    const [loading, setLoading] = React.useState({show: false, message: ""})
    const [measurement, setMeasurement] = React.useState(params.measurement)
    const handle_test = (name: string, value: string | number) => {
        setMeasurement({...measurement, [name]: value})
    }

    const save_measurement = () => {
        const get_value = (value: number | string) => {
            return Utils.is_valid_number(value.toString().trim()) ? parseFloat(value.toString().trim()) : '';
        }

        setLoading({message: 'Saving measurements, please wait', show: true})
        ApiRequest.save_doctor_measurements({
            bmi: get_value(measurement.bmi),
            client_id: measurement.client_id,
            comments: measurement.comments.trim(),
            diastole: get_value(measurement.diastole),
            heart_rate: get_value(measurement.heart_rate),
            height: get_value(measurement.height),
            measurement_id: measurement.measurement_id,
            systole: get_value(measurement.systole),
            temperature: get_value(measurement.temperature),
            weight: get_value(measurement.weight)
        })
            .then((response) => {
                setLoading({message: '', show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    params.update({...measurement, measurement_id: response.data.measurement_id})
                    ToastsStore.success("Results saved successfully")
                } else {
                    ToastsStore.error("Could not save results")
                }
            })
            .catch(() => {
                setLoading({message: '', show: false})
                ToastsStore.error("Could not save results")
            })
    }

    React.useEffect(() => {
        setMeasurement(params.measurement)
    }, [params.measurement])

    React.useEffect(() => {
        if (Utils.is_valid_number(measurement.height.toString().trim()) && Utils.is_valid_number(measurement.weight.toString().trim())) {
            const height = parseFloat(measurement.height.toString().trim()) / 100
            setMeasurement({...measurement, bmi: parseFloat(measurement.weight.toString().trim()) / (height * height)})
        } else {
            setMeasurement({...measurement, bmi: ''})
        }
    }, [measurement.height, measurement.weight])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            {/*Modal to display results*/}
            <Modal open={params.show} basic size='mini' centered={false} closeOnDimmerClick={false} closeOnDocumentClick={false}>
                <div className="modal_container">
                    <div className="form_container">
                        {/*Test Abbreviation*/}
                        <span className="label" dangerouslySetInnerHTML={{__html: 'Temperature <sup>o</sup>C'}}></span>
                        <MyInput placeholder="Enter temperature" name="temperature"
                                 value={measurement.temperature.toString()} change={handle_test}/>

                        <span className="label">Diastole</span>
                        <MyInput placeholder="Enter diastole" name="diastole" value={measurement.diastole.toString()} change={handle_test}/>

                        <span className="label">Systole</span>
                        <MyInput placeholder="Enter systole" name="systole" value={measurement.systole.toString()} change={handle_test}/>

                        <span className="label">Pulse</span>
                        <MyInput placeholder="Enter pulse" name="heart_rate" value={measurement.heart_rate.toString()}
                                 change={handle_test}/>

                        <span className="label">Height (centimeters)</span>
                        <MyInput placeholder="Enter height" name="height" value={measurement.height.toString()} change={handle_test}/>

                        <span className="label">Weight (kilogrammes)</span>
                        <MyInput placeholder="Enter weight" name="weight" value={measurement.weight.toString()} change={handle_test}/>

                        <span className="label">Body Mass Index</span>
                        <MyInput placeholder="Enter weight" name="weight" value={measurement.bmi.toString()}/>

                        {/*Test Description*/}
                        <Form className="mt-3">
                            <span className="label">Test remarks</span>
                            <TextArea
                                placeholder='Enter remarks of diagnosis' value={measurement.comments} rows={4}
                                onChange={(event, data) => setMeasurement({...measurement, comments: data.value as string})}/>
                        </Form>
                    </div>

                    <div className="button_container">
                        <div className="row m-0">
                            <div className="col-6 pl-0 pr-1">
                                <Button
                                    negative icon="close" labelPosition="left" size="mini" fluid
                                    content="Close Window" onClick={params.close}/>
                            </div>
                            <div className="col-6 pl-1 pr-0">
                                <Button
                                    positive icon="save" labelPosition="left" size="mini" fluid onClick={save_measurement}
                                    content={measurement.measurement_id > 0 ? 'Update Results' : 'Save Result'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
