import {Button, Table} from "semantic-ui-react";
import React from "react";
import {Loading} from "../../../utils/components/Loading";
import {ApiRequest} from "../../../utils/ApiRequest";
import {ToastsStore} from "react-toasts";
import {Stock, StockType, StockUnit, Supplier} from "../../../utils/utils/Models";
import {MyInput} from "../../../utils/components/MyInput";
import {MySelect} from "../../../utils/components/MySelect";
import EditStock, {initialStock} from "./utils/utilities/EditStock";

export default function StockManagement() {
    const [loading, setLoading] = React.useState({show: false, message: ""})
    const [search, setSearch] = React.useState<{ name: string, quantity: 'all' | 'out' | 'low' | 'in' }>({name: '', quantity: 'low'})
    const handle_search = (name: string, value: string | number) => {
        setSearch({...search, [name]: value})
    }

    const [data, setData] = React.useState<{ types: StockType[], suppliers: Supplier[], units: StockUnit[] }>({types: [], suppliers: [], units: []})
    const [stocks, setStocks] = React.useState(Array<Stock>())
    const [editStock, setEditStock] = React.useState<{ stock: Stock, show: boolean }>({stock: initialStock, show: false})

    const load_stock = (output: 'list' | 'pdf' | 'excel') => {
        setLoading({message: "Loading stock list, please wait", show: true})
        ApiRequest.get_stock({name: search.name, quantity: search.quantity, output: output})
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setStocks(response.data['stocks'])
                } else {
                    ToastsStore.error("Error while loading stocks, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while loading stocks, please retry")
            })
    }

    const initialise_data = () => {
        setLoading({show: true, message: 'Initialising data, please wait'})
        ApiRequest.initialise_stocks()
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setData({
                        types: response.data['types'],
                        units: response.data['units'],
                        suppliers: response.data['suppliers'],
                    })
                } else {
                    ToastsStore.error("Error while initialising data, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while initialising data, please retry")
            })
    }

    const setStatus = (aStock: Stock) => {
        if (aStock.current_quantity === 0) {
            return <span className={"stock_level out"}>Out of Stock</span>
        } else if (aStock.current_quantity <= aStock.threshold_quantity) {
            return <span className={"stock_level low"}>Low Stock <span>({aStock.current_quantity.toLocaleString()})</span></span>
        } else {
            return <span className={"stock_level in"}>Available <span>({aStock.current_quantity.toLocaleString()})</span></span>
        }
    }

    React.useEffect(() => {
        initialise_data()
    }, [])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            <EditStock
                show={editStock.show} stock={editStock.stock} suppliers={data.suppliers} stockTypes={data.types} stockUnits={data.units}
                close={() => setEditStock({stock: initialStock, show: false})}
                updateStock={(aStock, isNew) => {
                    if (isNew) {
                        setStocks([...stocks, aStock])
                    } else {
                        setStocks(stocks.map((bStock) => bStock.stock_id === aStock.stock_id ? aStock : bStock))
                    }
                }}
                updateStockUnits={(units) => setData({...data, units: units})}
                updateStockTypes={(types) => setData({...data, types: types})}
                updateStockSuppliers={(suppliers) => setData({...data, suppliers: suppliers})}/>

            <div className="content_bar">
                <div className="search_bar">
                    <div style={{width: '250px'}}>
                        <MyInput
                            placeholder="Search by stock name" name="name" value={search.name}
                            change={handle_search} enter={() => load_stock('list')}/>
                    </div>

                    <div style={{width: '200px'}}>
                        <MySelect
                            name="quantity" value={search.quantity} placeholder="Select quantity status"
                            change={(value) => handle_search('quantity', value as string)}
                            options={[
                                {text: "View all stock", value: 'all'},
                                {text: "Available in Stock", value: 'in'},
                                {text: "Low in stock", value: 'low'},
                                {text: "Out of stock", value: 'out'}
                            ]}/>
                    </div>
                </div>
                <div className="content_buttons">
                    <Button primary size='mini' icon='refresh' labelPosition="left" content="Initialise" onClick={initialise_data}/>
                    <Button primary size='mini' icon='add' labelPosition="left" content="Add Stock" onClick={() => setEditStock({stock: initialStock, show: true})}/>
                    <Button primary size='mini' icon='search' labelPosition="left" content="Search Stock" onClick={() => load_stock('list')}/>
                </div>
            </div>

            <div className="content_container">
                <div className="window_content">
                    <Table celled striped compact={false} size='small' inverted color='grey' selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '200px'}}>Item Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '200px'}}>Common Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '80px'}}>Stock Type</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '80px'}}>Stock Unit</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '100px'}}>Sale Price</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '100px'}}>Available</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                stocks.map((aStock, index) =>
                                    <Table.Row key={aStock.stock_id} onClick={() => setEditStock({stock: aStock, show: true})}>
                                        <Table.Cell style={{width: '50px'}} textAlign="center">{index + 1}</Table.Cell>
                                        <Table.Cell style={{width: '200px'}}>{aStock.stock_name}</Table.Cell>
                                        <Table.Cell style={{width: '200px'}}>{aStock.generic_name}</Table.Cell>
                                        <Table.Cell style={{width: '80px'}}>
                                            {data.types.filter((type) => type.type_id === aStock.stock_type)[0]?.type_name}
                                        </Table.Cell>
                                        <Table.Cell style={{width: '80px'}}>
                                            {data.units.filter((unit) => unit.unit_id === aStock.unit_id)[0]?.unit_name}
                                        </Table.Cell>
                                        <Table.Cell style={{width: '100px'}}>{parseFloat(aStock.sale_price.toString()).toLocaleString()}</Table.Cell>
                                        <Table.Cell style={{width: '100px'}}>{setStatus(aStock)}</Table.Cell>
                                    </Table.Row>
                                )
                            }
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </>
    )
}
