import {SampleTest} from "../SampleList";
import React from "react";
import {Button, Form, Modal, TextArea} from "semantic-ui-react";
import Utils from "../../../../utils/utils/Utils";
import {ToastsStore} from "react-toasts";
import {ApiRequest} from "../../../../utils/ApiRequest";
import {MySelect} from "../../../../utils/components/MySelect";
import {MyInput} from "../../../../utils/components/MyInput";
//import { Editor } from "react-draft-wysiwyg";

export default function EditSample(params: {
    sample_id: number, bill_id: number, test_name: string, results: string, results_desc: string, test_id: number,
    close: () => void, update: (results: string, results_desc: string) => void, tests: SampleTest[], sample_no: string
}) {
    const [results, setResults] = React.useState({results: '', results_desc: ''})
    const [test, setTest] = React.useState<SampleTest | undefined>(undefined)
    const handle_results = (name: string, value: string) => {
        setResults({...results, [name]: value})
    }

    const [saving, setSaving] = React.useState(false)
    const save_results = () => {
        if (results.results_desc === '' && results.results === '') {
            ToastsStore.error("Enter results data")
        } else {
            setSaving(true)
            ApiRequest.save_sample_results({
                sample_id: params.sample_id, results: results.results.trim(), results_desc: results.results_desc.trim()
            })
                .then((response) => {
                    setSaving(false)
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            ToastsStore.success("Sample results updates successfully")
                            params.update(results.results.trim(), results.results_desc.trim())
                        } else if (response.data.code === 2) {
                            ToastsStore.error("Sample not found")
                        } else if (response.data.code === 3) {
                            ToastsStore.error("Only processed samples can be given results")
                        }
                    } else {
                        ToastsStore.error("Error while saving sample results, please retry")
                    }
                })
                .catch(() => {
                    setSaving(false)
                    ToastsStore.error("Error while saving sample results, please retry")
                })
        }
    }

    React.useEffect(() => {
        setResults({results: params.results, results_desc: params.results_desc})
    }, [params.sample_id])

    React.useEffect(() => {
        const _tests = params.tests.filter((_test) => _test.test_id === params.test_id)
        setTest(_tests.length === 1 ? _tests[0] : undefined)
    }, [params.test_id, params.tests])

    return (
        <>
            <Modal open={params.sample_id > 0 && test !== undefined} basic size='mini' centered={false}
                   closeOnDimmerClick={false} closeOnDocumentClick={false}>
                {
                    test !== undefined &&
                    <>
                        <div className="modal_container">
                            <span className="form_header">VERIFY SAMPLE ({params.sample_no.toUpperCase()})</span>

                            <div className="form_container has_header">
                                {
                                    test.results_type === 'Custom' &&
                                    <>
                                        <Form>
                                            <span className="label mt-0">Result Description</span>
                                            <TextArea
                                                placeholder='Enter result description' value={results.results_desc} rows={3}
                                                onChange={(event, data) => {
                                                    handle_results('results_desc', data.value as string)
                                                }}/>
                                        </Form>
                                    </>
                                }

                                {
                                    test.results_type === 'Distinct' &&
                                    <>
                                        <span className="label mt-0">Enter Results</span>
                                        <MySelect
                                            name="results" value={results.results} placeholder="Select result"
                                            change={(value) => {
                                                const _value = value as string
                                                const values = test.results_values
                                                if (_value === '') {
                                                    setResults({results: '', results_desc: ''})
                                                } else {
                                                    for (let index = 0; index < values.length; index++) {
                                                        if (values[index].value === _value) {
                                                            setResults({results: _value, results_desc: values[index].desc})
                                                            break
                                                        }
                                                    }
                                                }
                                            }}
                                            options={[
                                                {text: "Select Result", value: ""},
                                                ...test.results_values.map((value) => ({text: value.value.toString(), value: value.value}))
                                            ]}/>

                                        <Form>
                                            <span className="label">Result Description</span>
                                            <TextArea
                                                placeholder='Enter result description' value={results.results_desc} rows={3}
                                                onChange={(event, data) => {
                                                    handle_results('results_desc', data.value as string)
                                                }}/>
                                        </Form>
                                    </>
                                }

                                {
                                    test.results_type === 'Connected' &&
                                    <>
                                        {/*results value*/}
                                        <span className="label mt-0">Enter Results</span>
                                        <MyInput
                                            placeholder="Enter value" name="results" value={results.results.toString()}
                                            change={(name, value) => {
                                                const values = test.results_values
                                                let results = value.trim()
                                                let results_desc = ''

                                                if (results === "") {
                                                    results_desc = ''
                                                } else if (Utils.is_valid_number(results)) {
                                                    const _value = parseFloat(value.trim())
                                                    if (_value < values[0].value) {
                                                        results_desc = 'Out of range'
                                                    } else if (_value >= values[values.length - 1].value) {
                                                        results_desc = values[values.length - 1].desc
                                                    } else {
                                                        for (let index = 0; index < values.length - 1; index++) {
                                                            if (_value >= values[index].value && _value < values[index + 1].value) {
                                                                results_desc = values[index].desc
                                                                break;
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    ToastsStore.error("Enter a valid value")
                                                }
                                                setResults({results: results.toString(), results_desc: results_desc})
                                            }}/>

                                        {/*results information*/}
                                        <Form>
                                            <span className="label">Result Description</span>
                                            <TextArea
                                                placeholder='Enter result description' value={results.results_desc} rows={3}
                                                onChange={(event, data) => {
                                                    handle_results('results_desc', data.value as string)
                                                }}/>
                                        </Form>
                                    </>
                                }
                            </div>

                            <div className="button_container">
                                <div className="row m-0">
                                    <div className="col-2 pl-0 pr-1">
                                        <Button negative icon="close" size="mini" fluid onClick={params.close} disabled={saving}/>
                                    </div>
                                    <div className="col-5 px-1">
                                        <Button negative icon="trash" labelPosition="left" size="mini" fluid content='Clear Results'
                                                onClick={() => setResults({results: '', results_desc: ''})} disabled={saving}/>
                                    </div>
                                    <div className="col-5 px-0">
                                        <Button positive icon="save" labelPosition="left" size="mini" fluid
                                                content="Save Results" onClick={save_results} disabled={saving} loading={saving}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </Modal>
        </>
    )
}
