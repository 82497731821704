declare var JSPM: any;

export interface Receipt {
    client: string
    bill_id: number
    bill_status: string
    total_paid: number
    discount: number
    bill_amount: number
    group_name: string
    tests: Test[]
    lab: {
        lab_name: string
        location: string
        contact: string
        email: string
    }
}

interface Test {
    test_name: string
    test_abbr: string
    bill_amount: number
}

export default class JSPrinter {
    static print_receipt(receipts: Receipt[], type: string) {

        receipts.forEach((receipt) => {
            let commands = ""
            commands += "\x1b\x40" //Initialize printer
            commands += `\x1b\x61\x01`// Select justification: Centering
            commands += "\x1b\x45\x01" //Enable bold mode
            commands += "\x1b\x21\x10\x01" //FontSize
            commands += `${receipt.lab.lab_name}\x0a`
            commands += "\x1b\x21\x00\x00" //FontSize
            commands += receipt.lab.location
            commands += `\x0a`
            commands += `${receipt.lab.contact}`
            commands += `\x0a`
            commands += receipt.lab.email
            commands += `\x0a`
            commands += "\x1b\x45\x00" //Disable bold mode

            commands += `\x1b\x64\x01`
            commands += `\x1b\x61\x01`// Select justification: Centering
            commands += "\x1b\x45\x01" //Enable bold mode
            commands += "\x1b\x21\x10\x01" //FontSize
            commands += "\x1b\x2d\x02" //Enable underline
            commands += `${type}`
            commands += "\x1b\x2d\x00" //Disable underline
            commands += "\x1b\x45\x00" //Disable bold mode
            commands += "\x1b\x21\x00\x00" //FontSize
            commands += `\x1b\x64\x01`
            commands += `\x1b\x64\x01`//Print and feed paper: Paper feeding amount = 1.13 mm (8/180 inches)

            commands += `\x1b\x61\x00`// Select justification: Left
            receipt.tests.forEach((field) => {
                commands += `${field.test_name} (${field.test_abbr})`
                commands += `\x09`// Horizontal Tab
                commands += "\x1b\x45\x01" //Enable bold mode
                commands += `UGX ${field.bill_amount.toLocaleString()}`
                commands += "\x1b\x45\x00" //Enable bold mode
                commands += `\x0a`
            });

            [
                {name: "Bill Amount", amount: receipt.bill_amount},
                {name: "Discount", amount: receipt.discount},
                {name: "Total Paid", amount: receipt.total_paid},
                {name: "Balance", amount: receipt.bill_amount - receipt.discount - receipt.total_paid}
            ].forEach((summary) => {
                commands += `${summary.name}`
                commands += `\x09`// Horizontal Tab
                commands += "\x1b\x45\x01" //Enable bold mode
                commands += `UGX ${summary.amount.toLocaleString()}`
                commands += "\x1b\x45\x00" //Enable bold mode
                commands += `\x0a`
            });


            commands += `\x1b\x64\x02`//Print and feed paper: Paper feeding amount = 1.13 mm (8/180 inches)

            commands += `\x1b\x61\x01`// Select justification: Centering
            commands += "\x1b\x45\x01" //Enable bold mode
            commands += "\x1b\x21\x20\x02" //FontSize
            commands += `${receipt.bill_status}\x0a`
            commands += "\x1b\x45\x01" //Disable bold mode
            commands += "\x1b\x21\x00\x00" //FontSize

            commands += `\x1b\x64\x05`//Print and feed paper: Paper feeding amount = 1.13 mm (8/180 inches)
            commands += "\x1b\x69" //Execute paper full cut

            JSPrinter.print_data(commands)
        })
    }

    static print_barcode(sample_no: string) {
        const command = 'SIZE 30mm,20mm \n' +
            'GAP 5mm,5mm\n' +
            'DIRECTION 0 \n' +
            'CLS \n' +
            'BARCODE 20,5,"128",100,1,0,1,2,"' + sample_no + '" \n' +
            'PRINT 1\n' +
            'SOUND 1,200'
        JSPrinter.print_data(command)
    }

    static print_data(commands: string) {
        JSPM.JSPrintManager
            .start()
            .then(() => {
                if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
                    const printJob = new JSPM.ClientPrintJob();
                    printJob.clientPrinter = new JSPM.UserSelectedPrinter()
                    printJob.printerCommands = commands;

                    printJob.sendToClient()
                        .catch((error: any) => {
                            console.log(error)
                        })
                } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed) {
                    alert("JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm")
                } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Blocked) {
                    alert("JSPM has blocked this website")
                } else {
                    alert("Could not get printer status")
                }
            });
    }
}
