import {Action, combineReducers, configureStore, ThunkAction,} from '@reduxjs/toolkit';
import {persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER,} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import userSlice from "./userSlice";
import {connectRouter} from "connected-react-router";
import {createBrowserHistory} from "history";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";

const persistConfig = {key: 'root', version: 1, storage,}
const rootReducer = combineReducers({
    router: connectRouter(createBrowserHistory()),
    user: userSlice
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware(
            {
                serializableCheck: {ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],},
            });
    },
});
export const persistedStore = persistStore(store)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>


export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
