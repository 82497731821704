import React from "react";
import {Button, Modal, Table} from "semantic-ui-react";
import Utils from "../../../../utils/utils/Utils";
import {ToastsStore} from "react-toasts";
import {ApiRequest} from "../../../../utils/ApiRequest";
import {MySelect} from "../../../../utils/components/MySelect";
import {MyInput} from "../../../../utils/components/MyInput";

interface Payment {
    amount_paid: string
    payment_type: 'Cash' | 'MTN' | 'Airtel'
    reference_no: string
}

const initial_payment: Payment = {amount_paid: "", reference_no: "", payment_type: 'Cash'}

export default function PayOrder(params: {
    bill_id: number, balance: number, close: () => void,
    update: (total_paid: number, bill_status: 'Authorized' | 'Pending', receipt: any) => void
}) {

    const [showPayment, setShowPay] = React.useState({index: -1, show: false})
    const [payment, setPayment] = React.useState(initial_payment)
    const [payments, setPayments] = React.useState(Array<Payment>())

    const add_payment = () => {
        const _payment: Payment = {
            amount_paid: payment.amount_paid.trim(), payment_type: payment.payment_type, reference_no: payment.reference_no.trim()
        }

        const filtered = payments.filter((__payment) =>
            _payment.amount_paid === __payment.amount_paid && _payment.payment_type === __payment.payment_type &&
            _payment.reference_no === __payment.reference_no
        )

        if (!Utils.is_valid_number(_payment.amount_paid)) {
            ToastsStore.error("Enter a valid amount paid")
        } else if (filtered.length > 0 && showPayment.index === -1) {
            ToastsStore.error("Duplicate payment created")
        } else {
            if (showPayment.index === -1) {
                setPayments([...payments, _payment])
            } else {
                setPayments(payments.map((__p, __i) => __i === showPayment.index ? _payment : __p))
            }
            setPayment(initial_payment)
            setShowPay({show: false, index: -1})
        }
    }

    const [paying, setPaying] = React.useState(false)
    const pay_bill = () => {
        let total_paid = 0
        payments.forEach((payment) => {
            total_paid += parseFloat(payment.amount_paid)
        })

        if (payments.length === 0) {
            ToastsStore.error("Add at least 1 payment")
        } else if (total_paid > params.balance) {
            ToastsStore.error(`Maximum total balance of ${Utils.comma_number(params.balance, 'UGX ')} has been exceeded`)
        } else {
            setPaying(true)

            ApiRequest.pay_billing_order({bill_id: params.bill_id, payments: JSON.stringify(payments), total_paid: total_paid})
                .then((response) => {
                    setPaying(false)
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            ToastsStore.success("Bill payment successfully made")
                            params.update(response.data.total_paid, response.data.status, response.data.receipt)
                        } else if (response.data.code === 2) {
                            ToastsStore.error("Bill order cannot be found")
                        } else if (response.data.code === 3) {
                            ToastsStore.error("Bill was already cleared, no further payments can be made")
                        } else if (response.data.code === 4) {
                            ToastsStore.error(`A maximum pay of ${Utils.comma_number(response.data.balance, 'UGX ')} can be made`)
                        } else if (response.data.code === 5) {
                            ToastsStore.error("Bill was cancelled, you cannot make payments over it")
                        } else if (response.data.code === 6) {
                            ToastsStore.error("Invalid payment data received")
                        }
                    } else {
                        ToastsStore.error("Error while making payment, please retry")
                    }
                })
                .catch(() => {
                    setPaying(false)
                    ToastsStore.error("Error while making payment, please retry")
                })
        }
    }

    const get_total_paid = () => {
        let total = 0
        payments.forEach((payment) => {
            total += parseFloat(payment.amount_paid)
        })
        return Utils.comma_number(total, 'UGX ')
    }

    React.useEffect(() => {
        setPayment(initial_payment)
        setPayments([])
    }, [params.bill_id])

    return (
        <>
            <Modal open={showPayment.show} basic size='mini' centered={false} closeOnDimmerClick={false} closeOnDocumentClick={false}>
                <div className="modal_container">
                    <span className="form_header">{payment.payment_type}</span>

                    <div className="form_container">
                        <span className="label mt-0">Amount Paid</span>
                        <MyInput placeholder="Enter amount paid" name="amount_paid" value={payment.amount_paid}
                                 change={(name, value) => setPayment({...payment, [name]: value})}/>

                        {
                            payment.payment_type !== 'Cash' &&
                            <>
                                <span className="label">Reference Number</span>
                                <MyInput placeholder="Enter payment reference no" name="reference_no" value={payment.reference_no}
                                         change={(name, value) => setPayment({...payment, [name]: value})}/>
                            </>
                        }
                    </div>

                    <div className="button_container">
                        <div className="row m-0">
                            <div className="col-2 pl-0 pr-1">
                                <Button
                                    negative icon="close" size="mini" fluid
                                    onClick={() => {
                                        setPayment(initial_payment)
                                        setShowPay({show: false, index: -1})
                                    }}/>
                            </div>
                            <div className="col-5 px-1">
                                <Button negative icon="trash" labelPosition="left" size="mini" fluid content='Clear Payment'
                                        onClick={() => setPayment({...initial_payment, payment_type: payment.payment_type})}/>
                            </div>
                            <div className="col-5 px-0">
                                <Button positive icon="add" labelPosition="left" size="mini" fluid
                                        content="Add Payment" onClick={add_payment}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal open={params.bill_id > 0} basic size='mini' centered={false} closeOnDimmerClick={false} closeOnDocumentClick={false}>
                <div className="modal_container">
                    <span className="form_header">
                        BILL NO: {params.bill_id} (BALANCE {Utils.comma_number(params.balance, 'UGX ')})
                    </span>

                    <div className="form_container">
                        <span className="label mt-0">Payment Type</span>
                        <MySelect
                            name="" value={0} placeholder="Select a payment type" disabled={paying}
                            change={(value) => {
                                setShowPay({index: -1, show: true})
                                setPayment({amount_paid: '', payment_type: value as 'Cash' | 'MTN' | 'Airtel', reference_no: ''})
                            }}
                            options={[
                                {text: "Select a payment type", value: 0},
                                {text: "Airtel Money", value: 'Airtel'},
                                {text: "Cash Payment", value: 'Cash'},
                                {text: "MTN Money", value: 'MTN'},
                            ]}/>

                        <span className="label">Payment Break Down</span>
                        <div className="table_payment">
                            <Table celled striped compact={false} size='small' inverted color='grey' selectable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '90px'}} textAlign="center"/>
                                        <Table.HeaderCell style={{width: '110px'}}>Amount Paid</Table.HeaderCell>
                                        <Table.HeaderCell>Reference No.</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        payments.map((_payment, _index) =>
                                            <Table.Row key={`${_payment.payment_type}${_payment.reference_no}`}>
                                                <Table.Cell style={{width: '90px'}} textAlign="center">
                                                    <Button
                                                        primary size='mini' icon='edit' compact
                                                        onClick={() => {
                                                            setPayment(_payment)
                                                            setShowPay({index: _index, show: true})
                                                        }}/>
                                                    <Button
                                                        primary size='mini' icon='trash' compact
                                                        onClick={() => setPayments(payments.filter((_p, __i) => _index !== __i))}/>
                                                </Table.Cell>
                                                <Table.Cell style={{width: '110px'}}>
                                                    {Utils.comma_number(parseFloat(_payment.amount_paid), '')}
                                                </Table.Cell>
                                                <Table.Cell>{_payment.reference_no}</Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>

                        <span className="bill_amount">{get_total_paid()}</span>
                    </div>

                    <div className="button_container">
                        <div className="row m-0">
                            <div className="col-2 pl-0 pr-1">
                                <Button negative icon="close" size="mini" fluid disabled={paying}
                                        onClick={() => params.close()}/>
                            </div>
                            <div className="col-5 px-1">
                                <Button negative icon="trash" labelPosition="left" size="mini" fluid content='Clear Reason'
                                        onClick={() => setPayments([])} disabled={paying}/>
                            </div>
                            <div className="col-5 px-0">
                                <Button positive icon="save" labelPosition="left" size="mini" fluid
                                        content="Pay Order" onClick={pay_bill} disabled={paying} loading={paying}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
