import React from "react";
import Utils from "../../utils/utils/Utils";
import PhoneInput from "react-phone-input-2";
import {Button, Confirm, Modal} from "semantic-ui-react";
import {ApiRequest} from "../../utils/ApiRequest";
import {countries} from "../../utils/Countries"
import moment from "moment";
import {ToastsStore} from "react-toasts";
import {WebCamView} from "../../utils/components/WebCamView";
import {MyDateInput} from "../../utils/components/MyDateInput";

import {ConfirmAction, initialConfirm} from "../../utils/utils/Models";
import {Loading} from "../../utils/components/Loading";
import {MySelect} from "../../utils/components/MySelect";
import {MyInput} from "../../utils/components/MyInput";
import {BillGroup} from "./ClientList";

export interface Client {
    client_id: number
    client_no: string
    first_name: string,
    last_name: string,
    other_name: string,
    client_gender: string,
    date_of_birth: string
    mobile_contact: string
    email_address: string
    passport_no: string
    nationality: string
    village: string
    sub_county: string
    parish: string
    district: string
    next_of_kin: string
    next_of_kin_contact: string
    passport_photo: string
    group_id: number
}

export const initial_client: Client = {
    client_gender: "", date_of_birth: "", district: "", email_address: "", first_name: "", last_name: "",
    mobile_contact: "", nationality: "", next_of_kin: "", next_of_kin_contact: "", passport_photo: "",
    other_name: "", parish: "", sub_county: "", village: "", passport_no: "", client_id: 0, group_id: 0, client_no: ""
}

export default function ClientData(params: {
    client: Client, close: () => void, groups: BillGroup[],
    update: (client: Client, new_data: boolean) => void, show: boolean
}) {
    const webContainerRef = React.useRef<HTMLDivElement | null>(null)
    const [dimens, setDimens] = React.useState({width: 0, height: 0})

    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initialConfirm)

    const [saving, setSaving] = React.useState(false)
    const [client, setClient] = React.useState(initial_client)
    const handle_client = (name: string, value: string | number) => {
        setClient({...client, [name]: value})
    }

    const [image, setImage] = React.useState("")

    const update_data = () => {
        if (Utils.invalid_name(client.first_name.trim())) {
            ToastsStore.error("Enter a valid first name")
        } else if (Utils.invalid_name(client.last_name.trim())) {
            ToastsStore.error("Enter a valid surname")
        } else if (client.other_name.trim().length > 0 && Utils.invalid_name(client.other_name.trim())) {
            ToastsStore.error("Enter a valid other name")
        } else if (client.date_of_birth !== "" && moment(client.date_of_birth).isBefore(moment("1900-01-01"))) {
            ToastsStore.error("Date of birth cannot be before 01/01/1900")
        } else if (client.date_of_birth !== "" && moment(client.date_of_birth).isAfter(moment(Utils.today()))) {
            ToastsStore.error("Date of birth cannot be after today")
        } else if (client.client_gender === "") {
            ToastsStore.error("Please select a gender")
        } else if (client.nationality === '') {
            ToastsStore.error("Please select nationality")
        } else {
            setSaving(true)
            ApiRequest.save_client({
                client_id: client.client_id,
                first_name: client.first_name,
                last_name: client.last_name,
                other_name: client.other_name,
                client_gender: client.client_gender,
                date_of_birth: client.date_of_birth,
                mobile_contact: client.mobile_contact,
                email_address: client.email_address,
                passport_no: client.passport_no,
                nationality: client.nationality,
                village: client.village,
                sub_county: client.sub_county,
                parish: client.parish,
                district: client.district,
                next_of_kin: client.next_of_kin,
                next_of_kin_contact: client.next_of_kin_contact,
                passport_photo: image,
                group_id: client.group_id
            })
                .then((response) => {
                    setSaving(false)
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            ToastsStore.success("Successfully saved user profile")
                            params.update(
                                {
                                    ...client,
                                    client_id: response.data.client_id,
                                    passport_photo: response.data.photo === "" ? client.passport_photo : response.data.photo

                                },
                                client.client_id === 0
                            )
                        } else if (response.data.code === 2) {
                            //todo handle email presence
                        } else if (response.data.code === 3) {
                            //todo handle contact presence
                        } else if (response.data.code === 4) {
                            ToastsStore.error("Client data could not be matched, please retry")
                        }
                    } else {
                        ToastsStore.error("Error while saving client data, please retry")
                    }
                })
                .catch(() => {
                    setSaving(false)
                    ToastsStore.error("Error while saving client data, please retry")
                })
        }
    }

    const clear_data = () => {
        setConfirm({
            ...confirm, open: true, content: 'Do you want to clear data?',
            onConfirm: () => {
                setConfirm({...confirm, open: false})
                setClient(initial_client)
            },
            onCancel: () => setConfirm({...confirm, open: false})
        })
    }

    React.useEffect(() => {
        setClient(params.client)
        setImage("")
    }, [params.client])

    React.useEffect(() => {
        if (params.show && webContainerRef.current !== null) {
            const width = (webContainerRef.current as HTMLDivElement).offsetWidth - 20
            setDimens({height: width * 0.75, width: width})
        }
    }, [params.show, webContainerRef])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm}
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton}
                     size='mini' content={confirm.content} header="Clear Data"/>

            <Modal open={params.show} basic size='small' centered={false} closeOnDimmerClick={false} closeOnDocumentClick={false}>
                <div className="modal_container full_height">
                    <div className="form_container">
                        {/*Personal Information*/}
                        <span className="sub_header mt-0">Personal Information</span>
                        <div className="row m-0">
                            <div className="col-8 col-md-5 px-1 offset-md-2" ref={webContainerRef}>
                                <WebCamView setImage={setImage} dimens={dimens}/>
                            </div>

                            <div className="col-4 col-md-3 px-1 d-md-flex flex-md-column">
                                <div className={"flex-md-grow-1"}/>
                                {
                                    image !== "" ?
                                        <img src={image} alt="I" style={{width: '100%', height: 'auto'}}/> : (
                                            client.passport_photo !== "" ?
                                                <img src={`${Utils.BASE_URL}${client.passport_photo}`} alt="I"
                                                     style={{width: '100%', height: 'auto'}}/> :
                                                <></>
                                        )
                                }
                            </div>
                        </div>

                        <div className="row m-0">
                            <div className="col-12 col-md-6 p-1">
                                <span className="label mt-0">First Name</span>
                                <MyInput placeholder="First Name" name="first_name" value={client.first_name} change={handle_client}/>
                            </div>

                            <div className="col-12 col-md-6 p-1">
                                <span className="label mt-md-0">Surname</span>
                                <MyInput placeholder="Enter surname" name="last_name" value={client.last_name} change={handle_client}/>
                            </div>

                            <div className="col-12 col-md-6 p-1">
                                <span className="label">Other Name (optional)</span>
                                <MyInput placeholder="Other Name" name="other_name" value={client.other_name} change={handle_client}/>
                            </div>

                            <div className="col-12 col-md-6 p-1">
                                <span className="label">Nationality</span>
                                <MySelect
                                    change={(value) => handle_client("nationality", value as string)}
                                    name="nationality" value={client.nationality} placeholder="Select nationality" options={countries}/>
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className="col-12 col-md-6 p-1">
                                <span className="label">Date of Birth</span>
                                <MyDateInput
                                    value={client.date_of_birth} name="date_of_birth" maxDate={Utils.today()} minDate="1900-01-01"
                                    placeholder="Select date of birth" change={handle_client}/>
                            </div>

                            <div className="col-12 col-md-6 p-1">
                                <span className="label">Gender</span>
                                <MySelect
                                    change={(value) => handle_client("client_gender", value as string)}
                                    name="client_gender" value={client.client_gender} placeholder="Select user gender"
                                    options={[
                                        {value: "Other", text: "Other"}, {value: "Male", text: "Male"}, {value: "Female", text: "Female"},
                                    ]}/>
                            </div>

                            <div className="col-12 col-md-6 p-1">
                                <span className="label">ID No (Passport, National ID, Driving License)</span>
                                <MyInput placeholder="Passport No" name="passport_no" value={client.passport_no} change={handle_client}/>
                            </div>

                            <div className="col-12 col-md-6 p-1">
                                <span className="label">Billing Category</span>
                                <MySelect
                                    name="group_id" value={client.group_id} placeholder="Select a billing categort"
                                    change={(value) => setClient({...client, group_id: value as number})}
                                    options={[
                                        {text: "Select a billing company", value: 0},
                                        ...params.groups.map((group) => ({text: group.group_name, value: group.group_id}))
                                    ]}/>
                            </div>

                        </div>

                        {/*Location information*/}
                        <span className="sub_header">Address and Contact Info</span>
                        <div className="row m-0">
                            <div className="col-12 col-md-6 p-1">
                                <span className="label mt-0">Email Address</span>
                                <MyInput placeholder="Email Address" name="email_address" value={client.email_address}
                                         change={handle_client}/>
                            </div>

                            <div className="col-12 col-md-6 p-1">
                                <span className="label mt-md-0">Mobile Contact</span>
                                <PhoneInput
                                    countryCodeEditable={true} country={'ug'} value={client.mobile_contact}
                                    onChange={((value) => handle_client('mobile_contact', value))}/>
                            </div>

                            <div className="col-12 col-md-6 p-1">
                                <span className="label">Residential Sub Country (optional)</span>
                                <MyInput placeholder="Enter sub county" name="sub_county" change={handle_client} value={client.sub_county}/>
                            </div>

                            <div className="col-12 col-md-6 p-1">
                                <span className="label">Residential District (In Uganda)</span>
                                <MyInput placeholder="Enter district" name="district" change={handle_client} value={client.district}/>
                            </div>

                            <div className="col-12 col-md-6 p-1">
                                <div className="label">Residential Parish (optional)</div>
                                <MyInput placeholder="Enter parish" name="parish" value={client.parish} change={handle_client}/>
                            </div>

                            <div className="col-12 col-md-6 p-1">
                                <div className="label">Residential Village (In Uganda)</div>
                                <MyInput placeholder="Enter village" name="village" value={client.village} change={handle_client}/>
                            </div>
                        </div>

                        {/*Next of Kin*/}
                        <span className="sub_header">Next Of Kin Information</span>
                        <div className="row m-0">
                            <div className="col-12 col-md-6 p-1">
                                <span className="label mt-0">Next of kin full name</span>
                                <MyInput placeholder="Next of kin name" name="next_of_kin" change={handle_client}
                                         value={client.next_of_kin}/>
                            </div>

                            <div className="col-12 col-md-6 p-1">
                                <span className="label mt-md-0">Mobile Contact</span>
                                <PhoneInput
                                    country={'ug'} value={client.next_of_kin_contact} containerStyle={{marginBottom: '0px'}}
                                    onChange={((value) => handle_client('next_of_kin_contact', value))}/>
                            </div>
                        </div>
                    </div>

                    <div className="button_container">
                        <div className="row m-0 align-content-center align-items-center">
                            <div className="col-2 col-md-1 offset-md-2 pl-0 pr-1">
                                <Button negative icon="close" size="tiny" fluid disabled={saving} onClick={params.close}/>
                            </div>
                            <div className="col-5 col-md-3 px-0">
                                <Button negative icon="trash" labelPosition="left" size="tiny" fluid
                                        content="Clear Data" disabled={saving} onClick={clear_data}/>
                            </div>
                            <div className="col-5 col-md-3 pl-1 pr-0">
                                <Button positive icon="save" labelPosition="left" size="tiny" fluid
                                        content={client.client_id === 0 ? "Save Client" : "Update Client"}
                                        onClick={update_data} disabled={saving} loading={saving}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
