import React from "react";
import {ToastsStore} from "react-toasts";
import Utils from "../../../utils/utils/Utils";
import {ApiRequest} from "../../../utils/ApiRequest";
import {Button, Form, Icon, Modal, Table, TextArea} from "semantic-ui-react";
import {Category} from "./ModalCategory";
import {Loading} from "../../../utils/components/Loading";
import {MySelect} from "../../../utils/components/MySelect";
import {MyInput} from "../../../utils/components/MyInput";

type Results = {
    value: string | number
    desc: string
}

export interface Test {
    test_id: number
    category_id: number
    test_name: string
    test_abbr: string
    test_desc: string
    results_type: 'Connected' | 'Distinct' | 'Custom'
    results_values: string
    results_units: string
    time_taken: string | number
}

interface CustomTest {
    test_id: number
    category_id: number
    test_name: string
    test_abbr: string
    test_desc: string
    results_type: 'Connected' | 'Distinct' | 'Custom'
    custom: string
    connected: Results[]
    distinct: Results[]
    results_units: string
    time_taken: string
}

export const initial_test: Test = {
    category_id: 0, results_type: 'Distinct', results_units: "", results_values: "[]", test_abbr: "",
    test_desc: "", test_id: 0, test_name: "", time_taken: ''
}
const initial_results: Results = {desc: "", value: ""}
const custom_test: CustomTest = {
    category_id: 0, connected: [], distinct: [], custom: "", results_type: 'Distinct',
    results_units: "", test_abbr: "", test_desc: "", test_id: 0, test_name: "", time_taken: ""
}

export default function ModalTest(params: {
    test: Test, update: (test: Test) => void, show: boolean, close: () => void,
    categories: Category[]
}) {
    const [loading, setLoading] = React.useState({show: false, message: ""})
    const [test, setTest] = React.useState<CustomTest>(custom_test)

    const handle_test = (name: string, value: string | number) => {
        setTest({...test, [name]: value})
    }

    /*saving the distinct and connected results*/
    const [showResults, setShowResults] = React.useState<{ type: 'Connected' | 'Distinct', show: boolean }>({type: 'Distinct', show: false})
    const [results, setResults] = React.useState<Results>(initial_results)
    const handle_results = (name: string, value: string) => {
        setResults({...results, [name]: value})
    }

    const get_results_type = () => {
        if (test.category_id === 0) {
            return [{text: "Results are interpreted on analysis", value: 'Custom'},]
        }
        if (params.categories.filter((category) => category.category_id === test.category_id)[0].category_type === "lab") {
            return [
                {text: "Results have defined values", value: 'Distinct'},
                {text: "Results interpreted according to range", value: 'Connected'},
                {text: "Results are interpreted on analysis", value: 'Custom'},
            ]
        } else {
            return [{text: "Results are interpreted on analysis", value: 'Custom'},]
        }
    }
    const save_distinct = () => {
        if (results.value.toString().trim() === "") {
            ToastsStore.error("Enter a valid result")
        } else {
            const _results = {value: results.value.toString().trim(), desc: results.desc.trim()}
            if (test.distinct.filter((distinct) => _results.value === distinct.value).length === 0) {
                setTest({...test, distinct: [...test.distinct, _results]})
            } else {
                setTest({...test, distinct: test.distinct.map((_init) => _init.value.toString() === _results.value ? _results : _init)})
            }
            setShowResults({...showResults, show: false})
        }
    }

    const save_connected = () => {
        if (results.value.toString().trim() === "" || !Utils.is_valid_number(results.value.toString().trim())) {
            ToastsStore.error("Enter a valid result")
        } else {
            const _results = {value: parseFloat(results.value.toString().trim()), desc: results.desc.trim()}
            let connected: Results[]
            if (test.connected.filter((connected) => _results.value === parseFloat(connected.value.toString())).length === 0) {
                connected = [...test.connected, _results]
            } else {
                connected = test.connected.map((connected) => parseFloat(connected.value.toString()) === _results.value ? _results : connected)
            }
            setTest({
                ...test, connected: connected.sort((a, b) =>
                    (parseFloat(a.value.toString())) - (parseFloat(b.value.toString()))
                )
            })

            setShowResults({...showResults, show: false})
        }
    }

    const save_test = () => {
        if (test.category_id === 0) {
            ToastsStore.error("Select a category name")
        } else if (test.test_name.trim().length < 3) {
            ToastsStore.error("Enter a valid test name")
        } else if (test.test_abbr.trim().length < 2) {
            ToastsStore.error("Enter a valid test abbreviation")
        } else if (!Utils.is_valid_number(test.time_taken.toString().trim())) {
            ToastsStore.error("Enter valid time taken to process results in minutes")
        } else if (test.results_type === 'Connected' && (test.connected).length < 2) {
            ToastsStore.error("Add at least 2 results interpretations")
        } else if (test.results_type === 'Distinct' && (test.distinct).length < 2) {
            ToastsStore.error("Add at least 2 results interpretations")
        } else {
            setLoading({message: "Saving results, please wait", show: true})

            let results_values: string = ''
            if (test.results_type === 'Custom') {
                results_values = test.custom
            } else if (test.results_type === 'Distinct') {
                results_values = JSON.stringify(test.distinct)
            } else if (test.results_type === 'Connected') {
                results_values = JSON.stringify(test.connected)
            }

            ApiRequest.save_test({
                test_id: test.test_id, category_id: test.category_id, test_name: test.test_name.trim(),
                test_abbr: test.test_abbr.trim(), test_desc: test.test_desc.trim(),
                results_type: test.results_type, results_values: results_values,
                results_units: test.results_units.trim(), time_taken: parseInt(test.time_taken.toString().trim())
            })
                .then((response) => {
                    setLoading({message: "", show: false})
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            params.update({
                                test_id: response.data.category_id,
                                category_id: test.category_id,
                                test_name: test.test_name.trim(),
                                test_abbr: test.test_abbr.trim(),
                                test_desc: test.test_desc.trim(),
                                results_type: test.results_type,
                                results_values: results_values,
                                results_units: test.results_units.trim(),
                                time_taken: parseInt(test.time_taken.toString().trim())
                            })
                            ToastsStore.success("Test saved successfully")
                        } else if (response.data.code === 2) {
                            ToastsStore.error("Test name is already in use")
                        } else if (response.data.code === 3) {
                            ToastsStore.error("Test abbreviation is already in use")
                        }
                    } else {
                        ToastsStore.error("Error while saving test, please retry")
                    }
                })
                .catch(() => {
                    setLoading({message: "", show: false})
                    ToastsStore.error("Error while saving test, please retry")
                })
        }
    }

    React.useEffect(() => {
        setTest({
            test_id: params.test.test_id,
            category_id: params.test.category_id,
            test_name: params.test.test_name,
            test_abbr: params.test.test_abbr,
            test_desc: params.test.test_desc,
            results_type: params.test.results_type,
            custom: params.test.results_type === 'Custom' ? params.test.results_values : '',
            connected: params.test.results_type === 'Connected' ? JSON.parse(params.test.results_values) : [],
            distinct: params.test.results_type === 'Distinct' ? JSON.parse(params.test.results_values) : [],
            results_units: params.test.results_units,
            time_taken: params.test.time_taken.toString()
        })
    }, [params.test])

    React.useEffect(() => {
        if (test.category_id === 0) {
            setTest({...test, results_type: 'Custom'})
        } else {
            if (params.categories.filter((category) => category.category_id === test.category_id)[0].category_type === "lab") {
                setTest({...test, results_type: test.results_type === 'Custom' ? 'Distinct' : test.results_type})
            } else {
                setTest({...test, results_type: test.results_type !== 'Custom' ? 'Custom' : test.results_type})
            }
        }
    }, [test.category_id])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            {/*Modal to display results*/}
            <Modal open={showResults.show} basic size='mini' centered={false} closeOnDimmerClick={false} closeOnDocumentClick={false}>
                <div className="modal_container">
                    <div className="form_container">
                        {/*Results Value*/}
                        <span className="label">{showResults.type === 'Connected' ? 'Enter Max Value' : 'Max Value'}</span>
                        <MyInput placeholder="Enter value" name="value"
                                 value={results.value.toString()} change={handle_results}/>
: string
    : string
                        {/*Results description*/}
                        <Form className="mt-3">
                            <span className="label">Result Description</span>
                            <TextArea
                                placeholder='Enter result description' value={results.desc} rows={3}
                                onChange={(event, data) => handle_results('desc', data.value as string)}/>
                        </Form>
                    </div>

                    <div className="button_container">
                        <div className="row m-0">
                            <div className="col-6 pl-0 pr-1">
                                <Button negative icon="close" labelPosition="left" size="mini" fluid content="Close Window"
                                        onClick={() => setShowResults({...showResults, show: false})}/>
                            </div>
                            <div className="col-6 pl-1 pr-0">
                                <Button positive icon="save" labelPosition="left" size="mini" fluid content="Save Result"
                                        onClick={() => showResults.type === 'Connected' ? save_connected() : save_distinct()}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            {/*Modal to display test*/}
            <Modal open={params.show} basic size='small' centered={false} closeOnDimmerClick={false} closeOnDocumentClick={false}>
                <div className="modal_container full_height">
                    <div className="form_container">
                        <div className="row mx-0 h-100">
                            <div className="col-5 pl-0 pr-1">
                                {/*Category Name*/}
                                <span className="label mt-0">Test category</span>
                                <MySelect
                                    name="category_id" value={test.category_id} placeholder="Select a category"
                                    change={(value) => handle_test('category_id', value as string)}
                                    options={[
                                        {text: "Select a category", value: 0},
                                        ...params.categories.map((_) => ({text: _.category_name, value: _.category_id}))
                                    ]}/>

                                {/*Test Name*/}
                                <span className="label">Test name</span>
                                <MyInput placeholder="Enter test name" name="test_name" value={test.test_name} change={handle_test}/>

                                {/*Test Abbreviation*/}
                                <span className="label">Test abbreviation</span>
                                <MyInput placeholder="Enter abbreviation" name="test_abbr" value={test.test_abbr} change={handle_test}/>

                                {/*Results Type*/}
                                <span className="label">Results interpretation</span>
                                <MySelect
                                    name="results_type" value={test.results_type} placeholder="Select a result type"
                                    change={(value) => handle_test('results_type', value as string)}
                                    options={get_results_type()}/>

                                {/*Test Units*/}
                                <span className="label">Units of results</span>
                                <MyInput placeholder="Enter units" name="results_units" value={test.results_units} change={handle_test}/>

                                {/*Results Time*/}
                                <span className="label">Turn Around Time (minutes)</span>
                                <MyInput placeholder="Enter time" name="time_taken" value={test.time_taken.toString()}
                                         change={handle_test}/>

                                {/*Test Description*/}
                                <Form className="mt-3">
                                    <span className="label">Test description</span>
                                    <TextArea
                                        placeholder='Enter description of test a client may wish to know' value={test.test_desc} rows={4}
                                        onChange={(event, data) => setTest({...test, test_desc: data.value as string})}/>
                                </Form>
                            </div>

                            <div className="col-7 pl-1 pr-0 h-100">
                                <div className="table_test">
                                    <span className="label mt-0">
                                        {
                                            (test.results_type === 'Custom') ? 'Custom results description' :
                                                `Results interpretations for ${test.results_type.toLowerCase()} results`
                                        }
                                    </span>
                                    {
                                        (test.results_type === 'Connected' || test.results_type === 'Distinct') &&
                                        <>
                                            <Table celled striped compact={false} size='small' inverted color='grey' selectable>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell style={{width: '40px'}} textAlign="center"/>
                                                        <Table.HeaderCell style={{width: '80px'}}>
                                                            {test.results_type === 'Connected' ? 'Maximum' : 'Value'}
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>Description</Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>

                                                <Table.Body>
                                                    {
                                                        (test.results_type === 'Connected' ? test.connected : test.distinct)
                                                            .map((result) =>
                                                                <Table.Row key={result.value}>
                                                                    <Table.HeaderCell style={{width: '40px'}} textAlign="center">
                                                                        <Button
                                                                            primary size='mini' icon='trash' compact
                                                                            onClick={() => {
                                                                                setTest({
                                                                                    ...test,
                                                                                    connected: test.results_type === 'Connected' ?
                                                                                        test.connected.filter((_) => _.value !== result.value) : test.connected,
                                                                                    distinct: test.results_type === 'Distinct' ?
                                                                                        test.distinct.filter((_) => _.value !== result.value) : test.distinct
                                                                                })
                                                                            }}/>
                                                                    </Table.HeaderCell>
                                                                    <Table.Cell style={{width: '80px'}}>{result.value}</Table.Cell>
                                                                    <Table.Cell>{result.desc}</Table.Cell>
                                                                </Table.Row>
                                                            )
                                                    }
                                                </Table.Body>
                                            </Table>

                                            <div className="floating_button"
                                                 onClick={() => {
                                                     setShowResults({show: true, type: test.results_type as 'Connected' | 'Distinct'})
                                                     setResults({value: '', desc: ''})
                                                 }}>
                                                <Icon name='add'/>
                                            </div>
                                        </>
                                    }

                                    {
                                        (test.results_type === 'Custom') &&
                                        <Form className="">
                                            <TextArea
                                                placeholder='Enter results description here' value={test.custom} rows={2}
                                                onChange={(event, data) => setTest({...test, custom: data.value as string})}/>
                                        </Form>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="button_container">
                        <div className="row m-0">
                            <div className="col-4 offset-2 pl-0 pr-1">
                                <Button secondary icon="close" labelPosition="left" size="mini" fluid
                                        content={test.test_id === 0 ? 'Close Window' : 'Clear Data'}
                                        onClick={() => test.test_id === 0 ? params.close() : setTest(custom_test)}/>
                            </div>
                            <div className="col-4 pl-1 pr-0">
                                <Button primary icon="save" labelPosition="left" size="mini" fluid
                                        content="Save Test" onClick={save_test}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
