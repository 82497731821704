import {Button, Confirm, Modal, Table} from "semantic-ui-react";
import React from "react";
import {ToastsStore} from "react-toasts";
import {ConfirmAction, initialConfirm} from "../../../../utils/utils/Models";
import {ApiRequest} from "../../../../utils/ApiRequest";
import {tier_type} from "../../Billing";
import Utils from "../../../../utils/utils/Utils";
import {MySelect} from "../../../../utils/components/MySelect";
import {Loading} from "../../../../utils/components/Loading";
import {BillGroup} from "../../../clients/ClientList";

interface Order {
    client_id: number
    group_id: number
    tests: Tier[]
}

interface Tier {
    bill_amount: number
    test_abbr: string
    test_id: number
    test_name: string
    tier_id: number
    tier_type: tier_type
}

const initial_order: Order = {client_id: 0, group_id: 0, tests: []}

export default function CreateBillOrder(params: {
    show: boolean, close: () => void, client_id: number, client_name: string, groups: BillGroup[]
}) {
    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initialConfirm)
    const [order, setOrder] = React.useState(initial_order)
    const [tiers, setTiers] = React.useState(Array<Tier>())

    const save_order = () => {
        if (order.group_id === 0) {
            ToastsStore.error("Select an order payment group")
        } else if (order.tests.length === 0) {
            ToastsStore.error("Select at least one test to order for")
        } else {
            setConfirm({
                ...confirm,
                content: `Are you sure you want to proceed with saving order for "${params.client_name}"`, open: true,
                onCancel: () => {
                    setConfirm({...confirm, open: false})
                },
                onConfirm: () => {
                    setConfirm({...confirm, open: false})
                    setLoader({message: "Creating test order, please wait", show: true})

                    /*preparing the tests*/
                    const tests: { test_id: number, bill_amount: number, tier_type: tier_type }[] =
                        order.tests.map((test) => ({test_id: test.test_id, bill_amount: test.bill_amount, tier_type: test.tier_type}))

                    /*calculating bill amount*/
                    let total = 0
                    order.tests.forEach((test) => {
                        total += test.bill_amount
                    })

                    /*saving the order*/
                    ApiRequest.create_order({
                        group_id: order.group_id, bill_amount: total, tests: JSON.stringify(tests),
                        client_id: params.client_id, discount: 0
                    })
                        .then((response) => {
                            setLoader({message: '', show: false})
                            if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                                ToastsStore.success("Bill order has been created successfully")
                                params.close()
                            } else {
                                ToastsStore.error("Could not create bill order")
                            }
                        })
                        .catch(() => {
                            setLoader({message: '', show: false})
                            ToastsStore.error("Could not create bill order")
                        })
                }
            })
        }
    }

    const get_price = (): string => {
        let total = 0
        order.tests.forEach((test) => {
            total += test.bill_amount
        })
        return Utils.comma_number(total, 'UGX ')
    }

    const load_group_tests = (group_id: number) => {
        setLoader({message: "Loading billing tests, please wait", show: true})

        ApiRequest.get_billing_catalogue({group_id: group_id})
            .then((response) => {
                setLoader({message: '', show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setOrder({...order, group_id: group_id, tests: []})
                    setTiers(response.data['tiers'])
                } else {
                    ToastsStore.error("Could not load billing tests")
                }
            })
            .catch(() => {
                setLoader({message: '', show: false})
                ToastsStore.error("Could not load billing tests")
            })
    }

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm}
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton}
                     size='mini' content={confirm.content} header="Clear Data"/>

            <Modal open={params.show} basic size='small' centered={false} closeOnDimmerClick={false} closeOnDocumentClick={false}>
                <div className="modal_container full_height">
                    <span className="form_header">Request Investigations for {params.client_name}</span>

                    <div className="form_container has_header">
                        <span className="label mt-0">Client Category</span>
                        <MySelect
                            name="group_id" value={order.group_id} placeholder="Select a group"
                            change={(value) => load_group_tests(value as number)}
                            options={[{text: "Select a group name", value: 0}, ...params.groups.map((aGroup) => ({text: aGroup.group_name, value: aGroup.group_id}))]}/>

                        <span className="label">Investigation Required</span>
                        <MySelect
                            name="" value={0} placeholder="Select an investigation"
                            change={(value) => {
                                if (value as number === 0) {
                                    return
                                }
                                setOrder({...order, tests: [...order.tests, tiers.filter((tier) => tier.test_id === value as number)[0]]})
                            }}
                            options={[
                                {text: "Select a test type", value: 0},
                                ...tiers
                                    .filter((tier) => !order.tests.map((test) => (test.test_id)).includes(tier.test_id))
                                    .map((tier) => {
                                        const name = `${tier.test_name} ${tier.test_name !== tier.test_abbr ? `(${tier.test_abbr})` : ''} - ${Utils.comma_number(tier.bill_amount, 'UGX ')}`
                                        return ({text: name, value: tier.test_id});
                                    })
                            ]}/>

                        <span className="label">Investigations Ordered</span>
                        <div className="table_order">
                            <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '40px'}}/>
                                        <Table.HeaderCell>Investigation Name</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '100px'}}>Test Price</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {
                                        order.tests.map((test, index) =>
                                            <Table.Row>
                                                <Table.Cell style={{width: '40px'}} textAlign="center">
                                                    <Button
                                                        primary size='mini' icon='trash' compact
                                                        onClick={() => setOrder({...order, tests: order.tests.filter((_t, _i) => _i !== index)})}/>
                                                </Table.Cell>
                                                <Table.Cell>{`${test.test_name} ${test.test_name !== test.test_abbr ? `(${test.test_abbr})` : ''}`}</Table.Cell>
                                                <Table.Cell style={{width: '100px'}}>
                                                    {Utils.comma_number(test.bill_amount, 'UGX ')}
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>

                        <span className="bill_amount">{get_price()}</span>
                    </div>

                    <div className="button_container">
                        <div className="row m-0">
                            <div className="col-2 pl-0 pr-1">
                                <Button negative icon="close" size="tiny" fluid onClick={params.close}/>
                            </div>
                            <div className="col-5 px-0">
                                <Button negative icon="trash" labelPosition="left" size="tiny" fluid
                                        content="Clear Data" onClick={() => setOrder(initial_order)}/>
                            </div>
                            <div className="col-5 pl-1 pr-0">
                                <Button positive icon="save" labelPosition="left" size="tiny" fluid
                                        content={"Save Order"} onClick={save_order}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
