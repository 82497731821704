import React from "react";
import {Button, Table} from "semantic-ui-react";
import PhoneInput from "react-phone-input-2";
import {ToastsStore} from "react-toasts";
import {Branch} from "./BranchManagement"
import {ApiRequest} from "../../utils/ApiRequest";
import Utils from "../../utils/utils/Utils";
import {getUser} from "../../utils/store/userSlice";
import {useAppSelector} from "../../utils/store/store";
import {Loading} from "../../utils/components/Loading";
import {MySelect} from "../../utils/components/MySelect";
import {MyInput} from "../../utils/components/MyInput";
import {Role} from "./UserRoles";

interface User {
    user_id: number
    first_name: string
    last_name: string
    user_name: string
    mobile_contact: string
    user_role: number
    branch_id: number
    user_status: 'Active' | 'In-Active'
}

export default function UserManagement(params: { branches: Array<Branch> }) {
    const _user = useAppSelector(getUser)

    const [loading, setLoading] = React.useState({show: false, message: ""})
    const [initialUser, setInitialUser] = React.useState<User>({
        user_id: 0, first_name: "", last_name: "", mobile_contact: "+256", user_name: "", user_role: 0,
        branch_id: _user.branch_id, user_status: 'Active'
    })

    const [user, setUser] = React.useState(initialUser)
    const handle_change = (name: string, value: string | number) => {
        setUser({...user, [name]: value})
    }


    const [users, setUsers] = React.useState<Array<User>>([])
    const [roles, setRoles] = React.useState<Array<Role>>([])
    const [search, setSearch] = React.useState({branch_id: _user.branch_id})
    const handle_search = (name: string, value: string | number) => {
        setSearch({...search, [name]: value})
    }

    const load_roles = () => {
        setLoading({message: "Initialising roles data, please wait", show: true})
        ApiRequest.get_user_roles({branch_id: _user.branch_id})
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setRoles(response.data.roles)
                } else {
                    ToastsStore.error("Could not initialise roles, please retry")
                }
            })
            .catch(() => {
                ToastsStore.error("Could not initialise roles, please retry")
                setLoading({message: "", show: false})
            })
    }


    const load_users = () => {
        if (search.branch_id === 0) {
            ToastsStore.info("First select a test center")
            return
        }
        setLoading({message: "Loading users, please wait", show: true})
        ApiRequest.get_users({branch_id: search.branch_id})
            .then((response) => {
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setUsers(response.data.users)
                    if ((response.data.users as Array<any>).length === 0) {
                        ToastsStore.info("No users found")
                    }
                }
                setLoading({message: "", show: false})
            })
            .catch(() => {
                setLoading({message: "", show: false})
            })
    }

    const save_user = () => {
        if (user.branch_id === 0) {
            ToastsStore.info("Select a user branch")
        } else if (Utils.invalid_name(user.first_name.trim())) {
            ToastsStore.error("Enter a valid first name")
        } else if (Utils.invalid_name(user.last_name.trim())) {
            ToastsStore.error("Enter a valid last name")
        } else if (Utils.invalid_name(user.user_name.trim())) {
            ToastsStore.error("Enter a valid username")
        } else if (Utils.invalid_contact(user.mobile_contact.trim())) {
            ToastsStore.error("Enter a valid user contact")
        } else {
            setLoading({message: "Saving User, please wait", show: true})
            console.log(user)
            ApiRequest.save_user({
                first_name: user.first_name, user_id: user.user_id, last_name: user.last_name,
                mobile_contact: user.mobile_contact, user_name: user.user_name,
                user_role: user.user_role, branch_id: user.branch_id,
                user_status: user.user_status
            })
                .then((response) => {
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            if (user.user_id === 0) {
                                setUser({...user, user_id: response.data.user_id})
                                setUsers([{...user, user_id: response.data.user_id}, ...users])
                            } else {
                                setUsers(users.map((_user) => _user.user_id === user.user_id ? user : _user))
                            }
                            ToastsStore.success("User has been successfully created")
                        } else if (response.data.code === 2) {
                            ToastsStore.error("Mobile contact is already in use")
                        } else if (response.data.code === 3) {
                            ToastsStore.error("Username is already in use")
                        }
                    } else {
                        ToastsStore.error("Could not save user, please retry")
                    }
                    setLoading({message: "", show: false})
                })
                .catch(() => {
                    ToastsStore.error("Could not save user, please retry")
                    setLoading({message: "", show: false})
                })
        }
    }

    React.useEffect(() => {
        setSearch({branch_id: user.branch_id})
        setInitialUser({...initialUser, branch_id: user.branch_id})
    }, [user])

    React.useEffect(() => {
        load_users()
        load_roles()
    }, [])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            <div className="content_bar">
                <div className="search_bar">
                    <div className="">
                        <MySelect
                            change={(value) => handle_search('branch_id', value as number)}
                            options={[...params.branches.map((branch) => ({text: branch.branch_name, value: branch.branch_id}))]}
                            name={'branch_id'} value={search.branch_id} placeholder='Select a branch'/>
                    </div>
                </div>

                <div className="content_buttons">
                    <Button primary size='mini' icon='search' labelPosition="left" content="Search Users" onClick={load_users}/>
                </div>
            </div>

            <div className="content_container">
                <div className="row m-0 h-100">
                    <div className="col-12 col-md-8 p-1 h-100">
                        <div className="window_content">
                            <div className="table_container">
                                <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '250px'}}>Full Name</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '100px'}}>User Name</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '150px'}}>User Contact</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            users.map((user, index) =>
                                                <Table.Row
                                                    key={user.user_id}
                                                    onClick={() => setUser({...user, mobile_contact: `+${user.mobile_contact}`})}>
                                                    <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                                    <Table.Cell style={{width: '250px'}}>{user.first_name} {user.last_name}</Table.Cell>
                                                    <Table.Cell style={{width: '100px'}}>{user.user_name}</Table.Cell>
                                                    <Table.Cell style={{width: '150px'}}>{user.mobile_contact}</Table.Cell>
                                                </Table.Row>
                                            )
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 p-1 h-100">
                        <div className="window_content">
                            <div className="form_container">
                                <div className="">
                                    <span className="label">Branch Name</span>
                                    <MySelect
                                        /* disabled={!Utils.has_center_role({role: "manage_centers", roles: user.user_roles})}*/
                                        name={'branch_id'} value={user.branch_id} placeholder='Select a branch'
                                        change={(value) => handle_change('branch_id', value as number)}
                                        options={[...params.branches.map((branch) => ({
                                            text: branch.branch_name, value: branch.branch_id
                                        }))]}/>
                                </div>

                                <span className="label">Given Name</span>
                                <MyInput placeholder="Enter given name" name="first_name" value={user.first_name} change={handle_change}/>

                                <span className="label">Surname</span>
                                <MyInput placeholder="Enter surname" name="last_name" value={user.last_name} change={handle_change}/>

                                <span className="label">Username</span>
                                <MyInput placeholder="Enter username" name="user_name" value={user.user_name} change={handle_change}/>

                                <span className="label">Mobile Contact</span>
                                <PhoneInput
                                    countryCodeEditable={false} country={'ug'} value={user.mobile_contact}
                                    onChange={(value) => handle_change('mobile_contact', value)}/>

                                <span className="label">User Status</span>
                                <MySelect
                                    name={'user_status'} value={user.user_status} placeholder='Select a user status'
                                    change={(value) => handle_change('user_status', value as string)}
                                    options={[{text: 'User is active', value: 'Active'}, {text: 'User disabled', value: 'In-Active'}]}/>

                                <span className="label">User permission group</span>
                                <MySelect
                                    name={'user_role'} value={user.user_role} placeholder='Select a user group'
                                    change={(value) => handle_change('user_role', value as number)}
                                    options={[
                                        {text: 'User has no roles', value: 0},
                                        ...roles.map((aRole) => ({text: aRole.role_name, value: aRole.role_id}))
                                    ]}/>
                            </div>
                            <div className="button_container">
                                <div className="row m-0">
                                    <div className="col-6 pl-0 pr-1">
                                        <Button negative icon="trash" labelPosition="left" size="mini" fluid
                                                content="Clear Data" onClick={() => setUser(initialUser)}/>
                                    </div>
                                    <div className="col-6 pl-1 pr-0">
                                        <Button positive icon="save" labelPosition="left" size="mini" fluid
                                                content="Save User" onClick={save_user}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
