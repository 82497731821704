import React from "react";
import {Loading} from "../../utils/components/Loading";
import {Button, Checkbox, Table} from "semantic-ui-react";
import {MyInput} from "../../utils/components/MyInput";
import {ApiRequest} from "../../utils/ApiRequest";
import {useAppSelector} from "../../utils/store/store";
import {getUser} from "../../utils/store/userSlice";
import {ToastsStore} from "react-toasts";

export class Roles {
    static users = "UR-1"
    static roles = "UR-2"
    static branches = "UR-3"

    static price_management = "Bill-1"
    static handle_payments = "Bill-2"
    static payment_reports = "Bill-3"

    static create_clients = "Cli-1"
    static create_tests = "Cli-2"
    static print_results = "Cli-3"
    static client_triage = "Cli-4"

    static take_lab_samples = "Lab-6"
    static process_lab_samples = "Lab-7"
    static sample_results = "Lab-1"
    static verify_samples = "Lab-2"
    static sample_reports = "Lab-4"
    static lab_catalog = "Lab-5"

    static radiology_testing = "Rad-5"
    static radiology_results = "Rad-1"
    static verify_radiology_samples = "Rad-2"
    static view_radiology_reports = "Rad-3"
    static radiology_catalog = "Rad-4"

    static save_doctor_examinations = "Doc-1"
    static view_doctor_examinations = "Doc-2"
}

type RoleGroup = "system" | "billing" | "clients" | "lab" | "radiology" | "doctor"

const coreRoles: { name: string, value: string, group: RoleGroup }[] = [
    {name: "User Management", value: Roles.users, group: "system"},
    {name: "Branch Management", value: Roles.branches, group: "system"},
    {name: "User Roles and Groups", value: Roles.roles, group: "system"},

    {name: "Price Managements", value: Roles.price_management, group: "billing"},
    {name: "Payments handling", value: Roles.handle_payments, group: "billing"},
    {name: "Payment Reports Viewing", value: Roles.payment_reports, group: "billing"},

    {name: "Client Management", value: Roles.create_clients, group: "clients"},
    {name: "Order lab and radiology samples", value: Roles.create_tests, group: "clients"},
    {name: "Print sample results", value: Roles.print_results, group: "clients"},

    {name: "Manage laboratory catalog", value: Roles.lab_catalog, group: 'lab'},
    {name: "Take lab samples", value: Roles.take_lab_samples, group: 'lab'},
    {name: "Process lab samples", value: Roles.process_lab_samples, group: 'lab'},
    {name: "Save lab sample results", value: Roles.sample_results, group: 'lab'},
    {name: "Verify lab results", value: Roles.verify_samples, group: 'lab'},
    {name: "View laboratory reports", value: Roles.sample_reports, group: 'lab'},

    {name: "Manage radiology catalog", value: Roles.radiology_catalog, group: 'radiology'},
    {name: "Do radiology tests", value: Roles.radiology_testing, group: 'radiology'},
    {name: "Save radiology sample results", value: Roles.radiology_results, group: 'radiology'},
    {name: "Verify radiology results", value: Roles.verify_radiology_samples, group: 'radiology'},
    {name: "View radiology reports", value: Roles.view_radiology_reports, group: 'radiology'},

    {name: "Save client triage results", value: Roles.client_triage, group: "doctor"},
    {name: "Save doctor examination findings", value: Roles.save_doctor_examinations, group: 'doctor'},
    {name: "View doctor examinations reports", value: Roles.view_doctor_examinations, group: 'doctor'},
]

export interface Role {
    branch_id: number
    role_id: number
    role_name: string
    permissions: string[]
}

export default function UserRoles() {
    const user = useAppSelector(getUser)
    const [loading, setLoading] = React.useState({show: false, message: ""})
    const initialRole: Role = {permissions: [], role_id: 0, role_name: "", branch_id: user.branch_id}

    const [roles, setRoles] = React.useState<Array<Role>>([])
    const [role, setRole] = React.useState(initialRole)

    const handle_role = (name: string, checked: boolean) => {
        setRole({...role, permissions: checked ? [...role.permissions, name] : role.permissions.filter((aPerm) => aPerm !== name)})
    }

    const groups: { name: string, id: RoleGroup }[] = [
        {name: "System Management", id: 'system'},
        {name: "Billings & Finance", id: 'billing'},
        {name: "Clients Management", id: 'clients'},
        {name: "Laboratory Section", id: 'lab'},
        {name: "Radiology Section", id: 'radiology'},
        {name: "Doctor's Section", id: 'doctor'}
    ]

    const load_roles = () => {
        setLoading({message: "Loading roles, please wait", show: true})
        ApiRequest.get_user_roles({branch_id: user.branch_id})
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setRoles(response.data.roles)
                } else {
                    ToastsStore.error("Could not load user roles, please retry")
                }
            })
            .catch(() => {
                ToastsStore.error("Could not load user roles, please retry")
                setLoading({message: "", show: false})
            })
    }

    const save_role = () => {
        if (role.role_name.trim().length < 3 || role.role_name.trim().length > 50) {
            ToastsStore.error("Enter a valid role name")
        } else {
            setLoading({message: "Saving role, please wait", show: true})
            ApiRequest.save_user_roles({
                branch_id: role.branch_id, group_id: role.role_id,
                group_name: role.role_name.trim(), permissions: JSON.stringify(role.permissions)
            })
                .then((response) => {
                    setLoading({message: "", show: false})
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            ToastsStore.success("Role name saved successfully")
                            if (role.role_id === 0) {
                                setRoles([...roles, {...role, role_id: response.data.group_id}])
                                setRole({...role, role_id: response.data.group_id})
                            } else {
                                setRoles(roles.map((aRole) => aRole.role_id === role.role_id ? role : aRole))
                            }
                        } else if (response.data.code === 1) {
                            ToastsStore.error(`Role with "${role.role_name}" was already saved`)
                        }
                    } else {
                        ToastsStore.error("Could not save role, please retry")
                    }
                })
                .catch(() => {
                    ToastsStore.error("Could not save role, please retry")
                    setLoading({message: "", show: false})
                })
        }
    }

    React.useEffect(() => {
        load_roles()
    }, [])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            <div className="content_bar">
                <div className="search_bar"></div>

                <div className="content_buttons">
                    <Button primary size='mini' icon='search' labelPosition="left" content="Search Roles" onClick={load_roles}/>
                </div>
            </div>

            <div className="content_container">
                <div className="row m-0 h-100">
                    <div className="col-7 p-1 h-100">
                        <div className="window_content">
                            <div className="table_container">
                                <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '250px'}}>Role Name</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            roles.map((aRole, index) =>
                                                <Table.Row key={aRole.role_id} onClick={() => setRole(aRole)}>
                                                    <Table.Cell style={{width: '50px'}} textAlign="center">{index + 1}</Table.Cell>
                                                    <Table.Cell style={{width: '250px'}}>{aRole.role_name}</Table.Cell>
                                                </Table.Row>
                                            )
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </div>

                    <div className="col-5 p-1 h-100">
                        <div className="window_content">
                            <div className="form_container">
                                <span className="label">Group Name</span>
                                <MyInput placeholder="Enter group name" name="role_name" value={role.role_name}
                                         change={(name, value) => setRole({...role, role_name: value})}/>

                                {
                                    groups.map((group) =>
                                        <div key={group.id}>
                                            <span className="label">{group.name}</span>
                                            {
                                                coreRoles
                                                    .filter((aRole) => aRole.group === group.id)
                                                    .map((aRole) =>
                                                        <div className="user_role" key={aRole.value}>
                                                            <Checkbox
                                                                label={aRole.name}
                                                                checked={role.permissions.includes(aRole.value)}
                                                                onChange={(event, data) => handle_role(aRole.value, data.checked as boolean)}/>
                                                        </div>
                                                    )
                                            }
                                        </div>
                                    )
                                }
                            </div>

                            <div className="button_container">
                                <div className="row m-0">
                                    <div className="col-6 pl-0 pr-1">
                                        <Button negative icon="trash" labelPosition="left" size="mini" fluid
                                                content="Clear Data" onClick={() => setRole(initialRole)}/>
                                    </div>
                                    <div className="col-6 pl-1 pr-0">
                                        <Button positive icon="save" labelPosition="left" size="mini" fluid
                                                content="Save Role" onClick={save_role}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
