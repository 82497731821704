import {Loading} from "../../../utils/components/Loading";
import React from "react";
import {MyInput} from "../../../utils/components/MyInput";
import {Button, Form, Table, TextArea} from "semantic-ui-react";
import {ApiRequest} from "../../../utils/ApiRequest";
import {ToastsStore} from "react-toasts";

export interface Diagnosis {
    diagnosis_id: number
    diagnosis_name: string
    diagnosis_desc: string
}

export default function ServicesAndExaminations() {
    const [loading, setLoading] = React.useState({show: false, message: ""})
    const [search, setSearch] = React.useState({name: ''})
    const [diagnosis, setDiagnosis] = React.useState(Array<Diagnosis>())
    const [service, setService] = React.useState({diagnosis_id: 0, diagnosis_name: '', diagnosis_desc: ''})

    const handle_search = (name: string, value: string) => {
        setSearch({...search, [name]: value})
    }

    const search_exams = () => {
        setLoading({message: "Loading services and examinations, please wait", show: true})
        ApiRequest.get_doctor_diagnosis()
            .then((response) => {
                setLoading({message: '', show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setDiagnosis(response.data.diagnosis)
                } else {
                    ToastsStore.error("Could not load services")
                }
            })
            .catch(() => {
                setLoading({message: '', show: false})
                ToastsStore.error("Could not load services")
            })
    }


    const save_diagnosis = () => {
        if (service.diagnosis_name.length < 3) {
            ToastsStore.error("Enter a valid service name")
        } else {
            setLoading({show: true, message: "Saving service, please wait"})

            ApiRequest.save_doctor_diagnosis({
                diagnosis_id: service.diagnosis_id, diagnosis_desc: service.diagnosis_desc.trim(), diagnosis_name: service.diagnosis_name.trim()
            })
                .then((response) => {
                    setLoading({message: "", show: false})
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            if (service.diagnosis_id === 0) {
                                setDiagnosis([...diagnosis, {...service, diagnosis_id: response.data.diagnosis_id}])
                            } else {
                                setDiagnosis(diagnosis.map((aDiagnosis) => aDiagnosis.diagnosis_id === service.diagnosis_id ? service : aDiagnosis))
                            }
                            setService({...service, diagnosis_id: response.data.diagnosis_id})
                            ToastsStore.success("Service name saved successfully")
                        } else if (response.data.code === 2) {
                            ToastsStore.error("Service name is already in use")
                        }
                    } else {
                        ToastsStore.error("Error while saving service, please retry")
                    }
                })
                .catch(() => {
                    setLoading({message: "", show: false})
                    ToastsStore.error("Error while saving service, please retry")
                })
        }
    }

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            <div className="content_bar">
                <div className="search_bar">
                    <div style={{width: '250px'}}>
                        <MyInput placeholder="Service or examination name" name='name' value={search.name} change={handle_search}/>
                    </div>
                </div>

                <div className="content_buttons">
                    <Button primary size='mini' icon='add' labelPosition="left" content="Add Service"
                            onClick={() => setService({diagnosis_id: 0, diagnosis_name: '', diagnosis_desc: ''})}/>

                    <Button primary size='mini' icon='search' labelPosition="left" content="Search Services" onClick={search_exams}/>
                </div>
            </div>

            <div className="content_container">
                <div className="row h-100 m-0">
                    <div className="col-md-9 p-1 h-100">
                        <div className="window_content">
                            <Table celled striped compact={false} size='small' inverted color='grey' selectable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '40px'}} textAlign="center" colSpan={2}/>
                                        <Table.HeaderCell style={{width: '500px'}}>Service or Examination Name</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        diagnosis.map((aService, index) =>
                                            <Table.Row key={aService.diagnosis_id} onClick={() => setService(aService)}>
                                                <Table.Cell style={{width: '40px'}}>{index + 1}</Table.Cell>
                                                <Table.Cell style={{width: '500px'}}>{aService.diagnosis_name}</Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                    </div>

                    <div className="col-md-3 p-1 h-100">
                        <div className="window_content">
                            <div className="form_container">
                                {/*Category Name*/}
                                <span className="label mt-0">Diagnosis Name</span>
                                <MyInput placeholder="Enter diagnosis name" name="diagnosis_name" value={service.diagnosis_name}
                                         change={(name, value) => setService({...service, diagnosis_name: value})}/>

                                {/*Test Description*/}
                                <Form className="mt-3">
                                    <span className="label">Diagnosis description</span>
                                    <TextArea
                                        placeholder='Enter description of diagnosis' value={service.diagnosis_desc} rows={4}
                                        onChange={(event, data) => setService({...service, diagnosis_desc: data.value as string})}/>
                                </Form>
                            </div>

                            <div className="button_container">
                                <div className="row m-0">
                                    <div className="col-6 pl-0 pr-1">
                                        <Button negative icon="trash" labelPosition="left" size="mini" fluid
                                                content="Clear Data" onClick={() => setService({diagnosis_id: 0, diagnosis_name: '', diagnosis_desc: ''})}/>
                                    </div>
                                    <div className="col-6 pl-1 pr-0">
                                        <Button positive icon="save" labelPosition="left" size="mini" fluid content="Save Service" onClick={save_diagnosis}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
