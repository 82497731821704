import React from "react";
import BranchManagement, {Branch} from "../sys/settings/BranchManagement";
import UserManagement from "../sys/settings/UserManagement";
import Billing from "../sys/billing/Billing";
import BillOrders from "../sys/billing/orders/BillOrders";
import ClientList, {BillGroup} from "../sys/clients/ClientList";
import ClientResults from "../sys/clients/samples/ClientResults";
import LabCatalogue from "../sys/lab/catalogue/LabCatalogue";
import SampleList from "../sys/lab/samples/SampleList";
import SampleReports from "../sys/lab/samples/SampleReports";
import Home from "../sys/Home";
import DoctorMeasurements from "../sys/doctor/measurements/DoctorMeasurements";
import DoctorExaminations from "../sys/doctor/diagnosis/DoctorExaminations";
import {ApiRequest} from "../utils/ApiRequest";
import {ToastsStore} from "react-toasts";
import {Loading} from "../utils/components/Loading";
import axios from "axios";
import Utils from "../utils/utils/Utils";
import {getFCMToken, getUser, logoutUser, updateFCMToken, updateWebToken} from "../utils/store/userSlice";
import {useAppDispatch, useAppSelector} from "../utils/store/store";
import DoctorReports from "../sys/doctor/DoctorReports";
import {Button, Confirm, Modal} from "semantic-ui-react";
import {ConfirmAction, initialConfirm, InputFile, SystemUser} from "../utils/utils/Models";
import UserRoles, {Roles} from "../sys/settings/UserRoles";
import * as localforage from "localforage"
import ServicesAndExaminations from "../sys/doctor/services/ServicesAndExaminations";
import DiagnosisResults from "../sys/doctor/diagnosis/DiagnosisResults";
import StockSupplies from "../sys/pharmacy/stock/StockSupplies";
import StockManagement from "../sys/pharmacy/stock/StockManagement";
import SalesOrders from "../sys/pharmacy/sales/SalesOrders";
import OrdersPayment from "../sys/pharmacy/sales/OrdersPayment";
import SalesReport from "../sys/pharmacy/sales/SalesReport";

export interface MatchedUser {
    client_id: number
    time: number
}

export interface Print {
    client_id: number,
    fingerprints: string,
    id: number
}

type menus = "home" | "office" | "users" | "groups_roles" | "billings" | "billOrders" | "paymentReports" |
    "clientInfo" | "clientResults" | "labCatalogue" | "labSamples" | "labReport" |
    "radiologyCatalogue" | "radiologySamples" | "radiologyReport" |
    "doctorMeasurements" | "doctorExaminations" | "doctorReports" | "servicesManagement" | "serviceOrdersResults" |
    'stockOrders' | 'stockSales' | 'stockSalesReport' | 'stock' | 'stockSuppliers'

export default function SideBar(params: { print: Print }) {
    const dispatch = useAppDispatch()
    const user = useAppSelector(getUser)

    const fcmToken = useAppSelector(getFCMToken)
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initialConfirm)

    const [connected, setConnected] = React.useState(false)
    const [webSocket, setWebSocket] = React.useState<WebSocket | undefined>(undefined)

    const [prints, setPrints] = React.useState<Print[]>([])
    const [matchedUser, setMatchedUser] = React.useState<MatchedUser>({client_id: 0, time: Math.floor(Date.now())})
    const [fingerPrint, setFingerPrint] = React.useState({show: false, userId: 0, values: [], image: ''})

    const save_finger_print = () => {
        setLoading({message: "Saving fingerprints, please wait", show: true})
        ApiRequest.save_client_fingerprints({client_id: fingerPrint.userId, fingerprints: JSON.stringify(fingerPrint.values)})
            .then((response) => {
                setLoading({message: '', show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    ToastsStore.success("Fingerprints enrolled successfully")
                    setFingerPrint({...fingerPrint, show: false})
                } else {
                    ToastsStore.error("Could not save fingerprints' data")
                }
            })
            .catch(() => {
                setLoading({message: '', show: false})
                ToastsStore.error("Could not save fingerprints")
            })
    }


    const [added, setAdded] = React.useState({id: 0, time: 0})
    const addSocketListeners = () => {
        if (webSocket !== undefined) {
            webSocket.onopen = function () {
                setConnected(true)
            }
            webSocket.onclose = function () {
                setConnected(false)
            }
            webSocket.onerror = function (err) {
                console.log(err)
            }
            webSocket.onmessage = function (event) {
                const data = JSON.parse(event.data)
                if (data.hasOwnProperty("type")) {
                    if (data.type === "enrollment") {
                        setFingerPrint({image: data.image, show: true, userId: data.userId, values: data.values})
                    } else if (data.type === "matched") {
                        setMatchedUser({client_id: data.userId, time: Math.floor(Date.now())})
                    } else if (data.type === "added") {
                        setAdded({time: Math.floor(Date.now()), id: data.id})
                    }
                }
            }
        }
    }

    const configureWebSocket = () => {
        try {
            setWebSocket(new WebSocket("ws://127.0.0.1:8086/"))
        } catch (error) {
            console.log(error)
        }
    }

    const [loading, setLoading] = React.useState({show: false, message: ""})

    const [branches, setBranches] = React.useState<Array<Branch>>([])
    const [groups, setGroups] = React.useState(Array<BillGroup>())
    const [users, setUsers] = React.useState(Array<SystemUser>())

    const [menu, setMenu] = React.useState<menus>("home")
    const [page, setPage] = React.useState({title: ""})

    const set_axios_data = () => {
        axios.defaults.baseURL = Utils.BASE_URL;
        axios.defaults.headers['common']['Content-Type'] = 'application/x-www-form-urlencoded';
        axios.defaults.headers['common']['Authorization'] = `Bearer ${user.bearer_token}`
        axios.defaults.timeout = 1000 * 60

        axios.interceptors.response.use(function (response) {
            console.log(response.data)
            console.log(response)

            if (response.data.hasOwnProperty("code") && response.data.code === 401) {
                ToastsStore.info("Account was logged in from another session, your login is required")
                dispatch(logoutUser())
            }

            if (response.data.hasOwnProperty("data") && response.data.data.hasOwnProperty("bearer_token")) {
                dispatch(updateWebToken(response.data.data.bearer_token))
            }
            return response;
        }, function (error) {
            console.log(error)
            return Promise.reject(error);
        });

        configureWebSocket()
        initialise_data()
    }

    const initialise_data = () => {
        setLoading({message: "Initialising billing data, please wait", show: true})

        ApiRequest.initialise_data()
            .then((response) => {
                setLoading({message: '', show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setGroups(response.data['groups'])
                    setUsers(response.data['users'])

                    let aBranches: Array<Branch> = [];
                    (response.data['branches'] as Array<any>).forEach((datum) => {
                        aBranches = [...aBranches, {
                            ...datum,
                            center_logo: new InputFile(datum.center_logo === '' ? '' : `${Utils.BASE_URL}${datum.center_logo}`)
                        }]
                    })
                    setBranches(aBranches)
                } else {
                    ToastsStore.error("Could not initialise data")
                }
            })
            .catch(() => {
                setLoading({message: '', show: false})
                ToastsStore.error("Could not initialise data")
            })

        if (user.bearer_token !== "" && fcmToken.token !== "" && !fcmToken.saved) {
            ApiRequest.update_fcm_token({fcm_token: fcmToken.token})
                .then((response) => {
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        dispatch(updateFCMToken({token: fcmToken.token, saved: true}))
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }

        localforage.getItem("fcmMessages")
            .then((value) => {
                if (value != null) {
                    if (typeof value === "string") {
                        const fcmMessages = JSON.parse(value)
                        if (fcmMessages.hasOwnProperty("logged_out")) {
                            delete fcmMessages['logged_out']
                            dispatch(logoutUser())
                        }
                        localforage.setItem("fcmMessages", JSON.stringify(fcmMessages)).then()
                    }
                }
            })

    }

    const logout_user = () => {
        setConfirm({
            ...confirm, open: true, content: 'Do you want to log out?',
            onConfirm: () => {
                dispatch(logoutUser())
                setConfirm({...confirm, open: false})
            },
            onCancel: () => setConfirm({...confirm, open: false})
        })
    }

    React.useEffect(() => {
        if (connected) {
            setLoading({message: "Initialising clients data", show: true})
            ApiRequest.get_client_fingerprints()
                .then((response) => {
                    setLoading({message: "", show: false})
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        setPrints(response.data.fingerprints)
                    }
                })
                .catch(() => {
                    setLoading({message: "", show: false})
                })
        }
    }, [connected])

    React.useEffect(() => {
        const getPage = () => {
            switch (menu) {
                case "office":
                    return {title: "Branch Management"}
                case "users":
                    return {title: "User Management"}
                case "groups_roles":
                    return {title: "User Roles & Groups"}

                case "billings":
                    return {title: "Billing Management"}
                case "billOrders":
                    return {title: "Bill Orders"}
                case "paymentReports":
                    return {title: "Payment Reports"}

                case "clientInfo":
                    return {title: "Client Information"}
                case "clientResults":
                    return {title: "Sample Results"}

                case "labCatalogue":
                    return {title: "Lab Catalogue"}
                case "labSamples":
                    return {title: "Lab Samples"}
                case "labReport":
                    return {title: "Sample Reports"}

                case "radiologyCatalogue":
                    return {title: "Radiology Catalogue"}
                case "radiologySamples":
                    return {title: "Radiology Samples"}
                case "radiologyReport":
                    return {title: "Radiology Reports"}

                case "doctorMeasurements":
                    return {title: "Triage"}
                case "doctorExaminations":
                    return {title: "Examinations"}
                case "doctorReports":
                    return {title: "Examination Reports"}
                case "servicesManagement":
                    return {title: "Services & Examinations"}
                case "serviceOrdersResults":
                    return {title: "Service Orders & Results"}

                case "stock":
                    return {title: "Stock Management"}
                case "stockSuppliers":
                    return {title: "Stock Suppliers"}

                case "stockOrders":
                    return {title: "Stock Orders"}
                case "stockSales":
                    return {title: "Order Sales"}
                case "stockSalesReport":
                    return {title: "Sales Report"}

                case "home":
                default:
                    return {title: "Home"}
            }
        }
        setPage(getPage())
    }, [menu])

    React.useEffect(() => {
        document.title = `Hospital | ${page.title}`
    }, [page])

    React.useEffect(() => {
        axios.defaults.headers['common']['Authorization'] = `Bearer ${user.bearer_token}`
    }, [user.bearer_token])

    React.useEffect(() => {
        if (prints.length > 0 && webSocket !== undefined) {
            const print = prints[0]
            webSocket.send(JSON.stringify({
                type: "fingerprint", id: print.id,
                client_id: print.client_id,
                fingerprints: print.fingerprints
            }))
        }
    }, [prints, webSocket])

    React.useEffect(() => {
        if (webSocket !== undefined) {
            addSocketListeners()
        }
    }, [webSocket])

    React.useEffect(() => {
        if (params.print.id !== 0) {
            setPrints([...prints, params.print])
        }
    }, [params.print])

    React.useEffect(() => {
        if (added.id !== 0) {
            setPrints(prints.filter((aPrint) => aPrint.id !== added.id))
        }
    }, [added])

    React.useEffect(() => {
        set_axios_data()
    }, [])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm}
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton}
                     size='mini' content={confirm.content}/>

            <section className="app">
                <aside className="sidebar">
                    <header>{`Hello, ${user.user_name}`}</header>

                    <nav className="sidebar-nav">
                        <ul>
                            <li>
                                 <span className={"link"} onClick={() => setMenu("home")}>
                                     <i className="ion-ios-briefcase-outline"></i>Home
                                 </span>
                            </li>

                            {
                                Utils.has_roles(user.roles, [Roles.branches, Roles.users, Roles.roles]) &&
                                <li>
                                    <span className={"link"}>
                                        <i className="ion-ios-briefcase-outline"></i> <span>System Administration</span>
                                    </span>
                                    <ul className="nav-flyout">
                                        {
                                            Utils.has_roles(user.roles, [Roles.branches]) &&
                                            <li>
                                                <span className={"link"} onClick={() => setMenu("office")}>
                                                    <i className="ion-ios-flame-outline"></i>Branch Management
                                                </span>
                                            </li>
                                        }

                                        {
                                            Utils.has_roles(user.roles, [Roles.users]) &&
                                            <li>
                                                <span className="link" onClick={() => setMenu("users")}>
                                                    <i className="ion-ios-flame-outline"></i>User Management
                                                </span>
                                            </li>
                                        }

                                        {
                                            Utils.has_roles(user.roles, [Roles.roles]) &&
                                            <li>
                                                <span className="link" onClick={() => setMenu("groups_roles")}>
                                                    <i className="ion-ios-flame-outline"></i>User Groups & Roles
                                                </span>
                                            </li>
                                        }
                                    </ul>
                                </li>
                            }


                            {
                                Utils.has_roles(user.roles, [Roles.create_clients, Roles.print_results, Roles.create_tests]) &&
                                <li>
                                    <span className="link">
                                        <i className="ion-ios-briefcase-outline"></i> <span className="">Client Management</span>
                                    </span>
                                    <ul className="nav-flyout">
                                        {
                                            Utils.has_roles(user.roles, [Roles.create_clients, Roles.create_tests]) &&
                                            <li>
                                                <span className="link"
                                                      onClick={() => setMenu("clientInfo")}>
                                                    <i className="ion-ios-flame-outline"></i>Client Information
                                                </span>
                                            </li>
                                        }

                                        {
                                            Utils.has_roles(user.roles, [Roles.print_results]) &&
                                            <li>
                                                <span className="link" onClick={() => setMenu("clientResults")}>
                                                    <i className="ion-ios-flame-outline"></i>Client Results
                                                </span>
                                            </li>
                                        }
                                    </ul>
                                </li>
                            }

                            {
                                Utils.has_roles(user.roles, [Roles.price_management, Roles.handle_payments, Roles.payment_reports]) &&
                                <li>
                                    <span className="link">
                                        <i className="ion-ios-briefcase-outline"></i> <span className="">Billing & Accounts</span>
                                    </span>
                                    <ul className="nav-flyout">
                                        {
                                            Utils.has_roles(user.roles, [Roles.price_management]) &&
                                            <li>
                                                <span className="link" onClick={() => setMenu("billings")}>
                                                    <i className="ion-ios-flame-outline"></i>Billing Management
                                                </span>
                                            </li>
                                        }

                                        {
                                            Utils.has_roles(user.roles, [Roles.handle_payments]) &&
                                            <li>
                                                <span className="link" onClick={() => setMenu("billOrders")}>
                                                    <i className="ion-ios-flame-outline"></i>Billing Orders
                                                </span>
                                            </li>
                                        }

                                        {
                                            Utils.has_roles(user.roles, [Roles.payment_reports]) &&
                                            <li>
                                                <span className="link" onClick={() => setMenu("paymentReports")}>
                                                    <i className="ion-ios-flame-outline"></i>Payment Reports
                                                </span>
                                            </li>
                                        }
                                    </ul>
                                </li>
                            }

                            {
                                Utils.has_roles(user.roles, [Roles.lab_catalog, Roles.sample_results, Roles.verify_samples,
                                    Roles.take_lab_samples, Roles.process_lab_samples, Roles.sample_reports]) &&
                                <li>
                                    <span className="link">
                                        <i className="ion-ios-briefcase-outline"></i> <span className="">Laboratory Section</span>
                                    </span>
                                    <ul className="nav-flyout">
                                        {
                                            Utils.has_roles(user.roles, [Roles.lab_catalog]) &&
                                            <li>
                                                <span className="link" onClick={() => setMenu("labCatalogue")}>
                                                    <i className="ion-ios-flame-outline"></i>Lab Catalogue
                                                </span>
                                            </li>
                                        }
                                        {
                                            Utils.has_roles(user.roles, [Roles.sample_results, Roles.verify_samples,
                                                Roles.take_lab_samples, Roles.process_lab_samples]) &&
                                            <li>
                                                <span className="link" onClick={() => setMenu("labSamples")}>
                                                    <i className="ion-ios-flame-outline"></i>Lab Samples
                                                </span>
                                            </li>
                                        }
                                        {
                                            Utils.has_roles(user.roles, [Roles.sample_reports]) &&
                                            <li>
                                                <span className="link" onClick={() => setMenu("labReport")}>
                                                    <i className="ion-ios-flame-outline"></i>Lab Reports
                                                </span>
                                            </li>
                                        }
                                    </ul>
                                </li>
                            }

                            {
                                Utils.has_roles(user.roles, [Roles.radiology_catalog, Roles.radiology_results,
                                    Roles.verify_radiology_samples, Roles.radiology_testing, Roles.view_radiology_reports]) &&
                                <li>
                                    <span className="link">
                                        <i className="ion-ios-briefcase-outline"></i> <span className="">Radiology Section</span>
                                    </span>
                                    <ul className="nav-flyout" data-active={"Hello"}>
                                        {
                                            Utils.has_roles(user.roles, [Roles.radiology_catalog]) &&
                                            <li>
                                                <span className="link" onClick={() => setMenu("radiologyCatalogue")}>
                                                    <i className="ion-ios-flame-outline"></i>Radiology Catalogue
                                                </span>
                                            </li>
                                        }

                                        {
                                            Utils.has_roles(user.roles, [Roles.radiology_results, Roles.verify_radiology_samples]) &&
                                            <li>
                                                <span className="link" onClick={() => setMenu("radiologySamples")}>
                                                    <i className="ion-ios-flame-outline"></i>Radiology Samples
                                                </span>
                                            </li>
                                        }
                                        {
                                            Utils.has_roles(user.roles, [Roles.view_radiology_reports]) &&
                                            <li>
                                                <span className="link" onClick={() => setMenu("radiologyReport")}>
                                                    <i className="ion-ios-flame-outline"></i>Radiology Reports
                                                </span>
                                            </li>
                                        }
                                    </ul>
                                </li>
                            }


                            {
                                Utils.has_roles(user.roles, [Roles.save_doctor_examinations, Roles.view_doctor_examinations, Roles.client_triage]) &&
                                <li>
                                    <span className="link">
                                        <i className="ion-ios-briefcase-outline"></i> <span className="">Doctor's Section</span>
                                    </span>
                                    <ul className="nav-flyout">
                                        {
                                            Utils.has_roles(user.roles, [Roles.client_triage]) &&
                                            <li>
                                                <span className="link" onClick={() => setMenu("doctorMeasurements")}>
                                                    <i className="ion-ios-flame-outline"></i>Triage
                                                </span>
                                            </li>
                                        }

                                        {
                                            Utils.has_roles(user.roles, [Roles.save_doctor_examinations]) &&
                                            <>
                                                <li>
                                                    <span className="link" onClick={() => setMenu("servicesManagement")}>
                                                        <i className="ion-ios-flame-outline"></i>Services & Examinations
                                                    </span>
                                                </li>

                                                <li>
                                                    <span className="link" onClick={() => setMenu("serviceOrdersResults")}>
                                                        <i className="ion-ios-flame-outline"></i>Service Orders & Results
                                                    </span>
                                                </li>

                                                <li>
                                                    <span className="link" onClick={() => setMenu("doctorExaminations")}>
                                                        <i className="ion-ios-flame-outline"></i>Doctor Examinations
                                                    </span>
                                                </li>
                                            </>
                                        }

                                        {
                                            Utils.has_roles(user.roles, [Roles.view_doctor_examinations]) &&
                                            <li>
                                                <span className="link" onClick={() => setMenu("doctorReports")}>
                                                    <i className="ion-ios-flame-outline"></i>Examinations Reports
                                                </span>
                                            </li>
                                        }
                                    </ul>
                                </li>
                            }


                            <li>
                                <span className="link">
                                    <i className="ion-ios-briefcase-outline"></i> <span className="">Pharmacy & Services</span>
                                </span>
                                <ul className="nav-flyout">
                                    <li>
                                        <span className="link" onClick={() => setMenu("stock")}>
                                            <i className="ion-ios-flame-outline"></i>Stock
                                        </span>
                                    </li>
                                    <li>
                                        <span className="link" onClick={() => setMenu("stockSuppliers")}>
                                            <i className="ion-ios-flame-outline"></i>Stock Supply
                                        </span>
                                    </li>

                                    <li>
                                        <span className="link" onClick={() => setMenu("stockOrders")}>
                                            <i className="ion-ios-flame-outline"></i>Sales Orders
                                        </span>
                                    </li>
                                    <li>
                                        <span className="link" onClick={() => setMenu("stockSales")}>
                                            <i className="ion-ios-flame-outline"></i>Order Payment
                                        </span>
                                    </li>
                                    <li>
                                        <span className="link" onClick={() => setMenu("stockSalesReport")}>
                                            <i className="ion-ios-flame-outline"></i>Sales Reports
                                        </span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nav>

                    <div className="side_footer">
                        <div className={`server ${connected ? "on" : "off"}`}>
                            <span>{connected ? "ON" : "OFF"}</span>
                        </div>
                        <Button positive icon="sign-out" size="tiny" onClick={logout_user}/>
                    </div>
                </aside>

                <section className={"side_content"}>
                    <>
                        {menu === "home" && <Home/>}

                        {menu === "office" && <BranchManagement setBranches={setBranches} branches={branches}/>}
                        {menu === "users" && <UserManagement branches={branches}/>}
                        {menu === "groups_roles" && <UserRoles/>}

                        {menu === "billings" && <Billing/>}
                        {menu === "billOrders" && <BillOrders matchedUser={matchedUser} type={'All'}/>}
                        {menu === "paymentReports" && <BillOrders matchedUser={matchedUser} type={'Authorized'}/>}

                        {menu === "clientInfo" && <ClientList groups={groups} webSocket={webSocket} matchedUser={matchedUser}/>}
                        {menu === "clientResults" && <ClientResults matchedUser={matchedUser}/>}

                        {menu === "labCatalogue" && <LabCatalogue type={"lab"}/>}
                        {menu === "labSamples" && <SampleList type={"lab"} matchedUser={matchedUser}/>}
                        {menu === "labReport" && <SampleReports type={"lab"} matchedUser={matchedUser}/>}

                        {menu === "radiologyCatalogue" && <LabCatalogue type={"radiology"}/>}
                        {menu === "radiologySamples" && <SampleList type={"radiology"} matchedUser={matchedUser}/>}
                        {menu === "radiologyReport" && <SampleReports type={"radiology"} matchedUser={matchedUser}/>}

                        {menu === "doctorMeasurements" && <DoctorMeasurements groups={groups} matchedUser={matchedUser}/>}
                        {menu === "doctorExaminations" && <DoctorExaminations groups={groups} matchedUser={matchedUser}/>}
                        {menu === "doctorReports" && <DoctorReports matchedUser={matchedUser}/>}
                        {menu === "servicesManagement" && <ServicesAndExaminations/>}
                        {menu === "serviceOrdersResults" && <DiagnosisResults/>}

                        {menu === "stock" && <StockManagement/>}
                        {menu === "stockSuppliers" && <StockSupplies/>}

                        {menu === "stockOrders" && <SalesOrders branches={branches} users={users}/>}
                        {menu === "stockSales" && <OrdersPayment branches={branches}/>}
                        {menu === "stockSalesReport" && <SalesReport branches={branches} users={users}/>}
                    </>
                </section>
            </section>

            <Modal open={fingerPrint.show} basic size='mini' centered={false} closeOnDimmerClick={false} closeOnDocumentClick={false}>
                <div className="modal_container">
                    <div className="form_container">
                        {
                            fingerPrint.image !== "" &&
                            <img src={`data:image/png;base64,${fingerPrint.image}`} alt="Finger Print"
                                 style={{width: '250px', height: '250px', marginLeft: "calc((100% - 250px)/2)"}}/>
                        }
                    </div>
                    <div className="button_container">
                        <div className="row m-0 align-content-center align-items-center">
                            <div className="col-6 px-0">
                                <Button negative icon="close" labelPosition="left" size="tiny" fluid
                                        content="Close Window" onClick={() => setFingerPrint({...fingerPrint, show: false})}/>
                            </div>
                            <div className="col-6 pl-1 pr-0">
                                <Button positive icon="save" labelPosition="left" size="tiny" fluid
                                        content={"Save Fingerprint"} onClick={save_finger_print}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
