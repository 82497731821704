import React from "react";
import {Button, Table} from "semantic-ui-react";
import BillingGroups, {BillingGroup} from "./utils/BillingGroups";
import {ApiRequest} from "../../utils/ApiRequest";
import {ToastsStore} from "react-toasts";
import {MySelectMulti} from "../../utils/components/MySelectMulti";
import Utils from "../../utils/utils/Utils";
import EditPrice, {PriceEdit} from "./utils/EditPrice";
import {Loading} from "../../utils/components/Loading";

interface Test {
    category_id: number
    test_id: number
    test_abbr: string
    test_name: string
}

interface Category {
    category_id: number
    category_name: string
}

export type tier_type = 'LabTest' | 'DoctorService'

const tier_types: { text: string; value: tier_type }[] = [
    {text: 'Lab & Radiology Tests', value: 'LabTest'},
    {text: 'Service & Diagnosis', value: 'DoctorService'}
]

interface Billings {
    bill_amount: number | string
    category_name: string
    expiry_date: string
    group_id: number
    group_name: string
    test_abbr: string
    test_id: number
    test_name: string
    tier_id: number
    updated: string
    username: string
    tier_type: tier_type
}

interface Service {
    service_id: number
    service_name: string
}

export default function Billing() {
    const [loading, setLoading] = React.useState({show: false, message: ""})
    const [search, setSearch] = React.useState<{ groups: number[], categories: number[], tests: number[], tier_type: tier_type[], services: number[] }>(
        {groups: [], categories: [], tests: [], tier_type: ['LabTest', 'DoctorService'], services: []})

    const [showGroup, setShowGroup] = React.useState(false)
    const [groups, setGroups] = React.useState(Array<BillingGroup>())
    const [tests, setTests] = React.useState(Array<Test>())
    const [categories, setCategories] = React.useState(Array<Category>())
    const [services, setServices] = React.useState(Array<Service>())
    const [billings, setBillings] = React.useState(Array<Billings>())
    const [priceEdit, setPriceEdit] = React.useState<PriceEdit>({
        title: '', bill_amount: '', group_id: 0, test_id: 0, tier_id: 0, show: false, index: 0, tier_type: 'LabTest'
    })

    const initialize_data = () => {
        setLoading({show: true, message: "Initialising billing data, please wait"})
        ApiRequest.init_billing_data()
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setTests(response.data.tests)
                    setGroups(response.data.groups)
                    setServices(response.data.services)
                    setCategories(response.data.categories)
                    ToastsStore.success("Billing data initialised successfully")
                } else {
                    ToastsStore.error("Error while initialising billing data, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while initialising billing data, please retry")
            })
    }

    const search_billing_data = () => {
        if (search.groups.length === 0) {
            ToastsStore.error("At least 1 billing group must be selected")
        } else {
            setLoading({show: true, message: "Loading billing information, please wait"})
            ApiRequest.get_billing_data({
                groups: JSON.stringify(search.groups),
                tier_types: JSON.stringify(search.tier_type),
                tests: JSON.stringify(search.tests),
                categories: JSON.stringify(search.categories),
                services: JSON.stringify(search.services)
            })
                .then((response) => {
                    setLoading({message: "", show: false})
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        setBillings(response.data['billings'])
                        ToastsStore.success("Billing information loaded successfully")
                    } else {
                        ToastsStore.error("Error while loading billing information, please retry")
                    }
                })
                .catch(() => {
                    setLoading({message: "", show: false})
                    ToastsStore.error("Error while loading billing information, please retry")
                })
        }
    }

    React.useEffect(() => {
        setSearch({
            ...search,
            tests: search.tests.filter((test) => search.categories.includes((tests.filter((_test) => _test.test_id === test))[0].category_id))
        })
    }, [search.categories])

    React.useEffect(() => {
        initialize_data()
    }, [])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            <BillingGroups
                show={showGroup} groups={groups} close={() => setShowGroup(false)}
                update={(group, is_new) => {
                    if (is_new) {
                        setGroups([...groups, group])
                    } else {
                        setGroups(groups.map((_group) => _group.billing_group_id === group.billing_group_id ? group : _group))
                    }
                }}/>

            <EditPrice
                title={priceEdit.title} bill_amount={priceEdit.bill_amount} group_id={priceEdit.group_id} tier_type={priceEdit.tier_type}
                test_id={priceEdit.test_id} tier_id={priceEdit.tier_id} show={priceEdit.show} index={priceEdit.index}
                close={() => setPriceEdit({...priceEdit, show: false})}
                update={(tier_id: number, bill_amount: string, username: string, time: string, index) => {
                    setBillings(billings.map((billing, _index) => _index !== index ? billing : {
                        ...billing, tier_id: tier_id, bill_amount: bill_amount, username: username, updated: time
                    }))
                    setPriceEdit({...priceEdit, show: false})
                }}/>

            <div className="content_bar">
                <div className="search_bar">
                    <div>
                        <MySelectMulti
                            multiple={true} placeholder="Tier Type" selected={search.tier_type}
                            items={tier_types.map((aType) => ({value: aType.value, text: aType.text}))}
                            change={(value) => setSearch({...search, tier_type: value as tier_type[]})}/>
                    </div>

                    <div style={{width: '220px'}}>
                        <MySelectMulti
                            multiple={true} placeholder="Billing groups" selected={search.groups}
                            items={groups.map((group) => ({value: group.billing_group_id, text: group.billing_group_name}))}
                            change={(value) => setSearch({...search, groups: value as number[]})}/>
                    </div>

                    {
                        search.tier_type.includes('LabTest') &&
                        <>
                            <div>
                                <MySelectMulti
                                    multiple={true} placeholder="Test categories" selected={search.categories}
                                    items={categories.map((category) => ({value: category.category_id, text: category.category_name}))}
                                    change={(value) => setSearch({...search, categories: value as number[]})}/>
                            </div>
                            <div>
                                <MySelectMulti
                                    multiple={true} placeholder="Lab Tests" selected={search.tests}
                                    items={
                                        tests
                                            .filter((test) => search.categories.includes(test.category_id))
                                            .map((test) => ({value: test.test_id, text: test.test_name}))
                                    }
                                    change={(value) => setSearch({...search, tests: value as number[]})}/>
                            </div>
                        </>
                    }

                    {
                        search.tier_type.includes('DoctorService') &&
                        <div style={{width: '220px'}}>
                            <MySelectMulti
                                multiple={true} placeholder="Services & Diagnosis" selected={search.services}
                                items={services.map((service) => ({value: service.service_id, text: service.service_name}))}
                                change={(value) => setSearch({...search, services: value as number[]})}/>
                        </div>
                    }

                </div>

                <div className="content_buttons">
                    <Button primary size='mini' icon='refresh' labelPosition="left" content="Initialise data" onClick={initialize_data}/>
                    <Button primary size='mini' icon='add' labelPosition="left" content="Billing Groups" onClick={() => setShowGroup(true)}/>
                    <Button primary size='mini' icon='search' labelPosition="left" content="Search Pricings" onClick={search_billing_data}/>
                </div>
            </div>

            <div className="content_container">
                <div className="window_content">
                    <Table celled striped compact={false} size='small' inverted color='grey' selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '50px'}} textAlign="center"/>
                                <Table.HeaderCell style={{width: '150px'}}>Billing Group</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '150px'}}>Category Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '200px'}}>Test Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '140px'}}>Bill Amount</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '140px'}}>Expiry Date</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '180px'}}>Modified By</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '180px'}}>Last Modified</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                billings.map((billing, index) =>
                                    <Table.Row key={index}>
                                        <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                        <Table.Cell style={{width: '50px'}} textAlign="center">
                                            <Button
                                                primary size='mini' icon='edit' compact
                                                onClick={() => {
                                                    setPriceEdit({
                                                        title: `${billing.group_name}<br/>${billing.test_name}`,
                                                        bill_amount: billing.bill_amount, group_id: billing.group_id,
                                                        test_id: billing.test_id, tier_id: billing.tier_id, show: true,
                                                        index: index, tier_type: billing.tier_type
                                                    })
                                                }}/>
                                        </Table.Cell>
                                        <Table.Cell style={{width: '150px'}}>{billing.group_name}</Table.Cell>
                                        <Table.Cell style={{width: '150px'}}>{billing.category_name}</Table.Cell>
                                        <Table.Cell style={{width: '200px'}}>
                                            {billing.test_name} {billing.test_name !== billing.test_abbr ? `(${billing.test_abbr})` : ''}
                                        </Table.Cell>
                                        <Table.Cell style={{width: '140px'}}>
                                            {billing.bill_amount === '' ? 'N/A' : Utils.comma_number(parseFloat(billing.bill_amount.toString()))}
                                        </Table.Cell>
                                        <Table.Cell style={{width: '140px'}}>
                                            {
                                                billing.bill_amount === '' ? '' :
                                                    (billing.expiry_date === '' ? 'Open' : Utils.localise_date(billing.expiry_date))
                                            }
                                        </Table.Cell>
                                        <Table.Cell style={{width: '180px'}}>{billing.username}</Table.Cell>
                                        <Table.Cell style={{width: '180px'}}>{Utils.localise_date_time(billing.updated)}</Table.Cell>
                                    </Table.Row>
                                )
                            }
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </>
    )
}
