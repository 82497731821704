import axios from 'axios'
import Utils from "./utils/Utils";
import {InputFile, SupplyItem} from "./utils/Models";

export class ApiRequest {
    /******************************** BRANCH MANAGEMENT ************************/
    static initialise_data() {
        return axios({method: 'get', url: 'hospital/initialise/'});
    }

    static save_branch(params: {
        branch_id: number, branch_name: string, branch_logo: InputFile, contact_address: string, email_address: string,
        box_number: string, location: string, rad_prefix: string, lab_prefix: string, client_prefix: string
    }) {
        return axios({method: 'post', url: 'hospital/branches/save', data: Utils.format_post_params(params)});
    }

    /******************************** USER MANAGEMENT ************************/
    static save_user(data: {
        first_name: string, last_name: string, user_name: string, mobile_contact: string,
        user_role: number, user_id: number, branch_id: number, user_status: string
    }) {
        return axios({method: 'post', url: 'hospital/users/save', data: Utils.format_post_params(data)});
    }

    static get_users(params: { branch_id: number }) {
        return axios({method: 'get', url: 'hospital/users/get', params: params});
    }

    static save_user_roles(data: { group_id: number, branch_id: number, group_name: string, permissions: string }) {
        return axios({method: 'post', url: 'hospital/users/roles/save', data: Utils.format_post_params(data)});
    }

    static get_user_roles(params: { branch_id: number }) {
        return axios({method: 'get', url: 'hospital/users/roles/get', params: params});
    }

    static login_user(data: { credential: string, login_password: string, fcm_token: string }) {
        return axios({method: 'post', url: 'hospital/users/auth/login', data: Utils.format_post_params(data)});
    }

    static update_fcm_token(data: { fcm_token: string }) {
        return axios({method: 'post', url: 'hospital/users/auth/fcm', data: Utils.format_post_params(data)});
    }

    static change_password(data: { old_password: string, new_password: string }) {
        return axios({method: 'post', url: 'hospital/users/auth/password', data: Utils.format_post_params(data)});
    }

    /********************* CLIENT MANAGEMENT ******************************/
    static save_client(data: {
        client_id: number, first_name: string, last_name: string, other_name: string, client_gender: string, date_of_birth: string
        mobile_contact: string, email_address: string, passport_no: string, nationality: string, village: string, sub_county: string
        parish: string, district: string, next_of_kin: string, next_of_kin_contact: string, passport_photo: string, group_id: number
    }) {
        return axios({method: 'post', url: 'hospital/client/save', data: Utils.format_post_params(data)});
    }

    static search_clients(params: { search: string, client_id?: number }) {
        return axios({method: 'get', url: 'hospital/client/search', params: params});
    }

    static get_clients_list(params: { search: string }) {
        return axios({method: 'get', url: 'hospital/client/get', params: params});
    }

    static save_client_fingerprints(data: { client_id: number, fingerprints: string }) {
        return axios({method: 'post', url: 'hospital/client/fingerprints/save', data: Utils.format_post_params(data)});
    }

    static get_client_fingerprints() {
        return axios({method: 'get', url: 'hospital/client/fingerprints/get'});
    }

    /********************* LABORATORY CATALOGUE ******************************/
    static get_list_catalogue(params: { category_type: 'lab' | 'radiology' }) {
        return axios({method: 'get', url: 'hospital/lab/catalogue/list', params: params});
    }

    static get_lab_catalogue(params: { type: 'lab' | 'radiology' }) {
        return axios({method: 'get', url: 'hospital/lab/catalogue/lab', params: params});
    }

    static print_lab_catalogue() {
        return axios({method: 'get', url: 'hospital/lab/catalogue/print'});
    }

    static save_test_category(data: { category_id: number, category_name: string, category_type: 'lab' | 'radiology' }) {
        return axios({method: 'post', url: 'hospital/lab/catalogue/category', data: Utils.format_post_params(data)});
    }

    static save_test(data: {
        test_id: number, category_id: number, test_name: string, test_abbr: string, test_desc: string,
        results_type: 'Connected' | 'Distinct' | 'Custom', results_values: string, results_units: string, time_taken: string | number
    }) {
        return axios({method: 'post', url: 'hospital/lab/catalogue/test', data: Utils.format_post_params(data)});
    }

    /*BILLING MANAGEMENT*/
    static init_billing_data() {
        return axios({method: 'get', url: 'hospital/billing/manage/init'});
    }

    static save_billing_group(data: { billing_group_id: number, billing_group_name: string, expiry_date: string }) {
        return axios({method: 'post', url: 'hospital/billing/manage/group', data: Utils.format_post_params(data)});
    }

    static get_billing_data(params: { groups: string, categories: string, tests: string, tier_types: string, services: string }) {
        return axios({method: 'get', url: 'hospital/billing/manage/billings', params: params});
    }

    static save_billing_data(data: { bill_amount: string, group_id: number, test_id: number, tier_id: number, tier_type: string }) {
        return axios({method: 'post', url: 'hospital/billing/manage/billings', data: Utils.format_post_params(data)});
    }

    /*ORDER MANAGEMENT*/
    static get_billing_catalogue(params: { group_id: number }) {
        return axios({method: 'get', url: 'hospital/billing/bill/create/catalogue', params: params});
    }

    static create_order(data: { client_id: number, discount: number, bill_amount: number, tests: string, group_id: number }) {
        return axios({method: 'post', url: 'hospital/billing/bill/create/order', data: Utils.format_post_params(data)});
    }

    static search_billing_orders(params: { min_date: string, max_date: string, status: string, name: string, client_id?: number }) {
        return axios({method: 'get', url: 'hospital/billing/bill/order/orders', params: params});
    }

    static cancel_billing_order(data: { bill_id: number, reason: string }) {
        return axios({method: 'post', url: 'hospital/billing/bill/order/cancel', data: Utils.format_post_params(data)});
    }

    static authorize_billing_order(data: { bill_id: number, reason: string }) {
        return axios({method: 'post', url: 'hospital/billing/bill/order/authorize', data: Utils.format_post_params(data)});
    }

    static pay_billing_order(data: { bill_id: number, total_paid: number, payments: string }) {
        return axios({method: 'post', url: 'hospital/billing/bill/order/pay', data: Utils.format_post_params(data)});
    }

    static get_billing_receipt(params: { bill_id: number }) {
        return axios({method: 'get', url: 'hospital/billing/bill/order/receipt', params: params});
    }

    /*LAB SAMPLES*/
    static get_lab_samples(params: {
        status: string, min_date: string, max_date: string,
        name: string, type: 'lab' | 'radiology', client_id?: number
    }) {
        return axios({method: 'get', url: 'hospital/lab/samples/list', params: params});
    }

    static save_sample_results(data: { sample_id: number, results: string, results_desc: string }) {
        return axios({method: 'post', url: 'hospital/lab/samples/results', data: Utils.format_post_params(data)});
    }

    static update_sample_status(data: { sample_id: number, status: "Sampling" | "Processing" | "Verified" }) {
        return axios({method: 'post', url: 'hospital/lab/samples/status', data: Utils.format_post_params(data)});
    }

    static get_sample_reports(params: {
        status: string, min_date: string, max_date: string, categories: string,
        tests: string, name: string, type: 'lab' | 'radiology', client_id?: number
    }) {
        return axios({method: 'get', url: 'hospital/lab/samples/reports', params: params});
    }

    static get_sample_results(params: { min_date: string, max_date: string, name: string, client_id?: number }) {
        return axios({method: 'get', url: 'hospital/lab/samples/results', params: params});
    }

    static print_bill_results(params: { bill_id: number }) {
        return axios({method: 'get', url: 'hospital/lab/samples/print/bill', params: params});
    }

    static email_bill_results(params: { bill_id: number, message: string }) {
        return axios({method: 'get', url: 'hospital/lab/samples/print/mail', params: params});
    }

    static print_sample_results(params: { sample_id: number }) {
        return axios({method: 'get', url: 'hospital/lab/samples/print/sample', params: params});
    }

    /*DOCTORS SECTION*/
    static save_doctor_measurements(data: {
        measurement_id: number, client_id: number, temperature: string | number, diastole: string | number,
        systole: string | number, heart_rate: string | number, height: string | number,
        weight: string | number, bmi: string | number, comments: string,
    }) {
        return axios({method: 'post', url: 'hospital/doctor/measurements/save', data: Utils.format_post_params(data)});
    }

    static get_client_measurements(params: { client_id: number }) {
        return axios({method: 'get', url: 'hospital/doctor/measurements/list', params: params});
    }

    static get_diagnosis_orders(params: { min_date: string, max_date: string, name: string }) {
        return axios({method: 'get', url: 'hospital/doctor/examinations/results', params: params});
    }

    static update_diagnosis_results(data: { remarks: string, examination_id: number }) {
        return axios({method: 'post', url: 'hospital/doctor/examinations/update', data: Utils.format_post_params(data)});
    }

    static get_doctor_examinations(params: { client_id: number }) {
        return axios({method: 'get', url: 'hospital/doctor/examinations/list', params: params});
    }

    static save_doctor_diagnosis(data: { diagnosis_name: string, diagnosis_desc: string, diagnosis_id: number, }) {
        return axios({method: 'post', url: 'hospital/doctor/diagnosis/save', data: Utils.format_post_params(data)});
    }

    static get_doctor_diagnosis() {
        return axios({method: 'get', url: 'hospital/doctor/diagnosis/list'});
    }

    static get_doctor_report(params: { categories: string, min_date: string, max_date: string, name: string, client_id?: number }) {
        return axios({method: 'get', url: 'hospital/doctor/reports/list', params: params});
    }

    static print_doctor_report(params: { client_id: number, start_date: string, end_date: string }) {
        return axios({method: 'get', url: 'hospital/doctor/reports/print', params: params});
    }

    /*STOCKS*/
    static initialise_stocks() {
        return axios({method: 'get', url: 'hospital/stocks/init/get'});
    }

    static save_stock_types(data: { type_name: string, type_category: string, type_id: number, }) {
        return axios({method: 'post', url: 'hospital/stocks/init/type', data: Utils.format_post_params(data)});
    }

    static save_stock_suppliers(data: { supplier_id: number, supplier_name: string, contact: string, email: string, address: string }) {
        return axios({method: 'post', url: 'hospital/stocks/init/supplier', data: Utils.format_post_params(data)});
    }

    static save_stock_units(data: { unit_id: number, unit_name: string }) {
        return axios({method: 'post', url: 'hospital/stocks/init/unit', data: Utils.format_post_params(data)});
    }

    static get_stock(params: { quantity: string, output: string, name: string }) {
        return axios({method: 'get', url: 'hospital/stocks/stock/get', params: params});
    }

    static save_stock(data: {
        stock_id: number, stock_type: number, unit_id: number, sale_price: number,
        stock_name: string, generic_name: string, threshold_quantity: number
    }) {
        return axios({method: 'post', url: 'hospital/stocks/stock/save', data: Utils.format_post_params(data)});
    }

    static get_stocking_list(params: { suppliers: string, min_date: string, max_date: string }) {
        return axios({method: 'get', url: 'hospital/stocks/supplies/get', params: params});
    }

    static save_supplier_restock(data: {
        supply_id: number, supplier_id: number, bill_no: string, bill_date: string, supply_balance: number,
        supply_items: string, supply_cost: number, supply_date: string, profit_margin: number, payment_type: string
    }) {
        return axios({method: 'post', url: 'hospital/stocks/supplies/save', data: Utils.format_post_params(data)});
    }

    static initialise_sale() {
        return axios({method: 'get', url: 'hospital/stocks/sales/init'});
    }

    static make_sale(data: { sale_id: number, items: string, total_price: number, total_discount: number, client_id: number, sale_type: string }) {
        return axios({method: 'post', url: 'hospital/stocks/sales/orders/save', data: Utils.format_post_params(data)});
    }

    static cancel_order(data: { sale_id: number, cancel_reason: string }) {
        return axios({method: 'post', url: 'hospital/stocks/sales/orders/cancel', data: Utils.format_post_params(data)});
    }

    static verify_order(data: { sale_id: number, sale_type: string, total_discount: number }) {
        return axios({method: 'post', url: 'hospital/stocks/sales/orders/verify', data: Utils.format_post_params(data)});
    }

    static get_sales_orders(params: { branch_id: number, ordered_by: number, min_date: string, max_date: string }) {
        return axios({method: 'get', url: 'hospital/stocks/sales/orders/get', params: params});
    }

    static get_sales_reports(params: {
        branch_id: number, ordered_by: number, verified_by: number,
        min_date: string, max_date: string, sale_type: string, output: string,
    }) {
        return axios({method: 'get', url: 'hospital/stocks/sales/sales/get', params: params});
    }

    static get_sales_receipt(params: { sale_id: number }) {
        return axios({method: 'get', url: 'hospital/stocks/sales/sales/receipt', params: params});
    }
}
