import {ClientInfo} from "../../utils/utils/Models";
import {Button, Modal} from "semantic-ui-react";
import React from "react";
import avatar from "../../assets/images/avatar.png"
import Utils from "../../utils/utils/Utils";

export default function ClientDisplay(params: { callback?: () => void, client?: ClientInfo, close: () => void }) {

    return (
        <>
            <Modal open={params.client !== undefined} basic size='mini' centered={false} closeOnDimmerClick={params.callback !== undefined}
                   closeOnDocumentClick={params.callback !== undefined} onClose={params.close}>
                {
                    params.client !== undefined &&
                    <div className="modal_container">
                        <div className="form_container">
                            <img src={`${params.client.dp === '' ? avatar : `${Utils.BASE_URL}${params.client.dp}`}`} alt="Finger Print"
                                 style={{width: '300px', height: '300px', marginLeft: "calc((100% - 300px)/2)"}}/>

                            <div className={"client_info_display"}>
                                <div className={'title'}>Full Name</div>
                                <div className={"text"}>{params.client.name}</div>

                                <div className={'title'}>Client No.</div>
                                <div className={"text"}>{params.client.no}</div>

                                <div className={'title'}>Client Gender</div>
                                <div className={"text"}>{params.client.gender}</div>

                                <div className={'title'}>Date of Birth</div>
                                <div className={"text"}>{Utils.localise_date(params.client.dob)} ({params.client.age} yrs)</div>

                                <div className={'title'}>Passport No / ID No.</div>
                                <div className={"text"}>{params.client.id_}</div>

                                <div className={'title'}>Client Contact</div>
                                <div className={"text"}>{params.client.phone}</div>
                                {/*company: number */}
                            </div>
                        </div>

                        <div className="button_container">
                            <div className="row m-0 align-content-center align-items-center">
                                {
                                    params.callback !== undefined ?
                                        <>
                                            <div className="col-6 px-0">
                                                <Button negative icon="close" labelPosition="left" size="tiny" fluid
                                                        content="Close Window" onClick={params.close}/>
                                            </div>
                                            <div className="col-6 pl-1 pr-0">
                                                <Button positive icon="save" labelPosition="left" size="tiny" fluid
                                                        content={"Use Data"} onClick={params.callback}/>
                                            </div>
                                        </> :

                                        <div className="col-6 offset-3 px-0">
                                            <Button negative icon="close" labelPosition="left" size="tiny" fluid
                                                    content="Close Window" onClick={params.close}/>
                                        </div>

                                }

                            </div>
                        </div>


                    </div>
                }
            </Modal>
        </>
    )

}
