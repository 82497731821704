import {Button, Table} from "semantic-ui-react";
import React from "react";
import {MyInput} from "../../../utils/components/MyInput";
import {ApiRequest} from "../../../utils/ApiRequest";
import {ToastsStore} from "react-toasts";
import {Loading} from "../../../utils/components/Loading";
import Utils from "../../../utils/utils/Utils";
import {BillGroup} from "../../clients/ClientList";
import PrintReports from "./utils/PrintReports";
import {MatchedUser} from "../../../nav/SideBar";
import {ClientInfo} from "../../../utils/utils/Models";
import avatar from "../../../assets/images/avatar.png";
import ClientDisplay from "../../clients/ClientDisplay";
import {Diagnosis} from "../services/ServicesAndExaminations";
import CreateBillOrder from "../../billing/orders/utils/CreateBillOrder";

export interface Examination {
    examination_id: number
    diagnosis_id: number
    client_id: number
    doctor_id: number
    remarks: string
    time_examined: string
    time_created: string
}

export default function DoctorExaminations(params: { groups: BillGroup[], matchedUser: MatchedUser }) {
    const [loading, setLoading] = React.useState({show: false, message: ""})
    const [search, setSearch] = React.useState({search_name: ""})
    const [examinations, setExaminations] = React.useState(Array<Examination>())
    const [diagnosis, setDiagnosis] = React.useState(Array<Diagnosis>())
    const [clients, setClients] = React.useState(Array<ClientInfo>())

    const initial_examination: Examination = {
        client_id: 0, diagnosis_id: 0, doctor_id: 0, examination_id: 0, remarks: "", time_created: "", time_examined: ""
    }

    const [showRequest, setShowRequest] = React.useState({show: false, client_id: 0, client_name: ""})
    const [showReport, setShowReport] = React.useState({show: false, client_id: 0, client_name: ""})
    const [clientInfo, setClientInfo] = React.useState<ClientInfo | undefined>(undefined)

    const handle_search = (name: string, value: string | number) => {
        setSearch({...search, [name]: value})
    }

    const get_diagnosis = () => {
        setLoading({message: "Initialising data, please wait", show: true})

        ApiRequest.get_doctor_diagnosis()
            .then((response) => {
                setLoading({message: '', show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setDiagnosis(response.data['diagnosis'])
                } else {
                    ToastsStore.error("Could not initialise data")
                }
            })
            .catch(() => {
                setLoading({message: '', show: false})
                ToastsStore.error("Could not initialise data")
            })
    }

    const search_clients = () => {
        if (search.search_name.trim().length < 3) {
            ToastsStore.info("At least 3 characters must be inserted")
        } else {
            setLoading({message: "Searching client data, please wait", show: true})

            ApiRequest.get_clients_list({search: search.search_name.trim()})
                .then((response) => {
                    setLoading({message: '', show: false})
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        setClients(response.data.clients)
                    } else {
                        ToastsStore.error("Could not load clients' data")
                    }
                })
                .catch(() => {
                    setLoading({message: '', show: false})
                    ToastsStore.error("Could not load clients' data")
                })
        }
    }

    const search_examinations = (client_id: number) => {
        setLoading({message: "Loading records, please wait", show: true})

        ApiRequest.get_doctor_examinations({client_id: client_id})
            .then((response) => {
                setLoading({message: '', show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setExaminations(response.data['examinations'])
                } else {
                    ToastsStore.error("Could not client's records")
                }
            })
            .catch(() => {
                setLoading({message: '', show: false})
                ToastsStore.error("Could not client's records")
            })
    }

    React.useEffect(() => {
        if (clients.length === 1) {
            search_examinations(clients[0].id)
        }
        setExaminations([])
    }, [clients])

    React.useEffect(() => {
        get_diagnosis()
    }, [])

    React.useEffect(() => {
        if (params.matchedUser.client_id !== 0) {
            search_examinations(params.matchedUser.client_id)
        }
    }, [params.matchedUser.time])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>
            <ClientDisplay close={() => setClientInfo(undefined)} client={clientInfo}/>

            <CreateBillOrder
                show={showRequest.show} client_id={showRequest.client_id} client_name={showRequest.client_name}
                groups={params.groups} close={() => setShowRequest({...showRequest, show: false})}/>

            <PrintReports
                show={showReport.show} client_id={showReport.client_id} client_name={showReport.client_name}
                close={() => setShowReport({...showReport, show: false})}/>

            <div className="content_bar">
                <div className="search_bar">
                    <div style={{width: '250px'}}>
                        <MyInput
                            placeholder="Client name or email address" name='search_name' value={search.search_name}
                            change={handle_search} enter={search_clients}/>
                    </div>
                </div>

                <div className="content_buttons">
                    <Button primary size='mini' icon='search' labelPosition="left" content="Search Clients" onClick={search_clients}/>
                </div>
            </div>

            <div className="content_container">
                <div className="row h-100 m-0">
                    <div className="col-12 col-md-5 p-1 h-100">
                        <div className="window_content">
                            <Table celled striped compact={false} size='small' inverted color='grey' selectable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '80px'}} textAlign="center" colSpan={2}/>
                                        <Table.HeaderCell style={{width: '70px'}}/>
                                        <Table.HeaderCell style={{width: '200px'}}>Client Name</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        clients.map((client) =>
                                            <Table.Row key={client.id}>
                                                <Table.Cell style={{width: '80px'}}>
                                                    <Button
                                                        primary size='mini' icon='add' compact
                                                        onClick={() => setShowRequest({show: true, client_id: client.id, client_name: `${client.name}`})}/>

                                                    <Button
                                                        primary size='mini' icon='print' compact
                                                        onClick={() => setShowReport({show: true, client_id: client.id, client_name: `${client.name}`})}/>

                                                    <Button primary size='mini' icon='search' compact onClick={() => search_examinations(client.id)}/>
                                                </Table.Cell>
                                                <Table.Cell style={{width: '70px'}}>
                                                    <img
                                                        onClick={() => setClientInfo(client)} className={"table_avatar"} alt="Passport"
                                                        src={`${(client.dp === '') ? avatar : `${Utils.BASE_URL}${client.dp}`}`}/>
                                                </Table.Cell>
                                                <Table.Cell style={{width: '200px'}}>{client.name}</Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                    </div>

                    <div className="col-12 col-md-7 p-1 h-100">
                        <div className="window_content">
                            <Table celled striped compact={false} size='small' inverted color='grey' selectable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '150px'}}>Exam Date</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '120px'}}>Exam Category</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '400px'}}>Findings</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        examinations.map((examination, index) =>
                                            <Table.Row key={examination.examination_id}>
                                                <Table.Cell style={{width: '50px'}} textAlign="center">{index + 1}</Table.Cell>

                                                <Table.Cell style={{width: '150px'}}>
                                                    {Utils.localise_date_time(examination.time_examined)}
                                                </Table.Cell>

                                                <Table.Cell style={{width: '120px'}}>
                                                    {
                                                        diagnosis.filter((aDiagnosis) =>
                                                            aDiagnosis.diagnosis_id === examination.diagnosis_id)[0].diagnosis_name
                                                    }
                                                </Table.Cell>
                                                <Table.Cell style={{width: '400px'}}>{examination.remarks}</Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
