import {Button, Form, Modal, Table, TextArea} from "semantic-ui-react";
import React from "react";
import {ToastsStore} from "react-toasts";
import {Supplier} from "../../../../../utils/utils/Models";
import {ApiRequest} from "../../../../../utils/ApiRequest";
import {Loading} from "../../../../../utils/components/Loading";
import {MyInput} from "../../../../../utils/components/MyInput";

const initialSupplier: Supplier = {
    address: "", contact: "", email: "", supplier_id: 0, supplier_name: ""
}

export default function StockSuppliers(params: {
    show: boolean, close: () => void, suppliers: Supplier[], setSuppliers: (suppliers: Supplier[]) => void
}) {
    const [loading, setLoading] = React.useState({show: false, message: ""})
    const [search, setSearch] = React.useState({name: ''})
    const [supplier, setSupplier] = React.useState<Supplier>(initialSupplier)
    const handle_change = (name: string, value: string) => {
        setSupplier({...supplier, [name]: value})
    }

    const save_data = () => {
        if (supplier.supplier_name.trim().length < 2) {
            ToastsStore.error('Enter a valid supplier name')
        } else {
            setLoading({show: true, message: 'Saving stock supplier, please wait'})
            ApiRequest.save_stock_suppliers({
                supplier_id: supplier.supplier_id, contact: supplier.contact, email: supplier.email,
                address: supplier.address, supplier_name: supplier.supplier_name.trim()
            })
                .then((response) => {
                    setLoading({message: "", show: false})
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        if (supplier.supplier_id === 0) {
                            params.setSuppliers([...params.suppliers, {...supplier, supplier_id: response.data['supplier_id']}])
                        } else {
                            params.setSuppliers(params.suppliers.map((aSupplier) => aSupplier.supplier_id === supplier.supplier_id ? supplier : aSupplier))
                        }
                        setSupplier(initialSupplier)
                        ToastsStore.success("Stock type saved successfully")
                    } else {
                        ToastsStore.error("Error while saving stock supplier, please retry")
                    }
                })
                .catch(() => {
                    setLoading({message: "", show: false})
                    ToastsStore.error("Error while saving stock supplier, please retry")
                })
        }
    }

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            <Modal open={params.show} basic size='large' centered={false} closeOnDimmerClick={false} closeOnDocumentClick={false}>
                <div className="modal_container" style={{height: '600px'}}>
                    <div className="row m-0 h-100">
                        <div className="col-8 pl-0 pr-2 h-100">
                            <div className={'d-flex flex-column h-100'}>
                                <div className={'d-flex mb-2'}>
                                    <div className="content_bar">
                                        <div className="search_bar">
                                            <div style={{width: '250px'}}>
                                                <MyInput
                                                    placeholder="Filter by supplier name" name="name" value={search.name}
                                                    change={(name, value) => setSearch({...search, [name]: value})}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex' style={{flex: 1}}>
                                    <div className="window_content">
                                        <Table celled striped compact={false} size='small' inverted color='grey' selectable>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                                    <Table.HeaderCell style={{width: '250px'}}>Supplier Name</Table.HeaderCell>
                                                    <Table.HeaderCell style={{width: '100px'}}>Contact</Table.HeaderCell>
                                                    <Table.HeaderCell style={{width: '100px'}}>Email</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                {
                                                    params.suppliers
                                                        .filter((aSupplier) => aSupplier.supplier_name.toLowerCase().includes(search.name.toLowerCase()))
                                                        .map((aSupplier, index) =>
                                                            <Table.Row key={aSupplier.supplier_id} onClick={() => setSupplier(aSupplier)}>
                                                                <Table.Cell style={{width: '50px'}} textAlign="center">{index + 1}</Table.Cell>
                                                                <Table.Cell style={{width: '250px'}}>{aSupplier.supplier_name}</Table.Cell>
                                                                <Table.Cell style={{width: '100px'}}>{aSupplier.contact}</Table.Cell>
                                                                <Table.Cell style={{width: '100px'}}>{aSupplier.email}</Table.Cell>
                                                            </Table.Row>
                                                        )
                                                }
                                            </Table.Body>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-4 px-0 h-100">
                            <div className="window_content">
                                <div className="form_container">
                                    <span className="label">Supplier Name</span>
                                    <MyInput placeholder="Enter supplier name" name="supplier_name" value={supplier.supplier_name} change={handle_change}/>

                                    <span className="label">Supplier Contacts</span>
                                    <MyInput placeholder="Enter supplier contacts" name="contact" value={supplier.contact} change={handle_change}/>

                                    <span className="label">Supplier Email</span>
                                    <MyInput placeholder="Enter supplier email" name="email" value={supplier.email} change={handle_change}/>

                                    <Form className="mt-3">
                                        <span className="label">Supplier Address</span>
                                        <TextArea
                                            placeholder='Enter supplier address' value={supplier.address} rows={3}
                                            onChange={(event, data) => handle_change('address', data.value as string)}/>
                                    </Form>
                                </div>

                                <div className="button_container">
                                    <div className="row m-0">
                                        <div className="col-2 pl-0 pr-1">
                                            <Button negative icon="close" size="mini" fluid onClick={params.close}/>
                                        </div>

                                        <div className="col-5 px-0">
                                            <Button negative icon="trash" labelPosition="left" size="mini" fluid
                                                    content="Clear Data" onClick={() => setSupplier(initialSupplier)}/>
                                        </div>
                                        <div className="col-5 pl-1 pr-0">
                                            <Button positive icon="save" labelPosition="left" size="mini" fluid content="Save Data" onClick={save_data}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
