import React from "react";
import Utils from "../../../utils/utils/Utils";
import {Button, Table} from "semantic-ui-react";
import {ApiRequest} from "../../../utils/ApiRequest";
import {ToastsStore} from "react-toasts";
import CancelOrder from "./utils/CancelOrder";
import AuthorizeOrder from "./utils/AuthorizeOrder";
import PayOrder from "./utils/PayOrder";
import JSPrinter, {Receipt} from "../../../utils/JSPrinter";
import {MyDateInput} from "../../../utils/components/MyDateInput";
import {Loading} from "../../../utils/components/Loading";
import {MySelect} from "../../../utils/components/MySelect";
import {MyInput} from "../../../utils/components/MyInput";
import {MatchedUser} from "../../../nav/SideBar";
import ClientDisplay from "../../clients/ClientDisplay";
import {ClientInfo} from "../../../utils/utils/Models";
import avatar from "../../../assets/images/avatar.png";

interface Order {
    bill_id: number
    bill_amount: number
    bill_status: "Pending" | "Authorized" | "Cancelled"
    client_id: number
    client_name: string
    created_by: string
    date_created: string
    discount: number
    group_name: string
    mobile_contact: number
    total_paid: number
}

export default function BillOrders(params: { matchedUser: MatchedUser, type: 'Authorized' | 'All' }) {
    const [loading, setLoading] = React.useState({show: false, message: ""})

    const [orders, setOrders] = React.useState(Array<Order>())
    const [clients, setClients] = React.useState(Array<ClientInfo>())
    const [clientInfo, setClientInfo] = React.useState<ClientInfo | undefined>(undefined)
    const [search, setSearch] = React.useState({min_date: Utils.today(), max_date: Utils.today(), status: "Pending", name: ""})
    const handle_search = (name: string, value: string) => {
        setSearch({...search, [name]: value})
    }

    const search_orders = (client_id?: number) => {
        setLoading({show: true, message: "Searching orders, please wait"})
        ApiRequest.search_billing_orders({
            max_date: search.max_date, min_date: search.min_date,
            name: search.name, status: search.status, client_id: client_id
        })
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setOrders(response.data.orders)
                    setClients(response.data.clients)
                    const count = (response.data.orders as any[]).length
                    ToastsStore.info(count === 0 ? "No order found in selection" : `${count} orders found in selection`)
                } else {
                    ToastsStore.error("Error while searching orders, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while searching orders, please retry")
            })
    }

    const print_bill = (bill_id: number) => {
        setLoading({show: true, message: "Generating billing order, please wait"})
        ApiRequest.get_billing_receipt({bill_id: bill_id})
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    const receipt: Receipt = response.data.receipt
                    JSPrinter.print_receipt([receipt], "BILL ORDER")
                } else {
                    ToastsStore.error("Error while generating billing order, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while generating billing order, please retry")
            })
    }

    const [showCancel, setShowCancel] = React.useState({bill_id: 0})
    const [showAuthorize, setShowAuthorize] = React.useState({bill_id: 0})
    const [showPay, setShowPay] = React.useState({bill_id: 0, balance: 0})

    React.useEffect(() => {
        if (params.matchedUser.client_id !== 0) {
            search_orders(params.matchedUser.client_id)
        }
    }, [params.matchedUser.time])

    React.useEffect(() => {
        setSearch({...search, status: params.type})
    }, [params.type])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            <CancelOrder
                bill_id={showCancel.bill_id}
                close={(cancelled) => {
                    if (cancelled) {
                        setOrders(orders.map((order) => order.bill_id === showCancel.bill_id ? {
                            ...order,
                            bill_status: "Cancelled"
                        } : order))
                    }
                    setShowCancel({bill_id: 0})
                }}/>

            <AuthorizeOrder
                bill_id={showAuthorize.bill_id}
                close={(authorized) => {
                    if (authorized) {
                        setOrders(orders.map((order) => order.bill_id === showAuthorize.bill_id ? {
                            ...order,
                            bill_status: "Authorized"
                        } : order))
                    }
                    setShowAuthorize({bill_id: 0})
                }}/>

            <PayOrder
                bill_id={showPay.bill_id} balance={showPay.balance} close={() => setShowPay({...showPay, bill_id: 0})}
                update={(total_paid, bill_status) => {
                    setOrders(orders.map((order) => order.bill_id === showPay.bill_id ? {...order, total_paid, bill_status} : order))
                    setShowPay({...showPay, bill_id: 0})
                }}/>

            <ClientDisplay close={() => setClientInfo(undefined)} client={clientInfo}/>

            <div className="content_bar">
                <div className="search_bar">
                    {
                        params.type === 'All' &&

                        <div>
                            <MySelect
                                name="status" value={search.status} placeholder="Bill Status"
                                change={(value) => handle_search('status', value as string)}
                                options={[
                                    {text: 'All Orders', value: 'All'},
                                    {text: 'Pending Orders', value: 'Pending'},
                                    {text: 'Cancelled Orders', value: 'Cancelled'},
                                    {text: 'Authorized Orders', value: 'Authorized'}]
                                }/>
                        </div>
                    }

                    <div>
                        <MyDateInput
                            value={search.min_date} name="min_date" placeholder="Min Date"
                            change={handle_search} maxDate={search.max_date}/>
                    </div>

                    <div>
                        <MyDateInput
                            value={search.max_date} name="max_date" placeholder="Min Date"
                            change={handle_search} minDate={search.min_date} maxDate={Utils.today()}/>
                    </div>

                    <div style={{width: '250px'}}>
                        <MyInput
                            placeholder="Search by name, contact, email or bill no"
                            name="name" value={search.name} change={handle_search} enter={() => search_orders()}/>
                    </div>
                </div>
                <div className="content_buttons">
                    <Button primary size='mini' icon='search' labelPosition="left" content="Search Orders"
                            onClick={() => search_orders()}/>
                </div>
            </div>

            <div className="content_container">
                <div className="window_content">
                    <Table celled striped compact={false} size='small' inverted color='grey' selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '150px'}} textAlign="center"/>
                                <Table.HeaderCell style={{width: '40px'}} textAlign="center">No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '100px'}} textAlign="center">Bill No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '80px'}}></Table.HeaderCell>
                                <Table.HeaderCell style={{width: '180px'}}>Client Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '120px'}}>Client Contact</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '140px'}}>Ordered By</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '185px'}}>Time Created</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '140px'}}>Bill Category</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '90px'}}>Bill Status</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '90px'}}>Amount</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '90px'}}>Discount</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '90px'}}>Total Paid</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '90px'}}>Balance</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                orders.map((order) => {
                                        const client: ClientInfo | undefined = clients.filter((aClient) => aClient.id === order.client_id)[0]
                                        const balance = order.bill_amount - order.discount - order.total_paid
                                        return (
                                            <Table.Row key={order.bill_id}>
                                                <Table.Cell style={{width: '150px'}} textAlign="center">
                                                    <Button
                                                        primary size='mini' icon='check' compact
                                                        disabled={order.bill_status === 'Authorized'}
                                                        onClick={() => setShowAuthorize({bill_id: order.bill_id})}/>

                                                    <Button
                                                        primary size='mini' icon='cancel' compact disabled={order.bill_status !== 'Pending'}
                                                        onClick={() => setShowCancel({bill_id: order.bill_id})}/>

                                                    <Button
                                                        primary size='mini' icon='money' compact
                                                        disabled={balance === 0 || order.bill_status === 'Cancelled'}
                                                        onClick={() => setShowPay({bill_id: order.bill_id, balance: balance})}/>

                                                    <Button
                                                        primary size='mini' icon='print' compact
                                                        onClick={() => print_bill(order.bill_id)}/>
                                                </Table.Cell>
                                                <Table.Cell style={{width: '40px'}} textAlign="center">No.</Table.Cell>
                                                <Table.Cell style={{width: '100px'}} textAlign="center">{order.bill_id}</Table.Cell>
                                                <Table.Cell style={{width: '80px'}}>
                                                    <img
                                                        onClick={() => setClientInfo(client)} className={"table_avatar"} alt="Passport"
                                                        src={`${(client === undefined || client.dp === '') ? avatar : `${Utils.BASE_URL}${client.dp}`}`}/>
                                                </Table.Cell>
                                                <Table.Cell style={{width: '180px'}}>{order.client_name}</Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>{order.mobile_contact}</Table.Cell>
                                                <Table.Cell style={{width: '140px'}}>{order.created_by}</Table.Cell>
                                                <Table.Cell
                                                    style={{width: '185px'}}>{Utils.localise_date_time(order.date_created)}</Table.Cell>
                                                <Table.Cell style={{width: '140px'}}>{order.group_name}</Table.Cell>
                                                <Table.Cell style={{width: '90px'}}>{order.bill_status}</Table.Cell>
                                                <Table.Cell style={{width: '90px'}}>{Utils.comma_number(order.bill_amount, '')}</Table.Cell>
                                                <Table.Cell style={{width: '90px'}}>{Utils.comma_number(order.discount, '')}</Table.Cell>
                                                <Table.Cell style={{width: '90px'}}>{Utils.comma_number(order.total_paid, '')}</Table.Cell>
                                                <Table.Cell style={{width: '90px'}}>
                                                    {Utils.comma_number(order.bill_amount - order.discount - order.total_paid, '')}
                                                </Table.Cell>
                                            </Table.Row>
                                        )

                                    }
                                )
                            }
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </>
    )
}
