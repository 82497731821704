import React from "react";
import Utils from "../../utils/utils/Utils";
import {Button, Table} from "semantic-ui-react";
import {ApiRequest} from "../../utils/ApiRequest";
import {ToastsStore} from "react-toasts"
import ClientData, {Client, initial_client} from "./ClientData";
import {Loading} from "../../utils/components/Loading";
import {MyInput} from "../../utils/components/MyInput";
import {MatchedUser} from "../../nav/SideBar";
import {ClientInfo} from "../../utils/utils/Models";
import ClientDisplay from "./ClientDisplay";
import avatar from "../../assets/images/avatar.png";
import moment from "moment";
import CreateBillOrder from "../billing/orders/utils/CreateBillOrder";

export interface BillGroup {
    group_id: number
    group_name: string
}

export default function ClientList(params: { groups: BillGroup[], matchedUser: MatchedUser, webSocket: WebSocket | undefined }) {
    const [loading, setLoading] = React.useState({show: false, message: ""})

    const [search, setSearch] = React.useState({search_name: ""})
    const handle_search = (name: string, value: string | number) => {
        setSearch({...search, [name]: value})
    }

    const [clients, setClients] = React.useState<Array<Client>>([])
    const [client, setClient] = React.useState(initial_client)
    const [showClient, setShowClient] = React.useState(false)
    const [showAdd, setShowAdd] = React.useState({show: false, client_id: 0, client_name: ""})
    const [clientInfo, setClientInfo] = React.useState<ClientInfo | undefined>(undefined)

    const search_accounts = (client_id?: number) => {
        if (client_id === undefined && search.search_name.trim().length < 3) {
            ToastsStore.info("At least 3 characters must be inserted")
        } else {
            setLoading({message: "Searching client data, please wait", show: true})

            ApiRequest.search_clients({search: search.search_name.trim(), client_id: client_id})
                .then((response) => {
                    setLoading({message: '', show: false})
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        setClients(
                            (response.data.clients as any[]).map((client) => {
                                if (client.mobile_contact !== "") {
                                    client.mobile_contact = `+${client.mobile_contact}`
                                }
                                if (client.next_of_kin_contact !== "") {
                                    client.next_of_kin_contact = `+${client.next_of_kin_contact}`
                                }
                                return client as Client
                            })
                        )
                        const matches = response.data.clients.length
                        if (matches === 0) {
                            ToastsStore.info("Search marched no data")
                        } else {
                            ToastsStore.info(`${matches} account matches found`)
                        }
                    } else {
                        ToastsStore.error("Could not load clients' data")
                    }
                })
                .catch(() => {
                    setLoading({message: '', show: false})
                    ToastsStore.error("Could not load clients' data")
                })
        }
    }

    React.useEffect(() => {
        if (params.matchedUser.client_id !== 0) {
            search_accounts(params.matchedUser.client_id)
        }
    }, [params.matchedUser.time])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            <ClientDisplay close={() => setClientInfo(undefined)} client={clientInfo}/>

            <ClientData
                client={client} close={() => setShowClient(false)} show={showClient} groups={params.groups}
                update={(_client, new_data) => {
                    if (new_data) {
                        setClients([_client, ...clients])
                    } else {
                        setClients(clients.map((__client) => __client.client_id === _client.client_id ? _client : __client))
                    }
                    setClient(_client)
                    setShowClient(false)
                }}/>

            <CreateBillOrder
                show={showAdd.show} client_id={showAdd.client_id} client_name={showAdd.client_name} groups={params.groups}
                close={() => setShowAdd({...showAdd, show: false})}/>

            <div className="content_bar">
                <div className="search_bar">
                    <div style={{width: '250px'}}>
                        <MyInput
                            placeholder="Client name or email address" name='search_name' value={search.search_name}
                            change={handle_search} enter={() => search_accounts(undefined)}/>
                    </div>
                </div>

                <div className="content_buttons">
                    <Button
                        primary size='mini' icon='add' labelPosition="left" content="Add Client"
                        onClick={() => {
                            setClient(initial_client)
                            setShowClient(true)
                        }}/>

                    <Button primary size='mini' icon='search' labelPosition="left" content="Search Clients"
                            onClick={() => search_accounts(undefined)}/>
                </div>
            </div>

            <div className="content_container">
                <div className="window_content">
                    <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '100px'}} colSpan={2} textAlign="center"/>
                                <Table.HeaderCell style={{width: '120px'}}/>
                                <Table.HeaderCell style={{width: '180px'}}>Client Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '130px'}}>Client Number</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '130px'}}>Contact No</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '140px'}}>Date of Birth</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '60px'}}>Gender</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '180px'}}>Address</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                clients.map((client, index) =>
                                    <Table.Row key={client.client_id}>
                                        <Table.Cell style={{width: '40px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                        <Table.Cell style={{width: '60px'}} textAlign="center">
                                            <img
                                                onClick={() => setClientInfo({
                                                    age: moment().diff(client.date_of_birth, 'years'),
                                                    company: client.group_id, dob: client.date_of_birth,
                                                    dp: client.passport_photo, gender: client.client_gender,
                                                    id: client.client_id, id_: client.passport_no,
                                                    name: `${client.last_name} ${client.first_name} ${client.other_name}`,
                                                    no: client.client_no, phone: client.mobile_contact
                                                })}
                                                src={`${client.passport_photo === '' ? avatar : `${Utils.BASE_URL}${client.passport_photo}`}`}
                                                className={"table_avatar"} alt="Passport"/>
                                        </Table.Cell>
                                        <Table.Cell style={{width: '120px'}} textAlign='center'>
                                            <Button
                                                primary size='mini' icon='edit' compact
                                                onClick={() => {
                                                    setClient(client)
                                                    setShowClient(true)
                                                }}/>

                                            <Button
                                                primary size='mini' icon='add' compact
                                                onClick={() => {
                                                    setShowAdd({
                                                        show: true, client_id: client.client_id,
                                                        client_name: `${client.last_name} ${client.first_name}`
                                                    })
                                                }}/>

                                            <Button
                                                primary size='mini' icon='paw' compact
                                                onClick={() => {
                                                    if (params.webSocket === undefined) {
                                                        ToastsStore.error("Server is not connected")
                                                    } else {
                                                        params.webSocket.send(
                                                            JSON.stringify({
                                                                type: "new_enrollment", userId: client.client_id,
                                                                username: `${client.last_name} ${client.first_name}`
                                                            })
                                                        )
                                                    }
                                                }}/>
                                        </Table.Cell>
                                        <Table.Cell style={{width: '180px'}}>{`${client.last_name} ${client.first_name}`}</Table.Cell>
                                        <Table.Cell style={{width: '130px'}}>{client.client_no}</Table.Cell>
                                        <Table.Cell style={{width: '130px'}}>{client.mobile_contact}</Table.Cell>
                                        <Table.Cell style={{width: '140px'}}>{Utils.localise_date(client.date_of_birth)}</Table.Cell>
                                        <Table.Cell style={{width: '60px'}}>{client.client_gender}</Table.Cell>
                                        <Table.Cell style={{width: '180px'}}>{client.district} {client.village}</Table.Cell>
                                    </Table.Row>
                                )
                            }
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </>
    )
}
