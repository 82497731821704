import {Button, Modal} from "semantic-ui-react";
import {ApiRequest} from "../ApiRequest";
import React from "react";
import Utils from "../utils/Utils";

export function DisplayPDF(params: { show: boolean, close: () => void, url: string }) {
    return (
        <Modal size='large' open={params.show} centered={false} closeOnDimmerClick={false} onClose={params.close}>
            <div className="pdf_modal">
                {params.url !== "" &&
                    <iframe src={`${Utils.BASE_URL}${params.url}`} style={{width: '100%', height: '100%'}} frameBorder="0"/>}
            </div>
            <div className="row mx-0">
                <div className="col-12 col-md-4 offset-md-4 p-2">
                    <Button primary size='mini' icon='close' labelPosition="left" content="Close" fluid onClick={params.close}/>
                </div>
            </div>
        </Modal>
    )
}
