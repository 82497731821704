import React from "react";

export function MyDateInput(
    props: {
        minDate?: string, maxDate?: string, value: string, name: string, placeholder: string,
        change: (name: string, value: string) => void, disabled?: boolean
    }
) {
    return (
        <input
            className="date_input" name={props.name} placeholder={props.placeholder} value={props.value}
            type="date" max={props.maxDate} min={props.minDate} disabled={props.disabled ? props.disabled : false}
            onChange={(event) => props.change(event.target.name, event.target.value)}/>
    )
}
