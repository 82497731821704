import {Button, Modal, Table} from "semantic-ui-react";
import React from "react";
import {Stock, StockSupply, StockType, StockUnit, Supplier, SupplyItem} from "../../../../../utils/utils/Models";
import {ToastsStore} from "react-toasts";
import {Loading} from "../../../../../utils/components/Loading";
import Utils from "../../../../../utils/utils/Utils";
import {ApiRequest} from "../../../../../utils/ApiRequest";
import {MySelect} from "../../../../../utils/components/MySelect";
import {MyDateInput} from "../../../../../utils/components/MyDateInput";
import {MyInput} from "../../../../../utils/components/MyInput";
import ItemRestock from "./ItemRestock";
import EditStock, {initialStock} from "../utilities/EditStock";

const initialSupplyItem: SupplyItem = {
    min_sale_price: "", profit_margin: "", purchase_price: "", purchase_quantity: "", sale_price: "", stock_id: 0, stock_name: ""
}

export default function SupplierRestock(params: {
    show: boolean, supply: StockSupply, suppliers: Supplier[], units: StockUnit[], stockTypes: StockType[],
    updateStockUnits: (stockUnits: StockUnit[]) => void,
    updateStockTypes: (stockTypes: StockType[]) => void,
    updateStockSuppliers: (suppliers: Supplier[]) => void
    close: () => void, update: (supply: StockSupply) => void,
}) {
    const [addStock, setAddStock] = React.useState({show: false, stock: initialStock})
    const [loading, setLoading] = React.useState({show: false, message: ""})
    const [supplyItem, setSupplyItem] = React.useState(initialSupplyItem)
    const [restock, setRestock] = React.useState(params.supply)

    const handle_change = (name: string, value: string) => {
        setRestock({...restock, [name]: value})
    }

    const save_restock = () => {
        if (restock.supplier_id === 0) {
            ToastsStore.error("Select a supplier name")
        } else if (restock.supply_date === "") {
            ToastsStore.error("Enter a valid date of purchase")
        } else if (!Utils.is_valid_number(restock.supply_cost.toString().trim()) || parseFloat(restock.supply_cost.toString().trim()) <= 0) {
            ToastsStore.error("Enter a valid cost incurred during supply")
        } else if (restock.supply_items.length === 0) {
            ToastsStore.error("No items have been selected")
        } else if (!Utils.is_valid_number(restock.supply_balance.toString())) {
            ToastsStore.error("Enter valid credit of supply")
        } else if (restock.payment_type === 'Credit' && parseFloat(restock.supply_balance.toString()) <= 0) {
            ToastsStore.error("Supply credit cannot be less than 0")
        } else if (restock.profit_margin < 0) {
            ToastsStore.error("You are operating in losses, please adjust your stock details")
        } else {
            setLoading({show: true, message: 'Saving restock, please wait'})
            ApiRequest.save_supplier_restock({
                supply_id: restock.supply_id,
                supplier_id: restock.supplier_id,
                bill_no: restock.bill_no,
                bill_date: restock.bill_date,
                supply_items: JSON.stringify(restock.supply_items),
                supply_cost: parseFloat(restock.supply_cost.toString().trim()),
                supply_date: restock.supply_date,
                profit_margin: restock.profit_margin,
                payment_type: restock.payment_type,
                supply_balance: parseFloat(restock.supply_cost.toString().trim())
            })
                .then((response) => {
                    setLoading({message: "", show: false})
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            const aSupply = {...restock, supply_id: response.data['supply_id']}
                            params.update(aSupply)
                            setRestock(aSupply)
                            ToastsStore.success("Stock supply saved successfully")
                        } else if (response.data.code === 2) {
                            ToastsStore.error("Available quantity is below zero")
                        }
                    } else {
                        ToastsStore.error("Error while restocking, please retry")
                    }
                })
                .catch(() => {
                    setLoading({message: "", show: false})
                    ToastsStore.error("Error while restocking, please retry")
                })
        }
    }

    const [stocks, setStocks] = React.useState(Array<Stock>())
    const get_supplier_items = (supplier_id: number) => {
        if (supplier_id === 0) {
            setRestock({...restock, supplier_id: 0, supply_items: []})
            return
        }
        setLoading({show: true, message: 'Loading supplier items, please wait, please wait'})
        ApiRequest.get_stock({name: '', output: 'list', quantity: 'all'})
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setStocks(response.data['stocks'])
                    setRestock({...restock, supplier_id: supplier_id})
                } else {
                    ToastsStore.error("Error while loading supplier stocks, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while loading supplier stocks, please retry")
            })
    }

    React.useEffect(() => {
        if (Utils.is_valid_number(restock.supply_cost.toString().trim()) && parseFloat(restock.supply_cost.toString().trim()) >= 0) {
            let totalCost = parseFloat(restock.supply_cost.toString().trim())
            let totalSalePrice = 0
            restock.supply_items.forEach((item) => {
                totalCost += parseFloat(item.purchase_price.toString())
                totalSalePrice += (parseFloat(item.purchase_quantity.toString()) * parseFloat(item.sale_price.toString()))
            })
            setRestock({...restock, profit_margin: totalSalePrice - totalCost})
        } else {
            setRestock({...restock, profit_margin: -1})
        }
    }, [restock.supply_items, restock.supply_cost])

    React.useEffect(() => {
        setRestock(params.supply)
    }, [params.show, params.supply])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            <EditStock
                show={addStock.show} stock={addStock.stock} suppliers={params.suppliers} stockTypes={params.stockTypes} stockUnits={params.units}
                updateStockUnits={params.updateStockUnits} updateStockTypes={params.updateStockTypes} updateStockSuppliers={params.updateStockSuppliers}
                close={() => setAddStock({stock: initialStock, show: false})}
                updateStock={(aStock, isNew) => {
                    if (isNew) {
                        setStocks([...stocks, aStock])
                    } else {
                        setStocks(stocks.map((bStock) => bStock.stock_id === aStock.stock_id ? aStock : bStock))
                    }
                }}/>

            <Modal open={params.show} basic size='large' centered={false} closeOnDimmerClick={false} closeOnDocumentClick={false}>
                <div className="modal_container full_height">
                    <div className="row m-0 h-100">
                        <div className="col-8 pl-0 pr-2 h-100">
                            <div className='d-flex flex-column h-100'>
                                <div className={'d-flex'}>
                                    <div className="form_container w-100" style={{height: 'auto'}}>
                                        <div className='row mx-0 mb-2'>
                                            <div className='col-8 pl-0 pr-2'>
                                                {/*STOCK SUPPLIER*/}
                                                <span className="label mt-0">Item Supplier</span>
                                                <MySelect
                                                    name="supplier_id" value={restock.supplier_id} placeholder="Select a supplier"
                                                    change={(value) => get_supplier_items(value as number)}
                                                    options={[
                                                        {text: "Select a supplier", value: 0},
                                                        ...params.suppliers.map((_) => ({text: _.supplier_name, value: _.supplier_id}))
                                                    ]}/>
                                            </div>

                                            <div className='col-4 px-0'>
                                                <span className="label mt-0">Purchase Type</span>
                                                <MySelect
                                                    name="payment_type" value={restock.payment_type} placeholder="Select purchase type"
                                                    change={(value) => {
                                                        const payment_type = value as 'Cash' | 'Credit'
                                                        setRestock({
                                                            ...restock, payment_type: payment_type,
                                                            supply_balance: payment_type === 'Cash' ? 0 : restock.payment_type,
                                                            bill_date: payment_type === 'Cash' ? '' : restock.bill_date
                                                        })
                                                    }}
                                                    options={[{text: "Full payment", value: 'Cash'}, {text: "Credit or partial payment", value: 'Credit'}]}/>
                                            </div>
                                        </div>

                                        <div className='row mx-0 mb-2'>
                                            <div className='col-4 px-0'>
                                                <span className="label">Date of Purchase</span>
                                                <MyDateInput
                                                    value={restock.supply_date} name="supply_date" maxDate={Utils.today()} minDate="1900-01-01"
                                                    placeholder="Select date of stocking" change={handle_change}/>
                                            </div>

                                            <div className='col-4 px-2'>
                                                <span className="label">Costs of Purchase</span>
                                                <MyInput placeholder="Enter cost incured in puchases" name="supply_cost"
                                                         value={restock.supply_cost.toString()} change={handle_change}/>
                                            </div>

                                            <div className='col-4 px-0'>
                                                <span className="label">Profit Margin</span>
                                                <span className="label_text">{restock.profit_margin.toLocaleString()}</span>
                                            </div>
                                        </div>

                                        <div className='row mx-0'>
                                            <div className='col-4 px-0'>
                                                <span className="label">{restock.payment_type === 'Cash' ? 'Receipt' : 'Invoice'} Number</span>
                                                <MyInput placeholder={`Enter ${restock.payment_type === 'Cash' ? 'receipt' : 'invoice'} number`}
                                                         name="bill_no" value={restock.bill_no} change={handle_change}/>
                                            </div>

                                            <div className='col-4 px-2'>
                                                <span className="label">Bill Balance</span>
                                                <MyInput placeholder="Enter balance" name="supply_balance" disabled={restock.payment_type === 'Cash'}
                                                         value={restock.supply_balance.toString()} change={handle_change}/>
                                            </div>

                                            <div className='col-4 px-0'>
                                                <span className="label">Payment Date</span>
                                                <MyDateInput
                                                    value={restock.bill_date} name="bill_date" disabled={restock.payment_type === 'Cash'}
                                                    minDate={restock.supply_date} placeholder="Select date of payment" change={handle_change}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={'d-flex my-2'} style={{flex: 1}}>
                                    <div className="window_content">
                                        <Table celled striped compact={false} size='small' inverted color='grey' selectable>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell style={{width: '30px'}} textAlign="center">No.</Table.HeaderCell>
                                                    <Table.HeaderCell style={{width: '150px'}}>Item Name</Table.HeaderCell>
                                                    <Table.HeaderCell style={{width: '60px'}}>Quantity</Table.HeaderCell>
                                                    <Table.HeaderCell style={{width: '100px'}}>Price</Table.HeaderCell>
                                                    <Table.HeaderCell style={{width: '100px'}}>Profit Margin</Table.HeaderCell>
                                                    <Table.HeaderCell style={{width: '100px'}}>Min Price</Table.HeaderCell>
                                                    <Table.HeaderCell style={{width: '100px'}}>Sale Price</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                {
                                                    restock.supply_items.map((supplyItem, index) =>
                                                        <Table.Row key={supplyItem.stock_id} onClick={() => setSupplyItem(supplyItem)}>
                                                            <Table.Cell style={{width: '30px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                                            <Table.Cell style={{width: '150px'}}>{supplyItem.stock_name}</Table.Cell>
                                                            <Table.Cell style={{width: '60px'}}>
                                                                {parseFloat(supplyItem.purchase_quantity.toString()).toLocaleString()}
                                                            </Table.Cell>
                                                            <Table.Cell style={{width: '100px'}}>
                                                                {parseFloat(supplyItem.purchase_price.toString()).toLocaleString()}
                                                            </Table.Cell>
                                                            <Table.Cell style={{width: '80px'}}>
                                                                {parseFloat(supplyItem.profit_margin.toString()).toLocaleString()}
                                                            </Table.Cell>
                                                            <Table.Cell style={{width: '100px'}}>
                                                                {parseFloat(supplyItem.min_sale_price.toString()).toLocaleString()}
                                                            </Table.Cell>
                                                            <Table.Cell style={{width: '100px'}}>
                                                                {parseFloat(supplyItem.sale_price.toString()).toLocaleString()}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    )
                                                }
                                            </Table.Body>
                                        </Table>
                                    </div>
                                </div>

                                <div className={'d-flex'}>
                                    <div className="button_container w-100">
                                        <div className="row m-0">
                                            <div className="col-4 offset-2 pl-0 pr-1">
                                                <Button negative icon="close" labelPosition="left" size="mini"
                                                        fluid content="Close Window" onClick={() => params.close()}/>
                                            </div>
                                            <div className="col-4 pl-1 pr-0">
                                                <Button positive icon="save" labelPosition="left" size="mini" disabled={restock.supply_id > 0}
                                                        fluid content="Save Data" onClick={save_restock}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-4 px-0 h-100">
                            <div className='d-flex flex-column h-100'>

                                <div className={'d-flex flex-row'}>
                                    <div style={{flex: 1, marginRight: '10px'}}>
                                        <MySelect
                                            name="stock_id" value={0} placeholder="Select an item"
                                            change={(value, text) =>
                                                setSupplyItem({
                                                    min_sale_price: '', profit_margin: '', purchase_price: '', purchase_quantity: '',
                                                    sale_price: '', stock_id: value as number, stock_name: text
                                                })}
                                            options={[
                                                {text: "Select a stock item", value: 0},
                                                ...stocks
                                                    .filter((stock) => !restock.supply_items.map((aItem) => (aItem.stock_id)).includes(stock.stock_id))
                                                    .map((stock) => ({text: stock.stock_name, value: stock.stock_id}))
                                            ]}/>
                                    </div>

                                    <div>
                                        <Button primary size='mini' icon='add' compact disabled={restock.supplier_id === 0}
                                                onClick={() => setAddStock({show: true, stock: initialStock})}/>
                                    </div>
                                </div>

                                <div className={'mt-2'} style={{flex: 1}}>
                                    <ItemRestock
                                        supplyItem={supplyItem} stockItems={stocks}
                                        updateItem={(supplyItem) => {
                                            if (supplyItem !== undefined) {
                                                if (restock.supply_items.filter((aItem) => aItem.stock_id === supplyItem.stock_id).length === 0) {
                                                    setRestock({...restock, supply_items: [...restock.supply_items, supplyItem]})
                                                } else {
                                                    setRestock({
                                                        ...restock,
                                                        supply_items: restock.supply_items.map((aItem) => aItem.stock_id === supplyItem.stock_id ? supplyItem : aItem)
                                                    })
                                                }
                                            }
                                            setSupplyItem(initialSupplyItem)
                                        }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
