import {Button, Modal, Table} from "semantic-ui-react";
import React from "react";
import Utils from "../../../utils/utils/Utils";
import {ToastsStore} from "react-toasts";
import {ApiRequest} from "../../../utils/ApiRequest";
import {MyDateInput} from "../../../utils/components/MyDateInput";
import {Loading} from "../../../utils/components/Loading";
import {MyInput} from "../../../utils/components/MyInput";

export interface BillingGroup {
    billing_group_id: number
    billing_group_name: string
    date_created: string
    expiry_date: string
}

const initial_group: BillingGroup = {billing_group_id: 0, billing_group_name: "", date_created: "", expiry_date: ""}

export default function BillingGroups(params: {
    show: boolean, groups: BillingGroup[], update: (groups: BillingGroup, is_new: boolean) => void, close: () => void
}) {
    const [loading, setLoading] = React.useState({show: false, message: ""})
    const [group, setGroup] = React.useState(initial_group)

    const save_group = () => {
        if (group.billing_group_name.trim().length < 2) {
            ToastsStore.error("Enter a valid billing group name")
        } else {
            setLoading({show: true, message: "Saving billing group, please wait"})

            ApiRequest.save_billing_group({
                billing_group_id: group.billing_group_id, billing_group_name: group.billing_group_name.trim(),
                expiry_date: group.expiry_date
            })
                .then((response) => {
                    setLoading({message: "", show: false})
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            const _group: BillingGroup = {...group, billing_group_id: response.data.billing_group_id,}
                            params.update(_group, group.billing_group_id === 0)
                            setGroup(_group)
                            ToastsStore.success("Billing group saved successfully")
                        } else if (response.data.code === 2) {
                            ToastsStore.error("Billing group name is already in use")
                        }
                    } else {
                        ToastsStore.error("Error while saving billing group, please retry")
                    }
                })
                .catch(() => {
                    setLoading({message: "", show: false})
                    ToastsStore.error("Error while saving billing group, please retry")
                })
        }
    }

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            <Modal open={params.show} basic size='small' centered={false} closeOnDimmerClick={false} closeOnDocumentClick={false}>
                <div className="modal_container full_height">
                    <div className="row m-0 h-100">
                        <div className="col-7 pl-0 pr-2 h-100">
                            <div className="window_content">
                                <Table celled striped compact={false} size='small' inverted color='grey' selectable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                            <Table.HeaderCell>Group Name</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '140px'}}>Expiry Date</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            params.groups
                                                .map((_group, index) =>
                                                    <Table.Row key={_group.billing_group_id} onClick={() => setGroup(_group)}>
                                                        <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                                        <Table.Cell>{_group.billing_group_name}</Table.Cell>
                                                        <Table.Cell style={{width: '140px'}}>
                                                            {_group.expiry_date === '' ? 'Open' : Utils.localise_date(_group.expiry_date)}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>

                        <div className="col-5 px-0">
                            <div className="form_container">
                                {/*Group Name*/}
                                <span className="label">Group Name</span>
                                <MyInput placeholder="Enter group name" name="billing_group_name" value={group.billing_group_name}
                                         change={(name, value) => setGroup({...group, [name]: value})}/>

                                {/*Expiry Date*/}
                                <span className="label">Group Expiry date</span>
                                <MyDateInput value={group.expiry_date} name="expiry_date" placeholder="Expiry date"
                                             change={(name, value) => setGroup({...group, [name]: value})}/>
                                <label className="small_hint">Leave empty in case the group has no expiry date</label>
                            </div>

                            <div className="button_container">
                                <div className="row m-0">
                                    <div className="col-2 pl-0 pr-1">
                                        <Button negative icon="close" size="mini" fluid onClick={params.close}/>
                                    </div>
                                    <div className="col-5 px-0">
                                        <Button negative icon="trash" labelPosition="left" size="mini" fluid
                                                content={'Clear Data'} onClick={() => setGroup(initial_group)}/>
                                    </div>
                                    <div className="col-5 pl-1 pr-0">
                                        <Button positive icon="save" labelPosition="left" size="mini" fluid
                                                content="Save Group" onClick={save_group}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
