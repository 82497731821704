import {Loader, Modal} from "semantic-ui-react";
import React from "react";

export function Loading(props: { show: boolean, text: string, hide: () => void }) {
    return (
        <Modal
            size='small' basic closeOnEscape={false} closeOnDimmerClick={false}
            open={props.show} onClose={props.hide}>
            <Loader active inline='centered'>{props.text}</Loader>
        </Modal>
    )
}
