import React from "react";
import Webcam from "react-webcam";
import {Button} from "semantic-ui-react";
import {MySelect} from "./MySelect";

export function WebCamView(params: { setImage: (image: string) => void, dimens: { width: number, height: number } }) {
    const webcamRef = React.useRef<Webcam | null>(null);

    const capture = React.useCallback(() => {
        const imageSrc = (webcamRef.current as Webcam).getScreenshot({width: 800, height: 600})
        if (imageSrc !== null) {
            params.setImage(imageSrc as string)
        }
    }, [webcamRef])

    const [deviceId, setDeviceId] = React.useState<number>(-1);
    const [devices, setDevices] = React.useState<Array<any>>([])

    const handleDevices = React.useCallback((mediaDevices: any) => {
        setDevices(mediaDevices.filter(({kind}: any) => kind === "videoinput"));
    }, [setDevices])

    React.useEffect(() => {
        setDeviceId(devices.length > 0 ? 0 : -1)
    }, [devices])

    React.useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then(handleDevices);
    }, [handleDevices])


    return (
        <div style={{padding: '10px'}}>
            {
                deviceId >= 0 &&
                <>
                    <div className={"row mx-0"}>
                        <div className="col-10 pl-0 pr-1">
                            <MySelect
                                name="camera" value={deviceId} placeholder="Select a device"
                                options={[
                                    ...devices.map((device: any, index) => {
                                        return ({text: device.label || `Device ${index + 1}`, value: index})
                                    })
                                ]}/>
                        </div>
                        <div className="col-2 pl-1 pr-0">
                            <Button primary size='mini' icon='camera' onClick={capture} fluid/>
                        </div>

                        <div className="col-12 mt-2 px-0">
                            <Webcam
                                audio={false} ref={webcamRef} screenshotFormat="image/jpeg"
                                height={params.dimens.height} width={params.dimens.width}
                                videoConstraints={{deviceId: devices[deviceId].deviceId}}/>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}
