import React from "react";
import {ToastsStore} from "react-toasts";
import Utils from "../../utils/utils/Utils"
import {ApiRequest} from "../../utils/ApiRequest";
import {Button, Table} from "semantic-ui-react";
import {InputFile} from "../../utils/utils/Models";
import {Loading} from "../../utils/components/Loading";
import {MyInput} from "../../utils/components/MyInput";
import {readInput} from "../../utils/utils/Common";

export interface Branch {
    branch_id: number
    branch_name: string
    branch_logo: InputFile
    contact_address: string
    email_address: string
    box_number: string
    location: string
    rad_prefix: string
    lab_prefix: string
    client_prefix: string
}

const initial_branch: Branch = {
    box_number: "", contact_address: "", email_address: "", branch_id: 0, branch_logo: new InputFile(""),
    branch_name: "", location: "", client_prefix: "", lab_prefix: "", rad_prefix: ""
}

export default function BranchManagement(params: { setBranches: (centers: Array<Branch>) => void, branches: Branch[] }) {
    const [loading, setLoading] = React.useState({show: false, message: ""})

    const [branch, setBranch] = React.useState(initial_branch)

    const handle_center = (name: string, value: string | InputFile) => {
        setBranch({...branch, [name]: value})
    }

    const save_center = () => {
        setLoading({message: "Saving branch, please wait", show: true})
        ApiRequest.save_branch({
            box_number: branch.box_number, contact_address: branch.contact_address, email_address: branch.email_address,
            branch_id: branch.branch_id, branch_logo: branch.branch_logo, branch_name: branch.branch_name,
            location: branch.location, client_prefix: branch.client_prefix.trim(), rad_prefix: branch.rad_prefix.trim(),
            lab_prefix: branch.lab_prefix.trim()
        })
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code")) {
                    switch (response.data.code as number) {
                        case 1:
                            const aBranch: Branch = {
                                ...branch, branch_id: response.data.branch_id, branch_logo: new InputFile(`${Utils.BASE_URL}${response.data.branch_logo}`)
                            };

                            let aBranches: Array<Branch> = []
                            aBranches = branch.branch_id === 0 ? [aBranch, ...params.branches] : params.branches.map((__) => __.branch_id === branch.branch_id ? aBranch : __);

                            setBranch(aBranch)
                            params.setBranches(aBranches)
                            ToastsStore.success("Saved branch successfully")
                            break
                        case 2:
                            ToastsStore.error("Branch name is already saved")
                            break
                    }
                } else {
                    ToastsStore.error("Could not save branch, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Could not save branch, please retry")
            })
    }

    const display_branch_data = () => {
        return (
            <div className="window_content">
                <div className="form_container">
                    {/*Branch Logo*/}
                    <span className="label">Branch name</span>
                    <img src={branch.branch_logo.file_src} alt='' className='center_logo'/>
                    <div className="input_container" style={{width: '100%', margin: '0 auto'}}>
                        <input type="file" className="input_file" id="center_logo_input" accept="image/*" multiple={false}
                               onChange={(event) => {
                                   readInput(event)
                                       .then((response) => {
                                           handle_center('branch_logo', response);
                                           (document.getElementById("center_logo_input") as HTMLInputElement).value = "";
                                       })
                                       .catch((error) => {
                                           console.log(error)
                                       })
                               }}/>
                        <label htmlFor="center_logo_input" className="ui mini primary button fluid">
                            <i className="ui upload icon"/>Select an image file
                        </label>
                    </div>

                    {/*Branch Name*/}
                    <span className="label">Branch name</span>
                    <MyInput placeholder="Enter branch name" name="branch_name"
                             value={branch.branch_name} change={handle_center}/>

                    <span className="label">Branch Contacts (Separate with commas)</span>
                    <MyInput placeholder="Enter branch contacts" name="contact_address"
                             value={branch.contact_address} change={handle_center}/>

                    <span className="label">Branch Email</span>
                    <MyInput placeholder="Enter branch email" name="email_address" value={branch.email_address}
                             change={handle_center}/>

                    <span className="label">Box Number</span>
                    <MyInput placeholder="Enter center email" name="box_number"
                             value={branch.box_number} change={handle_center}/>

                    <span className="label">Center Location</span>
                    <MyInput placeholder="Enter center location" name="location" value={branch.location}
                             change={handle_center}/>
                </div>

                <div className="button_container">
                    <div className="row m-0">
                        <div className="col-6 pl-0 pr-1">
                            <Button negative icon="trash" labelPosition="left" size="mini" fluid
                                    content="Clear Data" onClick={() => setBranch(initial_branch)}/>
                        </div>
                        <div className="col-6 pl-1 pr-0">
                            <Button positive icon="save" labelPosition="left" size="mini" fluid
                                    content="Save Center" onClick={save_center}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    React.useEffect(() => {
        if (params.branches.length === 1) {
            setBranch(params.branches[0])
        }
    }, [params.branches])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            <div className="content_container">
                {
                    params.branches.length === 0 &&
                    <div className="row h-100 w-100 m-0">
                        <div className="offset-3 col-6 p-1 h-100">
                            {display_branch_data()}
                        </div>
                    </div>
                }

                {
                    params.branches.length > 0 &&
                    <div className="row h-100 m-0">
                        <div className="col-12 col-md-7 p-1 h-100">
                            <div className="window_content">
                                <Table celled striped compact size='small' inverted color='grey' selectable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '250px'}}>Center Name</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '250px'}}>Center Location</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            params.branches.map((center, index) =>
                                                <Table.Row key={center.branch_id} onClick={() => setBranch({...center})}>
                                                    <Table.Cell
                                                        style={{width: '50px'}}
                                                        textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                                    <Table.Cell style={{width: '250px'}}>{center.branch_name}</Table.Cell>
                                                    <Table.Cell style={{width: '250px'}}>{center.location}</Table.Cell>
                                                </Table.Row>
                                            )
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>

                        <div className="col-12 col-md-5 p-1 h-100">
                            {display_branch_data()}
                        </div>
                    </div>
                }
            </div>
        </>
    )
}
