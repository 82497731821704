import React from "react";
import {useAppDispatch, useAppSelector} from "./store/store";
import {getFCMToken, getUser, logoutUser, updateFCMToken, updateUser} from "./store/userSlice";
import {FirebaseApp} from "firebase/app";
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import {ToastsStore} from "react-toasts";
import {Print} from "../nav/SideBar";

export default function AppFirebase(params: { firebaseApp: FirebaseApp, setPrint: (print: Print) => void }) {
    const user = useAppSelector(getUser)
    const fcmToken = useAppSelector(getFCMToken)
    const dispatch = useAppDispatch()
    const messaging = getMessaging(params.firebaseApp);

    const get_web_token = () => {
        if (Notification.permission === 'granted') {
            getToken(messaging, {vapidKey: 'BOvt3ZgWBvuioz9HigugjosiYZWEyCeU7qKrwyLzarmyW4nU_J-y-8nT6Y0WTk6bPRLbspZdYRYnsRqBF2xshak'})
                .then((currentToken) => {
                    if (currentToken !== fcmToken.token) {
                        dispatch(updateFCMToken({token: currentToken, saved: false}))
                    }
                })
                .catch((err) => {
                    console.error(err)
                });
        }else{
            console.log("No permissions gotten")
        }
    }

    onMessage(messaging, function (payload) {
        if (payload.data !== undefined) {
            if (payload.data.hasOwnProperty("type")) {
                const data = JSON.parse(payload.data.data)
                if (payload.data.type === "log_out_suspended") {
                    ToastsStore.info("Account was logged in from another session, your login is required")
                    dispatch(logoutUser())
                } else if (payload.data.type === "permissions_changed") {
                    if (parseInt(data.user_id) === user.user_id) {
                        dispatch(updateUser({...user, roles: data.roles}))
                    }
                } else if (payload.data.type === "print_enrolled") {
                    params.setPrint({client_id: data.client_id, fingerprints: data.fingerprints, id: data.id})
                }
            }
        }
    })

    document.onclick = function () {
        if (Notification.permission !== 'granted') {
            Notification.requestPermission()
                .then((permission) => {
                    if (permission === 'granted') {
                        get_web_token()
                    } else {
                        console.log('Unable to get permission to notify')
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }else{
            get_web_token()
        }
    }

    return (
        <>
        </>
    )
}
