import {Button, Modal, Table} from "semantic-ui-react";
import Utils from "../../../../utils/utils/Utils";
import {MyInput} from "../../../../utils/components/MyInput";
import React from "react";
import {Loading} from "../../../../utils/components/Loading";
import {ToastsStore} from "react-toasts";
import {ApiRequest} from "../../../../utils/ApiRequest";
import {StockType} from "../../../../utils/utils/Models";

export default function StockTypes(params: {
    show: boolean, types: StockType[], close: () => void, category: 'Pharmacy',
    setStockTypes: (stockTypes: StockType[]) => void
}) {
    const [loading, setLoading] = React.useState({show: false, message: ""})
    const [type, setType] = React.useState<StockType>({type_id: 0, type_name: '', type_category: params.category})

    const save_type = () => {
        if (type.type_name.trim().length < 2) {
            ToastsStore.error('Enter a valid stock type')
        } else {
            setLoading({show: true, message: 'Saving stock type, please wait'})
            ApiRequest.save_stock_types({type_id: type.type_id, type_category: type.type_category, type_name: type.type_name.trim()})
                .then((response) => {
                    setLoading({message: "", show: false})
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            if (type.type_id === 0) {
                                params.setStockTypes([...params.types, {...type, type_id: response.data['type_id']}])
                            } else {
                                params.setStockTypes(params.types.map((aType) => aType.type_id === type.type_id ? type : aType))
                            }
                            setType({type_category: params.category, type_name: '', type_id: 0})
                            ToastsStore.success("Stock type saved successfully")
                        } else if (response.data.code === 2) {
                            ToastsStore.error("Stock type name is already in use")
                        }
                    } else {
                        ToastsStore.error("Error while saving stock type, please retry")
                    }
                })
                .catch(() => {
                    setLoading({message: "", show: false})
                    ToastsStore.error("Error while saving stock type, please retry")
                })
        }
    }

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            <Modal open={params.show} basic size='small' centered={false} closeOnDimmerClick={false} closeOnDocumentClick={false}>
                <div className="modal_container" style={{height: '400px'}}>
                    <div className="row m-0 h-100">
                        <div className="col-6 pl-0 pr-2 h-100">
                            <div className="window_content">
                                <Table celled striped compact={false} size='small' inverted color='grey' selectable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                            <Table.HeaderCell>Stock Type Name</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            params.types
                                                .map((aType, index) =>
                                                    <Table.Row key={aType.type_name} onClick={() => setType(aType)}>
                                                        <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                                        <Table.Cell>{aType.type_name}</Table.Cell>
                                                    </Table.Row>
                                                )
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>

                        <div className="col-6 px-0">
                            <div className="form_container">
                                {/*Group Name*/}
                                <span className="label mt-0">Stock Type Name</span>
                                <MyInput placeholder="Enter type name" name="type_name" value={type.type_name}
                                         change={(name, value) => setType({...type, [name]: value})}/>
                            </div>

                            <div className="button_container">
                                <div className="row m-0">
                                    <div className="col-2 pl-0 pr-1">
                                        <Button negative icon="close" size="mini" fluid onClick={params.close}/>
                                    </div>
                                    <div className="col-5 px-0">
                                        <Button negative icon="trash" labelPosition="left" size="mini" fluid content={'Clear Data'}
                                                onClick={() => setType({type_category: params.category, type_name: '', type_id: 0})}/>
                                    </div>
                                    <div className="col-5 pl-1 pr-0">
                                        <Button positive icon="save" labelPosition="left" size="mini" fluid
                                                content="Save Data" onClick={save_type}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
