import React from "react";
import {Button} from "semantic-ui-react";
import {Stock, SupplyItem} from "../../../../../utils/utils/Models";
import Utils from "../../../../../utils/utils/Utils";
import {MyInput} from "../../../../../utils/components/MyInput";
import {ToastsStore} from "react-toasts";

export default function ItemRestock(params: { supplyItem: SupplyItem, stockItems: Stock[], updateItem: (supplyItem?: SupplyItem) => void }) {
    const [supplyItem, setSupplyItem] = React.useState(params.supplyItem)
    const handle_change = (name: string, value: string) => {
        setSupplyItem({...supplyItem, [name]: value})
    }

    const save_stock_item = () => {
        if (!Utils.is_valid_number(supplyItem.purchase_price.toString().trim()) || parseFloat(supplyItem.purchase_price.toString().trim()) < 0) {
            ToastsStore.error("Enter a valid total price of items purchased")
        } else if (!Utils.is_valid_number(supplyItem.purchase_quantity.toString().trim()) || parseFloat(supplyItem.purchase_quantity.toString().trim()) <= 0) {
            ToastsStore.error("Enter a valid number o items purchased")
        } else if (!Utils.is_valid_number(supplyItem.profit_margin.toString().trim())) {
            ToastsStore.error("Enter a valid minimum profit or loss margin")
        } else if (!Utils.is_valid_number(supplyItem.min_sale_price.toString().trim())) {
            ToastsStore.error("Enter a valid minimum sale price")
        } else if (!Utils.is_valid_number(supplyItem.sale_price.toString().trim())) {
            ToastsStore.error("Enter a valid sale price")
        } else if (parseFloat(supplyItem.sale_price.toString().trim()) < parseFloat(supplyItem.min_sale_price.toString().trim())) {
            ToastsStore.error(`Sale price cannot be less than ${parseFloat(supplyItem.min_sale_price.toString().trim()).toLocaleString()}`)
        } else {
            params.updateItem({
                min_sale_price: parseFloat(supplyItem.min_sale_price.toString().trim()),
                profit_margin: parseFloat(supplyItem.profit_margin.toString().trim()),
                purchase_price: parseFloat(supplyItem.purchase_price.toString().trim()),
                purchase_quantity: parseFloat(supplyItem.purchase_quantity.toString().trim()),
                sale_price: parseFloat(supplyItem.sale_price.toString().trim()),
                stock_id: supplyItem.stock_id,
                stock_name: supplyItem.stock_name
            })
        }
    }

    React.useEffect(() => {
        if (Utils.is_valid_number(supplyItem.purchase_price.toString().trim()) && parseFloat(supplyItem.purchase_price.toString().trim()) >= 0) {
            if (Utils.is_valid_number(supplyItem.purchase_quantity.toString().trim()) && parseFloat(supplyItem.purchase_quantity.toString().trim()) > 0) {
                if (Utils.is_valid_number(supplyItem.profit_margin.toString().trim())) {
                    const stockItem = params.stockItems.filter((aItem) => aItem.stock_id === supplyItem.stock_id)[0]
                    const total_items_available = parseFloat(supplyItem.purchase_quantity.toString().trim()) + stockItem.current_quantity
                    const current_item_price = parseFloat(stockItem.sale_price.toString()) * stockItem.current_quantity
                    const restocking_cost = parseFloat(supplyItem.purchase_price.toString().trim()) + parseFloat(supplyItem.profit_margin.toString().trim())
                    const min_sale_price = (restocking_cost + current_item_price) / total_items_available
                    setSupplyItem({...supplyItem, min_sale_price: min_sale_price})
                }
            }
        }
    }, [supplyItem.profit_margin, supplyItem.purchase_price, supplyItem.purchase_quantity])

    React.useEffect(() => {
        setSupplyItem(params.supplyItem)
    }, [params.supplyItem])

    return (
        <>
            <div className="form_container">
                <span className="label mt-0">Item Name</span>
                <span className="label_text">{supplyItem.stock_name === '' ? 'No item has been selected' : supplyItem.stock_name}</span>

                <span className="label">Purchase Price of Items</span>
                <MyInput placeholder="Enter purchase price of items" name="purchase_price" value={supplyItem.purchase_price.toString()} change={handle_change}/>

                {/*QUANTITY*/}
                <span className="label">Quantity Purchased</span>
                <MyInput placeholder="Enter quantity purchased" name="purchase_quantity" value={supplyItem.purchase_quantity.toString()} change={handle_change}/>

                {/*PROFIT MARGINS*/}
                <span className="label">Profit Margin</span>
                <MyInput placeholder="Enter profit margin" name="profit_margin" value={supplyItem.profit_margin.toString()} change={handle_change}/>

                {/*MIN SALE PRICE*/}
                <span className="label">Minimum Sale Price</span>
                <MyInput placeholder="Minimum selling price" name="min_sale_price" value={supplyItem.min_sale_price.toString()}/>

                {/*ACTUAL SALE PRICE*/}
                <span className="label">Actual Sale Price</span>
                <MyInput placeholder="Enter actual selling price" name="sale_price"
                         disabled={!Utils.is_valid_number(supplyItem.min_sale_price.toString().trim())}
                         value={supplyItem.sale_price.toString()} change={handle_change}/>
            </div>

            <div className="button_container">
                <div className="row m-0">
                    <div className="col-12 px-0">
                        <Button positive icon="save" labelPosition="left" disabled={supplyItem.stock_id === 0}
                                size="mini" fluid content="Update Stock Item" onClick={save_stock_item}/>
                    </div>
                </div>
            </div>
        </>
    )
}
