import React from "react";
import {Button, Form, Modal, TextArea} from "semantic-ui-react";

export default function MessageContent(params: { show: boolean, message: string, close: (message?: string) => void }) {
    const [message, setMessage] = React.useState(params.message)

    React.useEffect(() => {
        setMessage(params.message)
    }, [params.message])

    return (
        <Modal open={params.show} basic size='mini' centered={false} closeOnDimmerClick={false} closeOnDocumentClick={false}>
            <div className="modal_container full_height">
                <span className="form_header">Enter email message details</span>

                <div className="form_container has_header">
                    <Form className="mt-3">
                        <span className="label mt-0">Message Content</span>
                        <TextArea
                            placeholder='Enter email message here' value={message} rows={20}
                            onChange={(event, data) => setMessage(data.value as string)}/>
                    </Form>
                </div>

                <div className="button_container">
                    <div className="row m-0">
                        <div className="col-6 pl-0 pr-1">
                            <Button negative icon="trash" labelPosition="left" size="tiny" fluid
                                    content="Cancel" onClick={() => params.close()}/>
                        </div>
                        <div className="col-6 pl-1 pr-0">
                            <Button positive icon="save" labelPosition="left" size="tiny" fluid
                                    content={"Proceed"} onClick={() => params.close(message)}/>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
