import React from "react";
import {Button, Table} from "semantic-ui-react";
import {MyInput} from "../../../utils/components/MyInput";
import {ToastsStore} from "react-toasts";
import {Loading} from "../../../utils/components/Loading";
import {ApiRequest} from "../../../utils/ApiRequest";
import Utils from "../../../utils/utils/Utils";
import AddMeasurement from "./AddMeasurement";
import {BillGroup} from "../../clients/ClientList";
import {MatchedUser} from "../../../nav/SideBar";
import {ClientInfo} from "../../../utils/utils/Models";
import avatar from "../../../assets/images/avatar.png";
import ClientDisplay from "../../clients/ClientDisplay";
import CreateBillOrder from "../../billing/orders/utils/CreateBillOrder";

export interface Measurement {
    measurement_id: number
    client_id: number
    doctor_id: number
    temperature: string | number
    diastole: string | number
    systole: string | number
    heart_rate: string | number
    height: string | number
    weight: string | number
    bmi: string | number
    comments: string
    time_taken: string
    time_created: string
}

export default function DoctorMeasurements(params: { groups: BillGroup[], matchedUser: MatchedUser }) {
    const [loading, setLoading] = React.useState({show: false, message: ""})
    const [search, setSearch] = React.useState({search_name: ""})
    const handle_search = (name: string, value: string | number) => {
        setSearch({...search, [name]: value})
    }

    const initial_measurement: Measurement = {
        bmi: "", client_id: 0, comments: "", diastole: "", doctor_id: 0, heart_rate: "", height: "",
        measurement_id: 0, systole: "", temperature: "", time_created: "", time_taken: "", weight: ""
    }

    const [show, setShow] = React.useState({measurement: initial_measurement, show: false})

    const [clients, setClients] = React.useState(Array<ClientInfo>())
    const [measurements, setMeasurements] = React.useState(Array<Measurement>())
    const [showRequest, setShowRequest] = React.useState({show: false, client_id: 0, client_name: ""})
    const [clientInfo, setClientInfo] = React.useState<ClientInfo | undefined>(undefined)

    const search_clients = () => {
        if (search.search_name.trim().length < 3) {
            ToastsStore.info("At least 3 characters must be inserted")
        } else {
            setLoading({message: "Searching client data, please wait", show: true})

            ApiRequest.get_clients_list({search: search.search_name.trim()})
                .then((response) => {
                    setLoading({message: '', show: false})
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        setClients(response.data.clients)
                    } else {
                        ToastsStore.error("Could not load clients' data")
                    }
                })
                .catch(() => {
                    setLoading({message: '', show: false})
                    ToastsStore.error("Could not load clients' data")
                })
        }
    }

    const search_results = (client_id: number) => {
        setLoading({message: "Loading records, please wait", show: true})

        ApiRequest.get_client_measurements({client_id: client_id})
            .then((response) => {
                setLoading({message: '', show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setMeasurements(response.data['measurements'])
                } else {
                    ToastsStore.error("Could not client's records")
                }
            })
            .catch(() => {
                setLoading({message: '', show: false})
                ToastsStore.error("Could not client's records")
            })
    }

    React.useEffect(() => {
        if (clients.length === 1) {
            search_results(clients[0].id)
        }
        setMeasurements([])
    }, [clients])

    React.useEffect(() => {
        if (params.matchedUser.client_id !== 0) {
            search_results(params.matchedUser.client_id)
        }
    }, [params.matchedUser.time])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            <ClientDisplay close={() => setClientInfo(undefined)} client={clientInfo}/>

            <AddMeasurement
                show={show.show} measurement={show.measurement}
                close={() => setShow({...show, show: false})}
                update={(measurement) => {
                    if (show.measurement.measurement_id === 0) {
                        setMeasurements([measurement, ...measurements])
                    } else {
                        setMeasurements(measurements.map((aMeasurement) => aMeasurement.measurement_id === measurement.measurement_id ?
                            measurement : aMeasurement
                        ))
                    }
                    setShow({...show, measurement: measurement})
                }}/>

            <CreateBillOrder
                show={showRequest.show} client_id={showRequest.client_id} client_name={showRequest.client_name}
                groups={params.groups} close={() => setShowRequest({...showRequest, show: false})}/>

            <div className="content_bar">
                <div className="search_bar">
                    <div style={{width: '250px'}}>
                        <MyInput
                            placeholder="Client name or email address" name='search_name' value={search.search_name}
                            change={handle_search} enter={search_clients}/>
                    </div>
                </div>

                <div className="content_buttons">
                    <Button primary size='mini' icon='search' labelPosition="left" content="Search Clients" onClick={search_clients}/>
                </div>
            </div>

            <div className="content_container">
                <div className="row h-100 m-0">
                    <div className="col-12 col-md-4 p-1 h-100">
                        <div className="window_content">
                            <Table celled striped compact={false} size='small' inverted color='grey' selectable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '120px'}} textAlign="center" colSpan={2}/>
                                        <Table.HeaderCell style={{width: '70px'}}/>
                                        <Table.HeaderCell style={{width: '170px'}}>Client Name</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        clients.map((client) =>
                                            <Table.Row key={client.id}>
                                                <Table.Cell style={{width: '120px'}}>
                                                    <Button
                                                        primary size='mini' icon='add' compact
                                                        onClick={() => {
                                                            setShow({
                                                                show: true, measurement: {...initial_measurement, client_id: client.id}
                                                            })
                                                        }}/>
                                                    <Button
                                                        primary size='mini' icon='yelp' compact
                                                        onClick={() => {
                                                            setShowRequest({
                                                                show: true, client_id: client.id, client_name: `${client.name}`
                                                            })
                                                        }}/>
                                                    <Button
                                                        primary size='mini' icon='search' compact
                                                        onClick={() => search_results(client.id)}/>
                                                </Table.Cell>
                                                <Table.Cell style={{width: '70px'}}>
                                                    <img
                                                        onClick={() => setClientInfo(client)} className={"table_avatar"} alt="Passport"
                                                        src={`${(client.dp === '') ? avatar : `${Utils.BASE_URL}${client.dp}`}`}/>
                                                </Table.Cell>
                                                <Table.Cell style={{width: '170px'}}>{client.name}</Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                    </div>

                    <div className="col-12 col-md-8 p-1 h-100">
                        <div className="window_content">
                            <Table celled striped compact={false} size='small' inverted color='grey' selectable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '30px'}} textAlign="center">No.</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '180px'}}>Test Date</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '80px'}} textAlign="center"> Temperature</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '80px'}} textAlign="center">Diastole</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '80px'}} textAlign="center">Systole</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '80px'}} textAlign="center">Pulse</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '80px'}} textAlign="center">Height</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '80px'}} textAlign="center">Weight</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '80px'}} textAlign="center">BMI</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        measurements.map((measurement, index) =>
                                            <Table.Row
                                                key={measurement.measurement_id}
                                                onClick={() => setShow({show: true, measurement: measurement})}>
                                                <Table.Cell style={{width: '30px'}} textAlign="center">{index + 1}</Table.Cell>
                                                <Table.Cell style={{width: '180px'}}>
                                                    {Utils.localise_date_time(measurement.time_taken)}
                                                </Table.Cell>
                                                <Table.Cell
                                                    style={{width: '80px'}} textAlign="center">{measurement.temperature}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '80px'}} textAlign="center">{measurement.diastole}</Table.Cell>
                                                <Table.Cell style={{width: '80px'}} textAlign="center">{measurement.systole}</Table.Cell>
                                                <Table.Cell style={{width: '80px'}} textAlign="center">{measurement.heart_rate}</Table.Cell>
                                                <Table.Cell style={{width: '80px'}} textAlign="center">{measurement.diastole}</Table.Cell>
                                                <Table.Cell style={{width: '80px'}} textAlign="center">{measurement.weight}</Table.Cell>
                                                <Table.Cell style={{width: '80px'}} textAlign="center">{measurement.bmi}</Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
