import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from "./app/App";
import {persistedStore, store} from "./app/utils/store/store";
import {ToastsContainer, ToastsContainerPosition, ToastsStore} from "react-toasts";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-phone-input-2/lib/style.css';
import 'semantic-ui-css/semantic.min.css'
import "./app/assets/css/styles.scss"

ReactDOM
    .createRoot(document.getElementById('root') as HTMLElement)
    .render(
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistedStore}>
                <ToastsContainer  position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} />
                <App/>
            </PersistGate>
        </Provider>
    )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
