import React from 'react';
import SideBar, {Print} from "./nav/SideBar";
import {getCounter, getUser, updateCounter, updateWebToken} from "./utils/store/userSlice";
import {useAppDispatch, useAppSelector} from "./utils/store/store";
import {FirebaseApp, initializeApp} from "firebase/app";
import AppFirebase from './utils/AppFirebase';
import Login from "./auth/Login";
import {createBrowserRouter, RouterProvider,} from "react-router-dom";

export default function App() {
    const user = useAppSelector(getUser)
    const counter = useAppSelector(getCounter)
    const dispatch = useAppDispatch()

    window.onclick = () => {
        dispatch(updateCounter(Math.floor(Date.now() / 1000)))
    }

    const firebaseApp: FirebaseApp = initializeApp({
        apiKey: "AIzaSyA63Nl28w2HphP7Z0IEHzMWClp6zIb06lo",
        authDomain: "misc-applications.firebaseapp.com",
        databaseURL: "https://misc-applications.firebaseio.com",
        projectId: "misc-applications",
        storageBucket: "misc-applications.appspot.com",
        messagingSenderId: "797043390702",
        appId: "1:797043390702:web:79454b33e3df43c1bcdbff",
        measurementId: "G-017FRGE287"
    });

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            if (user.bearer_token !== "") {
                if ((Math.floor(Date.now() / 1000) - counter) >= (5 * 60)) {
                    dispatch(updateWebToken(""))
                }
            }
        }, 5000);
        return () => clearInterval(intervalId);
    }, [counter])

    const [print, setPrint] = React.useState<Print>({client_id: 0, fingerprints: "", id: 0})

    const router = createBrowserRouter([
        {path: "/", element: <SideBar print={print}/>, errorElement: <SideBar print={print}/>,},
        {path: "*", element: <SideBar print={print}/>,},
    ]);

    return (
        <>
            <AppFirebase firebaseApp={firebaseApp} setPrint={setPrint}/>

            {(user.bearer_token === "" || user.user_id === 0) && <Login/>}

            <RouterProvider router={router} fallbackElement={<p>Loading...</p>}/>
        </>
    )
}
