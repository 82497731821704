import React from "react";
import {Button, Table} from "semantic-ui-react";
import {MySelectMulti} from "../../../utils/components/MySelectMulti";
import Utils from "../../../utils/utils/Utils";
import {ApiRequest} from "../../../utils/ApiRequest";
import {ToastsStore} from "react-toasts";
import {MyDateInput} from "../../../utils/components/MyDateInput";
import {Loading} from "../../../utils/components/Loading";
import {MyInput} from "../../../utils/components/MyInput";
import {MatchedUser} from "../../../nav/SideBar";

interface Test {
    category_id: number
    test_id: number
    test_abbr: string
    test_name: string
}

interface Category {
    category_id: number
    category_name: string
}

interface Log {
    status_name: 'Results' | 'Verified'
    status_date: string
    user_id: number
    username: string
}

interface Report {
    age: string
    bill_id: 1
    category_name: string
    gender: string
    logs: Log[]
    results: string
    results_desc: string
    sample_id: number
    sample_no: string
    sample_status: string
    test_abbr: string
    test_id: number
    test_name: string
    client: string
    client_no: string
}

export default function SampleReports(params: { type: 'lab' | 'radiology', matchedUser: MatchedUser }) {
    const [loading, setLoading] = React.useState({show: false, message: ""})

    const [tests, setTests] = React.useState(Array<Test>())
    const [categories, setCategories] = React.useState(Array<Category>())

    const [search, setSearch] = React.useState({
        status: Array<string>(), min_date: Utils.today(), max_date: Utils.today(),
        categories: Array<number>(), tests: Array<number>(), name: ''
    })
    const handle_search = (name: string, value: string | string[]) => {
        setSearch({...search, [name]: value})
    }

    const [samples, setSamples] = React.useState(Array<Report>())
    const load_reports = (client_id?: number) => {
        setLoading({show: true, message: "Loading sample reports, please wait"})
        ApiRequest.get_sample_reports({
            categories: JSON.stringify(search.categories), tests: JSON.stringify(search.tests),
            status: JSON.stringify(search.status), min_date: search.min_date, max_date: search.max_date,
            name: search.name.trim(), type: params.type, client_id: client_id
        })
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setSamples(response.data.samples)
                } else {
                    ToastsStore.error("Error while loading samples, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while loading samples, please retry")
            })
    }

    const get_lab_catalogue = () => {
        ApiRequest.get_lab_catalogue({type: params.type})
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setTests(response.data.tests)
                    setCategories(response.data.categories)
                }
            })
    }

    React.useEffect(() => {
        setSearch({
            ...search, tests: search.tests.filter((test) => search.categories
                .includes((tests.filter((_test) => _test.test_id === test))[0].category_id))
        })
    }, [search.categories])

    React.useEffect(() => {
        get_lab_catalogue()
    }, [])

    React.useEffect(() => {
        if (params.matchedUser.client_id !== 0) {
            load_reports(params.matchedUser.client_id)
        }
    }, [params.matchedUser.time])

    return (
        <>
            <Loading show={loading.show} text={loading.message}
                     hide={() => setLoading({message: "", show: false})}/>

            <div className="content_bar">
                <div className="search_bar">
                    <div style={{width: '180px'}}>
                        <MyInput
                            placeholder="Name, contact, email, bill no"
                            name="name" value={search.name} change={handle_search} enter={() => load_reports()}/>
                    </div>

                    <div style={{width: '135px'}}>
                        <MyDateInput
                            value={search.min_date} name="min_date" placeholder="Min Date"
                            change={handle_search} maxDate={search.max_date}/>
                    </div>

                    <div style={{width: '135px'}}>
                        <MyDateInput
                            value={search.max_date} name="max_date" placeholder="Min Date"
                            change={handle_search} minDate={search.min_date} maxDate={Utils.today()}/>
                    </div>

                    <div style={{width: '160px'}}>
                        <MySelectMulti
                            multiple={true} placeholder="Sample status" selected={search.status}
                            change={(value) => handle_search('status', value as string[])}
                            items={[{text: 'Samples with results', value: 'Results'}, {
                                text: 'Verified sample results',
                                value: 'Verified'
                            }]}/>
                    </div>

                    <div style={{width: '160px'}}>
                        <MySelectMulti
                            multiple={true} placeholder="Test categories" selected={search.categories}
                            items={categories.map((category) => ({value: category.category_id, text: category.category_name}))}
                            change={(value) => setSearch({...search, categories: value as number[]})}/>
                    </div>
                    <div style={{width: '160px'}}>
                        <MySelectMulti
                            multiple={true} placeholder="Lab Tests" selected={search.tests}
                            items={
                                tests
                                    .filter((test) => search.categories.includes(test.category_id))
                                    .map((test) => ({value: test.test_id, text: test.test_name}))
                            }
                            change={(value) => setSearch({...search, tests: value as number[]})}/>
                    </div>
                </div>

                <div className="content_buttons">
                    <Button primary size='mini' icon='search' labelPosition="left" content="Load Reports"
                            onClick={() => load_reports()}/>
                </div>
            </div>

            <div className="content_container">
                <div className="window_content">
                    <Table celled striped compact={false} size='small' inverted color='grey' selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '40px'}} textAlign="center">No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '120px'}}>Sample No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '80px'}}>Client No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '150px'}}>Client Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '75px'}}>Gender</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '75px'}}>Age</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '140px'}}>Test Category</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '140px'}}>Test Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '75px'}}>Status</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '90px'}}>Results</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '300px'}}>Results Interpretation</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                samples.map((sample, index) =>
                                    <Table.Row key={sample.sample_id}>
                                        <Table.Cell style={{width: '40px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                        <Table.Cell style={{width: '120px'}}>{sample.sample_no}</Table.Cell>
                                        <Table.Cell style={{width: '80px'}}>{sample.client_no}</Table.Cell>
                                        <Table.Cell style={{width: '150px'}}>{sample.client}</Table.Cell>
                                        <Table.Cell style={{width: '75px'}}>{sample.gender}</Table.Cell>
                                        <Table.Cell style={{width: '75px'}}>{sample.age}</Table.Cell>
                                        <Table.Cell style={{width: '140px'}}>{sample.category_name}</Table.Cell>
                                        <Table.Cell style={{width: '140px'}}>{sample.test_name}</Table.Cell>
                                        <Table.Cell style={{width: '75px'}}>{sample.sample_status}</Table.Cell>
                                        <Table.Cell style={{width: '90px'}}>{sample.results}</Table.Cell>
                                        <Table.Cell style={{width: '300px'}}>{sample.results_desc}</Table.Cell>
                                    </Table.Row>
                                )
                            }
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </>
    )
}
