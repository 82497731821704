import React from "react";
import {ToastsStore} from "react-toasts";
import {ApiRequest} from "../../../utils/ApiRequest";
import {Button, Modal} from "semantic-ui-react";
import {Loading} from "../../../utils/components/Loading";
import {MyInput} from "../../../utils/components/MyInput";
import {MySelect} from "../../../utils/components/MySelect";

export interface Category {
    category_id: number
    category_name: string
    category_type: 'lab' | 'radiology'
}

export const initial_category: Category = {category_id: 0, category_name: "", category_type: 'lab'}

export default function ModalCategory(params: {
    show: boolean, hide: () => void, update: (category: Category) => void, category: Category
}) {
    const [loading, setLoading] = React.useState({show: false, message: ""})
    const [category, setCategory] = React.useState(initial_category)

    const save_category = () => {
        if (category.category_name.trim().length < 3) {
            ToastsStore.error("Enter a valid category name")
        } else {
            setLoading({show: true, message: "Saving category, please wait"})

            ApiRequest.save_test_category({
                category_id: category.category_id,
                category_type: category.category_type,
                category_name: category.category_name.trim()
            })
                .then((response) => {
                    setLoading({message: "", show: false})
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            params.update({
                                category_id: response.data.category_id,
                                category_type: category.category_type,
                                category_name: category.category_name.trim()
                            })
                            ToastsStore.success("Test category saved successfully")
                        } else if (response.data.code === 2) {
                            ToastsStore.error("Category name is already in use")
                        }
                    } else {
                        ToastsStore.error("Error while saving test category, please retry")
                    }
                })
                .catch(() => {
                    setLoading({message: "", show: false})
                    ToastsStore.error("Error while saving test category, please retry")
                })
        }
    }

    React.useEffect(() => {
        setCategory(params.category)
    }, [params.category])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            <Modal open={params.show} basic size='mini' centered={false} closeOnDimmerClick={false} closeOnDocumentClick={false}>
                <div className="modal_container">
                    <div className="form_container">
                        <span className="label">Section</span>
                        <MySelect
                            name="status" value={category.category_type} placeholder="Select investigation section"
                            change={(value) => setCategory({...category, category_type: value as 'lab' | 'radiology'})}
                            options={
                                category.category_id === 0 ?
                                    [
                                        {
                                            text: category.category_type === 'lab' ? 'Laboratory Section' : 'Radiology Section',
                                            value: category.category_type
                                        },
                                    ]
                                    :
                                    [{text: 'Laboratory Section', value: 'lab'}, {text: 'Radiology Section', value: 'radiology'}]
                            }/>

                        <span className="label">Category</span>
                        <MyInput placeholder="Enter category name" name="branch_name" value={category.category_name}
                                 change={(name, value) => setCategory({...category, category_name: value})}/>
                    </div>

                    <div className="button_container">
                        <div className="row m-0">
                            <div className="col-6 pl-0 pr-1">
                                <Button
                                    negative icon="close" labelPosition="left" size="mini" fluid
                                    content={category.category_id === 0 ? 'Close Window' : 'Clear Data'}
                                    onClick={() => category.category_id === 0 ? params.hide() : setCategory(initial_category)}/>
                            </div>
                            <div className="col-6 pl-1 pr-0">
                                <Button
                                    positive icon="save" labelPosition="left" size="mini" fluid
                                    content="Save Category" onClick={save_category}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
