import React from "react";
import {Button, Modal} from "semantic-ui-react";
import Utils from "../../../utils/utils/Utils";
import {ToastsStore} from "react-toasts";
import {ApiRequest} from "../../../utils/ApiRequest";
import {MyInput} from "../../../utils/components/MyInput";
import {tier_type} from "../Billing";

export interface PriceEdit {
    tier_type: tier_type,
    title: string
    bill_amount: string | number
    group_id: number
    test_id: number
    tier_id: number
    show: boolean
    index: number
}

export default function EditPrice(params: {
    title: string, bill_amount: string | number, group_id: number, test_id: number, tier_id: number, show: boolean, index: number, tier_type: tier_type,
    close: () => void,
    update: (tier_id: number, bill_amount: string, username: string, time: string, index: number) => void
}) {
    const [saving, setSaving] = React.useState(false)
    const [amount, setAmount] = React.useState(params.bill_amount.toString())

    const save_billing = () => {
        if (!Utils.is_valid_number(amount.trim()) && amount.trim() !== '') {
            ToastsStore.error("Enter  a valid amount")
        } else {
            setSaving(true)
            ApiRequest.save_billing_data({
                bill_amount: amount.trim(),
                tier_id: params.tier_id,
                test_id: params.test_id,
                group_id: params.group_id,
                tier_type: params.tier_type
            })
                .then((response) => {
                    setSaving(false)
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            ToastsStore.success("Billing information saved successfully")
                            params.update(response.data.tier_id, amount.trim(), response.data.username, response.data.time, params.index)
                        } else if (response.data.code === 2) {
                            ToastsStore.error("Price was already saved, first refresh to get updated prices")
                        }
                    } else {
                        ToastsStore.error("Error while saving pricing, please retry")
                    }
                })
                .catch(() => {
                    setSaving(false)
                    ToastsStore.error("Error while saving pricing, please retry")
                })
        }
    }

    React.useEffect(() => {
        setAmount(params.bill_amount.toString())
    }, [params.tier_id, params.group_id, params.test_id, params.bill_amount])

    return (
        <>
            <Modal open={params.show} basic size='mini' centered={false} closeOnDimmerClick={false} closeOnDocumentClick={false}>
                <div className="modal_container">
                    <span className="form_header" dangerouslySetInnerHTML={{__html: params.title}}/>

                    <div className="form_container has_header">
                        <span className="label mt-0">Enter price amount</span>
                        <MyInput placeholder="Enter price amount" name="amount" value={amount}
                                 change={(name, value) => setAmount(value)}/>
                    </div>

                    <div className="button_container">
                        <div className="row m-0">
                            <div className="col-2 pl-0 pr-1">
                                <Button negative icon="close" size="mini" fluid onClick={params.close} disabled={saving}/>
                            </div>
                            <div className="col-5 px-1">
                                <Button negative icon="trash" labelPosition="left" size="mini" fluid content='Clear Price'
                                        onClick={() => setAmount('')} disabled={saving}/>
                            </div>
                            <div className="col-5 px-0">
                                <Button positive icon="save" labelPosition="left" size="mini" fluid
                                        content="Save Pricing" onClick={save_billing} disabled={saving} loading={saving}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
