import {Loading} from "../../../../utils/components/Loading";
import {Button, Modal, Table} from "semantic-ui-react";
import Utils from "../../../../utils/utils/Utils";
import {MyInput} from "../../../../utils/components/MyInput";
import React from "react";
import {StockUnit} from "../../../../utils/utils/Models";
import {ToastsStore} from "react-toasts";
import {ApiRequest} from "../../../../utils/ApiRequest";

export default function StockUnits(params: { show: boolean, units: StockUnit[], close: () => void, setUnits: (units: StockUnit[]) => void }) {
    const [loading, setLoading] = React.useState({show: false, message: ""})
    const [stockUnit, setStockUnit] = React.useState<StockUnit>({unit_id: 0, unit_name: ''})

    const save_stock_unit = () => {
        if (stockUnit.unit_name.trim().length < 2) {
            ToastsStore.error('Enter a valid stock unit name')
        } else {
            setLoading({show: true, message: 'Saving stock name, please wait'})
            ApiRequest.save_stock_units({unit_id: stockUnit.unit_id, unit_name: stockUnit.unit_name.trim()})
                .then((response) => {
                    setLoading({message: "", show: false})
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            if (stockUnit.unit_id === 0) {
                                params.setUnits([...params.units, {...stockUnit, unit_id: response.data['unit_id']}])
                            } else {
                                params.setUnits(params.units.map((aUnit) => aUnit.unit_id === stockUnit.unit_id ? stockUnit : aUnit))
                            }
                            setStockUnit({unit_id: 0, unit_name: ''})
                            ToastsStore.success("Stock unit saved successfully")
                        } else if (response.data.code === 2) {
                            ToastsStore.error("Stock unit name is already in use")
                        }
                    } else {
                        ToastsStore.error("Error while saving stock unit name, please retry")
                    }
                })
                .catch(() => {
                    setLoading({message: "", show: false})
                    ToastsStore.error("Error while saving stock unit name, please retry")
                })
        }
    }

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            <Modal open={params.show} basic size='small' centered={false} closeOnDimmerClick={false} closeOnDocumentClick={false}>
                <div className="modal_container" style={{height: '400px'}}>
                    <div className="row m-0 h-100">
                        <div className="col-6 pl-0 pr-2 h-100">
                            <div className="window_content">
                                <Table celled striped compact={false} size='small' inverted color='grey' selectable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                            <Table.HeaderCell>Stock Unit Name</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            params.units
                                                .map((aType, index) =>
                                                    <Table.Row key={aType.unit_id} onClick={() => setStockUnit(aType)}>
                                                        <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                                        <Table.Cell>{aType.unit_name}</Table.Cell>
                                                    </Table.Row>
                                                )
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>

                        <div className="col-6 px-0">
                            <div className="form_container">
                                {/*Group Name*/}
                                <span className="label mt-0">Stock Unit Name</span>
                                <MyInput placeholder="Enter unit name" name="unit_name" value={stockUnit.unit_name}
                                         change={(name, value) => setStockUnit({...stockUnit, [name]: value})}/>
                            </div>

                            <div className="button_container">
                                <div className="row m-0">
                                    <div className="col-2 pl-0 pr-1">
                                        <Button negative icon="close" size="mini" fluid onClick={params.close}/>
                                    </div>
                                    <div className="col-5 px-0">
                                        <Button negative icon="trash" labelPosition="left" size="mini" fluid content={'Clear Data'}
                                                onClick={() => setStockUnit({unit_id: 0, unit_name: ''})}/>
                                    </div>
                                    <div className="col-5 pl-1 pr-0">
                                        <Button positive icon="save" labelPosition="left" size="mini" fluid
                                                content="Save Data" onClick={save_stock_unit}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
