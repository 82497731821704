import {Loading} from "../../utils/components/Loading";
import React from "react";
import {MyDateInput} from "../../utils/components/MyDateInput";
import Utils from "../../utils/utils/Utils";
import {MyInput} from "../../utils/components/MyInput";
import {Button, Table} from "semantic-ui-react";
import {ApiRequest} from "../../utils/ApiRequest";
import {ToastsStore} from "react-toasts";
import {MatchedUser} from "../../nav/SideBar";

interface Report {
    client: string
    client_id: number
    client_no: string
    diagnosis_name: string
    doctor: string
    examination_id: number
    remarks: string
    time_created: string
    time_examined: string
}

export default function DoctorReports(params:{matchedUser: MatchedUser}) {
    const [loading, setLoading] = React.useState({show: false, message: ""})

    const [search, setSearch] = React.useState({
        min_date: Utils.today(), max_date: Utils.today(), categories: Array<number>(), name: ''
    })
    const handle_search = (name: string, value: string | string[]) => {
        setSearch({...search, [name]: value})
    }

    const [reports, setReports] = React.useState(Array<Report>())
    const load_reports = (client_id?: number) => {
        setLoading({show: true, message: "Loading examination reports, please wait"})
        ApiRequest.get_doctor_report({
            categories: JSON.stringify(search.categories), min_date: search.min_date,
            max_date: search.max_date, name: search.name.trim(), client_id: client_id
        })
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setReports(response.data.examinations)
                } else {
                    ToastsStore.error("Error while loading samples, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while loading samples, please retry")
            })
    }

    React.useEffect(() => {
        if (params.matchedUser.client_id !== 0) {
            load_reports(params.matchedUser.client_id)
        }
    }, [params.matchedUser.time])

    return (
        <>
            <Loading show={loading.show} text={loading.message}
                     hide={() => setLoading({message: "", show: false})}/>

            <div className="content_bar">
                <div className="search_bar">
                    <div>
                        <MyDateInput
                            value={search.min_date} name="min_date" placeholder="Min Date"
                            change={handle_search} maxDate={search.max_date}/>
                    </div>

                    <div>
                        <MyDateInput
                            value={search.max_date} name="max_date" placeholder="Min Date"
                            change={handle_search} minDate={search.min_date} maxDate={Utils.today()}/>
                    </div>


                    <div style={{width: '250px'}}>
                        <MyInput
                            placeholder="Search by name, contact, email"
                            name="name" value={search.name} change={handle_search} enter={()=> load_reports()}/>
                    </div>
                </div>

                <div className="content_buttons">
                    <Button primary size='mini' icon='search' labelPosition="left" content="Load Reports"
                            onClick={()=> load_reports()}/>
                </div>
            </div>

            <div className="content_container">
                <div className="window_content">
                    <Table celled striped compact={false} size='small' inverted color='grey' selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '40px'}} textAlign="center">No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '80px'}}>Client No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '120px'}}>Client Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '140px'}}>Exam Date</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '150px'}}>Exam Category</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '350px'}}>Findings</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                reports.map((report, index) =>
                                    <Table.Row key={report.examination_id}>
                                        <Table.Cell style={{width: '40px'}} textAlign="center">{index + 1}</Table.Cell>
                                        <Table.Cell style={{width: '80px'}}>{report.client_no}</Table.Cell>
                                        <Table.Cell style={{width: '120px'}}>{report.client}</Table.Cell>
                                        <Table.Cell style={{width: '140px'}}>{Utils.localise_date_time(report.time_created)}</Table.Cell>
                                        <Table.Cell style={{width: '150px'}}>{report.diagnosis_name}</Table.Cell>
                                        <Table.Cell style={{width: '350px'}}>{report.remarks}</Table.Cell>
                                    </Table.Row>
                                )
                            }
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </>
    )
}
