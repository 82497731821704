import React from "react";
import Utils from "../../../utils/utils/Utils";
import {MyDateInput} from "../../../utils/components/MyDateInput";
import {Button, Table} from "semantic-ui-react";
import {MySelectMulti} from "../../../utils/components/MySelectMulti";
import {StockSupply, StockType, StockUnit, Supplier} from "../../../utils/utils/Models";
import {ApiRequest} from "../../../utils/ApiRequest";
import {ToastsStore} from "react-toasts";
import moment from "moment";
import SupplierRestock from "./utils/restock/SupplierRestock";
import {Loading} from "../../../utils/components/Loading";

export default function StockSupplies() {
    const [loading, setLoading] = React.useState({show: false, message: ""})
    const [data, setData] = React.useState<{ types: StockType[], suppliers: Supplier[], units: StockUnit[] }>({types: [], suppliers: [], units: []})

    const [search, setSearch] = React.useState<{ suppliers: number[], min_date: string, max_date: string }>(
        {suppliers: [], min_date: Utils.today(), max_date: Utils.today()})
    const handle_search = (name: string, value: string | number) => {
        setSearch({...search, [name]: value})
    }

    const initialSupply: StockSupply = {
        bill_date: "", bill_no: "", profit_margin: 0, supplier_id: 0, supply_cost: '', supply_date: "", supply_id: 0,
        supply_items: [], time_created: "", time_updated: "", user_id: 0, username: "", payment_type: 'Cash', supply_balance: '0'
    }
    const [supplies, setSupplies] = React.useState(Array<StockSupply>())
    const [showSupply, setShowSupply] = React.useState({show: false, supply: initialSupply})

    const search_supplies = () => {
        setLoading({message: "Loading stocking list, please wait", show: true})
        ApiRequest.get_stocking_list({suppliers: JSON.stringify(search.suppliers), min_date: search.min_date, max_date: search.max_date})
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setSupplies(response.data['supplies'])
                } else {
                    ToastsStore.error("Error while loading stocking supplies, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while loading stocking supplies, please retry")
            })
    }

    const initialise_data = () => {
        setLoading({show: true, message: 'Initialising data, please wait'})
        ApiRequest.initialise_stocks()
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setData({
                        units: response.data['units'],
                        types: response.data['types'],
                        suppliers: response.data['suppliers'],
                    })
                } else {
                    ToastsStore.error("Error while initialising data, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while initialising data, please retry")
            })
    }

    const calculate_cost = (supply: StockSupply) => {
        let cost = 0
        supply.supply_items.forEach((item) => cost += parseFloat(item.purchase_price.toString()))
        return cost
    }

    React.useEffect(() => {
        initialise_data()
    }, [])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            <SupplierRestock
                show={showSupply.show} supply={showSupply.supply} suppliers={data.suppliers} units={data.units} stockTypes={data.types}
                close={() => setShowSupply({show: false, supply: initialSupply})}
                updateStockUnits={(units) => setData({...data, units: units})}
                updateStockTypes={(types) => setData({...data, types: types})}
                updateStockSuppliers={(suppliers) => setData({...data, suppliers: suppliers})}
                update={(supply) => {
                    if (showSupply.supply.supplier_id === 0) {
                        setSupplies([...supplies, supply])
                    }
                }}/>

            <div className="content_bar">
                <div className="search_bar">
                    <div style={{width: '250px'}}>
                        <MySelectMulti
                            multiple={true} placeholder="Stock suppliers" selected={search.suppliers}
                            items={data.suppliers.map((supplier) => ({value: supplier.supplier_id, text: supplier.supplier_name}))}
                            change={(value) => setSearch({...search, suppliers: value as number[]})}/>
                    </div>
                    <div>
                        <MyDateInput
                            value={search.min_date} name="min_date" placeholder="Min Date"
                            change={handle_search} maxDate={search.max_date}/>
                    </div>

                    <div>
                        <MyDateInput
                            value={search.max_date} name="max_date" placeholder="Min Date"
                            change={handle_search} minDate={search.min_date} maxDate={Utils.today()}/>
                    </div>
                </div>

                <div className="content_buttons">
                    <Button primary size='mini' icon='add' labelPosition="left" content="Add Supply"
                            onClick={() => setShowSupply({supply: initialSupply, show: true})}/>
                    <Button primary size='mini' icon='search' labelPosition="left" content="Search Orders" onClick={search_supplies}/>
                </div>
            </div>

            <div className="content_container">
                <div className="window_content">
                    <Table celled striped compact={false} size='small' inverted color='grey' selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '120px'}}>Receipt/Bill No</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '100px'}}>Supply Date</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '250px'}}>Supplier Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '120px'}}>Supply Cost</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '120px'}}>Stock Cost</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '120px'}}>Profit Margin</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '120px'}}>Invoice Date</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '120px'}}>Date Created</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                supplies.map((supply, index) =>
                                    <Table.Row key={supply.supplier_id} onClick={() => setShowSupply({show: true, supply: supply})}>
                                        <Table.Cell style={{width: '50px'}} textAlign="center">{index + 1}</Table.Cell>
                                        <Table.Cell style={{width: '120px'}}>{supply.bill_no}</Table.Cell>
                                        <Table.Cell style={{width: '100px'}}>{moment(supply.supply_date).format("ll")}</Table.Cell>
                                        <Table.Cell style={{width: '250px'}}>{supply.username}</Table.Cell>
                                        <Table.Cell style={{width: '120px'}}>{parseFloat(supply.supply_cost.toString()).toLocaleString()}</Table.Cell>
                                        <Table.Cell style={{width: '120px'}}>{calculate_cost(supply).toLocaleString()}</Table.Cell>
                                        <Table.Cell style={{width: '120px'}}>{supply.profit_margin.toLocaleString()}</Table.Cell>
                                        <Table.Cell style={{width: '120px'}}>{supply.bill_date !== "" && moment(supply.bill_date).format("ll")}</Table.Cell>
                                        <Table.Cell style={{width: '120px'}}>{moment(supply.supply_date).format("ll")}</Table.Cell>
                                    </Table.Row>
                                )
                            }
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </>
    )
}
