import React from "react";
import {Dropdown} from "semantic-ui-react";
import {selectType} from "../utils/Models";

export function MySelect(
    props: {
        change?: (value: selectType, text: string) => void, name: string, value: selectType, placeholder: string, disabled?: boolean,
        options: Array<{ text: string, value: string | number }>, style?: React.CSSProperties, multiple?: boolean
    }) {
    return (
        <Dropdown
            size="mini" fluid selection placeholder={props.placeholder} value={props.value}
            options={props.options} style={props.style} search={true} multiple={props.multiple ? props.multiple : false}
            disabled={props.disabled ? props.disabled : false}
            onChange={((event, data) => {
                if (props.change) {
                    props.change(data.value, props.options.filter((aItem) => aItem.value === data.value)[0]?.text ?? '')
                }
            })}/>
    )
}
