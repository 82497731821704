import React from "react";
import {Button, Modal} from "semantic-ui-react";
import {ApiRequest} from "../utils/ApiRequest"
import Password from "./Password";
import {useAppDispatch, useAppSelector} from "../utils/store/store";
import {getFCMToken, updateUser, updateWebToken} from "../utils/store/userSlice";
import Utils from "../utils/utils/Utils";

export default function Login() {
    const dispatch = useAppDispatch()
    const fcmToken = useAppSelector(getFCMToken)

    const [saving, setSaving] = React.useState(false)
    const [showVerify, setShowVerify] = React.useState(false)
    const [error, setError] = React.useState("")
    const [credential, setLoginData] = React.useState({email: "", password: ""})

    const login_user = () => {
        setSaving(true)

        ApiRequest.login_user({credential: credential.email, login_password: credential.password, fcm_token: fcmToken.token})
            .then((response) => {
                setSaving(false)
                if (response.data.hasOwnProperty("code")) {
                    const code: number = response.data.code
                    if (code === 1) {
                        setLoginData({...credential, password: ""})
                        dispatch(updateUser({
                            roles: response.data.user['user_roles'],
                            user_id: response.data.user.user_id,
                            user_name: `${response.data.user.first_name} ${response.data.user.last_name}`,
                            bearer_token: response.data.user.bearer_token,
                            branch_id: response.data.user.branch_id,
                            mobile_contact: response.data.user.mobile_contact
                        }))
                    } else if (code === 2) {
                        setError("Please make sure you have provided email or contact or username and password")
                    } else if (code === 3) {
                        setError("Invalid credentials, please check your credentials and retry")
                    } else if (code === 4) {
                        dispatch(updateWebToken(response.data.bearer_token))
                        setShowVerify(true)
                    }
                } else {
                    setError("Could not login, please retry")
                }
            })
            .catch((error) => {
                console.error(error)
                setSaving(false)
                setError("Could not login, please retry")
            })
    }


    return (
        <>
            <Password close={() => setShowVerify(false)} open={showVerify}/>

            <div className="login_window">
                <Modal size='mini' open={true} centered={true} closeOnDimmerClick={false} closeOnEscape={false}>
                    <div className="ui large form login_form">
                        <div className="ui stacked secondary segment">
                            <div className="login_logo">
                                <img src={Utils.company_data().logo} alt=""/>
                            </div>

                            <div className="field">
                                <div className="ui left icon input">
                                    <i className="user icon"/>
                                    <input type="text" placeholder="Enter username" value={credential.email}
                                           onChange={(event) => setLoginData({...credential, 'email': event.target.value})}/>
                                </div>
                            </div>
                            <div className="field">
                                <div className="ui left icon input">
                                    <i className="lock icon"/>
                                    <input type="password" placeholder="Login password" value={credential.password}
                                           autoComplete="autocomplete_off_hack_xfr4!k"
                                           onChange={(event) => setLoginData({...credential, 'password': event.target.value})}
                                           onKeyDown={(event: any) => {
                                               if (event.keyCode === 13) {
                                                   login_user()
                                               }
                                           }}
                                    />
                                </div>
                            </div>

                            <div className="row m-0">
                                <div className="col-6 offset-3 pl-0 pr-1">
                                    <Button className="ui button fluid primary" size='small' loading={saving}
                                            disabled={saving} onClick={login_user} content="Proceed Login"/>
                                </div>
                            </div>

                        </div>
                        <div className={`ui error message ${error === "" ? '' : 'show'}`}>{error}</div>
                    </div>
                </Modal>
            </div>
        </>
    )
}
