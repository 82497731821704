import React from "react";
import {Button, Confirm, Table} from "semantic-ui-react";
import {MySelectMulti} from "../../../utils/components/MySelectMulti";
import Utils from "../../../utils/utils/Utils";
import {ApiRequest} from "../../../utils/ApiRequest";
import {ToastsStore} from "react-toasts"
import EditSample from "./utils/EditSample";
import JSPrinter from "../../../utils/JSPrinter"
import {MyDateInput} from "../../../utils/components/MyDateInput";
import {Loading} from "../../../utils/components/Loading";
import {MyInput} from "../../../utils/components/MyInput";
import {MatchedUser} from "../../../nav/SideBar";
import {ClientInfo, ConfirmAction, initialConfirm} from "../../../utils/utils/Models";
import ClientDisplay from "../../clients/ClientDisplay";
import avatar from "../../../assets/images/avatar.png";
import {useAppSelector} from "../../../utils/store/store";
import {getUser} from "../../../utils/store/userSlice";
import {Roles} from "../../settings/UserRoles";

type Results = {
    value: string | number
    desc: string
}

interface Sample {
    bill_id: number
    category_name: string
    client_id: number
    client_name: string
    results: string
    results_desc: string
    sample_id: number
    sample_no: string
    sample_status: "Pending" | "Sampling" | "Processing" | "Results" | "Verified"
    test_abbr: string
    test_id: number
    test_name: string
}

export interface SampleTest {
    results_type: 'Connected' | 'Distinct' | 'Custom'
    results_values: Results[]
    test_id: number
}

export default function SampleList(params: { type: 'lab' | 'radiology', matchedUser: MatchedUser }) {
    const user = useAppSelector(getUser)
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initialConfirm)
    const [loading, setLoading] = React.useState({show: false, message: ""})

    const [search, setSearch] = React.useState({status: Array<string>(), min_date: Utils.today(), max_date: Utils.today(), name: ''})
    const handle_search = (name: string, value: string | string[]) => {
        setSearch({...search, [name]: value})
    }

    const [samples, setSamples] = React.useState(Array<Sample>())
    const [tests, setTests] = React.useState(Array<SampleTest>())
    const [clients, setClients] = React.useState(Array<ClientInfo>())
    const [clientInfo, setClientInfo] = React.useState<ClientInfo | undefined>(undefined)

    const load_samples = (client_id?: number) => {
        setLoading({show: true, message: "Loading samples, please wait"})
        ApiRequest.get_lab_samples({
            status: JSON.stringify(search.status), min_date: search.min_date, client_id: client_id,
            max_date: search.max_date, name: search.name.trim(), type: params.type
        })
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setTests(response.data.tests)
                    setSamples(response.data.samples)
                    setClients(response.data.clients)
                } else {
                    ToastsStore.error("Error while loading samples, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while loading samples, please retry")
            })
    }

    const [editSample, setEditSample] = React.useState({
        sample_id: 0, bill_id: 0, test_name: '', results: '', results_desc: '', test_id: 0, sample_no: ''
    })

    React.useEffect(() => {
        if (params.matchedUser.client_id !== 0) {
            load_samples(params.matchedUser.client_id)
        }
    }, [params.matchedUser.time])

    const get_action = (aSample: Sample) => {

        const proceed_update = (status: "Sampling" | "Processing" | "Verified") => {
            const content = status === "Sampling" ?
                "Are you sure you want to proceed taking this sample" :
                (status === "Processing" ?
                        "Are you sure you want to proceed processing this sample?" :
                        "Are you sure you want to verify this sample"
                )

            setConfirm({
                ...confirm, open: true, content: content,
                onConfirm: () => {
                    setConfirm({...confirm, open: false})
                    setLoading({message: "Updating sample status, please wait", show: true})

                    ApiRequest.update_sample_status({sample_id: aSample.sample_id, status: status})
                        .then((response) => {
                            setLoading({message: "", show: false})
                            if (response.data.hasOwnProperty("code")) {
                                switch (response.data.code) {
                                    case 1:
                                        ToastsStore.success("Sample status has been updated successfully")
                                        setSamples(samples.map((bSample) => aSample === bSample ?
                                            {...bSample, sample_status: status} : bSample))
                                        break;
                                    case 2:
                                        ToastsStore.error(response.data.message)
                                        break;
                                }
                            } else {
                                ToastsStore.error("Error while verifying sample results, please retry")
                            }
                        })
                        .catch(() => {
                            setLoading({message: "", show: false})
                            ToastsStore.error("Error while verifying sample results, please retry")
                        })
                },
                onCancel: () => setConfirm({...confirm, open: false})
            })
        }

        if (params.type === "lab") {
            if (aSample.sample_status === "Pending") {
                return (
                    <Button
                        primary size='mini' content={"Collect Sample"}
                        onClick={() => proceed_update("Sampling")} style={{width: '130px'}}
                        compact disabled={!Utils.has_roles(user.roles, [Roles.take_lab_samples])}/>
                )
            } else if (aSample.sample_status === "Sampling") {
                return (
                    <Button
                        primary size='mini' content={"Receive Sample"}
                        onClick={() => proceed_update("Processing")} style={{width: '130px'}}
                        compact disabled={!Utils.has_roles(user.roles, [Roles.process_lab_samples])}/>
                )
            } else if (aSample.sample_status === "Processing") {
                return (
                    <Button
                        primary size='mini' content={"Add Results"} compact style={{width: '130px'}}
                        disabled={!Utils.has_roles(user.roles, [Roles.sample_results])}
                        onClick={() => {
                            setEditSample({
                                sample_id: aSample.sample_id, bill_id: aSample.bill_id, test_name: aSample.test_name,
                                results: aSample.results, results_desc: aSample.results_desc, test_id: aSample.test_id,
                                sample_no: aSample.sample_no
                            })
                        }}/>
                )
            } else if (aSample.sample_status === "Results") {
                return (
                    <Button
                        primary size='mini' compact style={{width: '130px'}}
                        disabled={!Utils.has_roles(user.roles, [Roles.verify_samples])} content={"Verify"}
                        onClick={() => proceed_update("Verified")}/>
                )
            } else if (aSample.sample_status === "Verified") {
                return (<Button primary size='mini' compact content={"Completed"} style={{width: '130px'}}/>)
            }
        } else {
            if (aSample.sample_status === "Pending") {
                return (
                    <Button
                        primary size='mini' content={"Start Process"}
                        onClick={() => proceed_update("Processing")} style={{width: '130px'}}
                        compact disabled={!Utils.has_roles(user.roles, [Roles.radiology_testing])}/>
                )
            } else if (aSample.sample_status === "Processing") {
                return (
                    <Button
                        primary size='mini' content={"Add Results"} compact style={{width: '130px'}}
                        disabled={!Utils.has_roles(user.roles, [Roles.radiology_results])}
                        onClick={() => {
                            setEditSample({
                                sample_id: aSample.sample_id, bill_id: aSample.bill_id, test_name: aSample.test_name,
                                results: aSample.results, results_desc: aSample.results_desc, test_id: aSample.test_id,
                                sample_no: aSample.sample_no
                            })
                        }}/>
                )
            } else if (aSample.sample_status === "Results") {
                return (
                    <Button
                        primary size='mini' content={"Verify Results"} style={{width: '130px'}}
                        compact disabled={!Utils.has_roles(user.roles, [Roles.verify_radiology_samples])}
                        onClick={() => proceed_update("Verified")}/>
                )
            } else if (aSample.sample_status === "Verified") {
                return (<Button primary size='mini' compact content={"Completed"} style={{width: '130px'}}/>)
            }
        }
        return (<></>)
    }

    return (
        <>
            <ClientDisplay close={() => setClientInfo(undefined)} client={clientInfo}/>

            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm}
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton}
                     size='mini' content={confirm.content}/>

            <EditSample
                sample_id={editSample.sample_id} bill_id={editSample.bill_id} test_name={editSample.test_name}
                sample_no={editSample.sample_no} results={editSample.results} results_desc={editSample.results_desc}
                test_id={editSample.test_id} tests={tests}
                close={() => setEditSample({...editSample, sample_id: 0})}
                update={(results: string, results_desc: string) => {
                    setSamples(samples.map((sample) => editSample.sample_id === sample.sample_id
                        ? {...sample, results, results_desc, sample_status: 'Results'} : sample))
                    setEditSample({...editSample, sample_id: 0})
                }}/>

            <div className="content_bar">
                <div className="search_bar">
                    <div>
                        <MySelectMulti
                            multiple={true} placeholder="Sample status" selected={search.status}
                            change={(value) => handle_search('status', value as string[])}
                            items={[
                                {text: 'Samples without results', value: 'Pending'},
                                {text: 'Taking samples', value: 'Sampling'},
                                {text: 'Samples in lab process', value: 'Processing'},
                                {text: 'Samples with results only', value: 'Results'},
                                {text: 'Only verified samples', value: 'Verified'}]
                            }/>
                    </div>
                    <div>
                        <MyDateInput
                            value={search.min_date} name="min_date" placeholder="Min Date"
                            change={handle_search} maxDate={search.max_date}/>
                    </div>

                    <div>
                        <MyDateInput
                            value={search.max_date} name="max_date" placeholder="Min Date"
                            change={handle_search} minDate={search.min_date} maxDate={Utils.today()}/>
                    </div>

                    <div style={{width: '250px'}}>
                        <MyInput
                            placeholder="Search by name, contact, email or bill no"
                            name="name" value={search.name} change={handle_search} enter={() => load_samples()}/>
                    </div>
                </div>

                <div className="content_buttons">
                    <Button primary size='mini' icon='search' labelPosition="left" content="Load Samples"
                            onClick={() => load_samples()}/>
                </div>
            </div>

            <div className="content_container">
                <div className="window_content">
                    <Table celled striped compact={false} size='small' inverted color='grey' selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '220px'}} textAlign="center"/>
                                <Table.HeaderCell style={{width: '40px'}} textAlign="center">No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '70px'}}/>
                                <Table.HeaderCell style={{width: '100px'}}>Client No</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '150px'}}>Client Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '120px'}}>Sample No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '150px'}}>Test Category</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '150px'}}>Test Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '100px'}}>Status</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '90px'}}>Results</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '300px'}}>Results Description</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                samples.map((_sample, index) => {
                                        const client: ClientInfo | undefined = clients.filter((aClient) => aClient.id === _sample.client_id)[0]
                                        return (
                                            <Table.Row key={_sample.sample_id}>
                                                <Table.Cell style={{width: '220px'}}>
                                                    <Button
                                                        primary size='mini' icon='barcode' compact
                                                        onClick={() => JSPrinter.print_barcode(_sample.sample_no)}/>
                                                    {get_action(_sample)}
                                                </Table.Cell>

                                                <Table.Cell style={{width: '40px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                                <Table.Cell style={{width: '70px'}}>
                                                    <img
                                                        onClick={() => setClientInfo(client)} className={"table_avatar"} alt="Passport"
                                                        src={`${(client === undefined || client.dp === '') ? avatar : `${Utils.BASE_URL}${client.dp}`}`}/>
                                                </Table.Cell>
                                                <Table.Cell style={{width: '100px'}}>{client.no}</Table.Cell>
                                                <Table.Cell style={{width: '150px'}}>{_sample.client_name}</Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>{_sample.sample_no}</Table.Cell>
                                                <Table.Cell style={{width: '150px'}}>{_sample.category_name}</Table.Cell>
                                                <Table.Cell
                                                    style={{width: '150px'}}>{`${_sample.test_name} (${_sample.test_abbr})`}</Table.Cell>
                                                <Table.Cell style={{width: '100px'}}>{_sample.sample_status}</Table.Cell>
                                                <Table.Cell style={{width: '90px'}}>{_sample.results}</Table.Cell>
                                                <Table.Cell style={{width: '300px'}}>{_sample.results_desc}</Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                )
                            }
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </>
    )
}
