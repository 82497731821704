import React from "react";
import {Button, Table} from "semantic-ui-react";
import Utils from "../../../utils/utils/Utils";
import {ToastsStore} from "react-toasts";
import {ApiRequest} from "../../../utils/ApiRequest";
import ModalTest, {initial_test, Test} from "./ModalTest";
import ModalCategory, {Category, initial_category} from "./ModalCategory";
import {Loading} from "../../../utils/components/Loading";

export default function LabCatalogue(params: { type: 'lab' | 'radiology' }) {
    const [loading, setLoading] = React.useState({show: false, message: ""})

    const [categories, setCategories] = React.useState(Array<Category>())
    const [categoryID, setCategoryID] = React.useState(0)
    const [showCategory, setShowCategory] = React.useState(false)
    const [category, setCategory] = React.useState({...initial_category, category_type: params.type})

    const [tests, setTests] = React.useState(Array<Test>())
    const [showTest, setShowTest] = React.useState(false)
    const [test, setTest] = React.useState(initial_test)

    const load_catalogue = () => {
        setLoading({show: true, message: "Loading catalogue, please wait"})
        ApiRequest.get_list_catalogue({category_type: params.type})
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setCategories(response.data.categories)
                    setTests(response.data.tests)
                    ToastsStore.success("Tests updated successfully")
                } else {
                    ToastsStore.error("Error while loading catalogue, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while loading catalogue, please retry")
            })
    }

    const print_catalogue = () => {
        setLoading({show: true, message: "Printing catalogue, please wait"})
        ApiRequest.print_lab_catalogue()
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {

                } else {
                    ToastsStore.error("Error while printing catalogue, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while printing catalogue, please retry")
            })
    }

    React.useEffect(() => {
        load_catalogue()
        setCategory({...category, category_type: params.type})
    }, [params.type])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            <ModalCategory
                show={showCategory} hide={() => setShowCategory(false)} category={category}
                update={(_category) => {
                    if (category.category_id === 0) {
                        setCategories([...categories, _category])
                    } else {
                        setCategories(categories.map((__cat) => __cat.category_id === _category.category_id ? _category : __cat))
                    }
                    setCategory(_category)
                }}/>

            <ModalTest
                test={test} show={showTest} close={() => setShowTest(false)} categories={categories}
                update={(_test) => {
                    if (test.test_id === 0) {
                        setTests([...tests, _test])
                    } else {
                        setTests(tests.map((__test) => __test.test_id === _test.test_id ? _test : __test))
                    }
                    setTest(_test)
                }}/>

            <div className="content_bar">
                <div className="search_bar"/>

                <div className="content_buttons">
                    <Button primary size='mini' icon='add' labelPosition="left" disabled={categories.length === 0}
                            content="Add Exam" onClick={() => setShowTest(true)}/>

                    <Button primary size='mini' icon='add' labelPosition="left" content="Add Category"
                            onClick={() => setShowCategory(true)}/>

                    <Button primary size='mini' icon='print' labelPosition="left" content="Print Catalogue" onClick={print_catalogue}/>

                    <Button primary size='mini' icon='search' labelPosition="left" content="Load Catalogue" onClick={load_catalogue}/>
                </div>
            </div>

            <div className="content_container">
                <div className="row h-100 m-0">
                    <div className="col-12 col-md-3 p-1 h-100">
                        <div className="window_content">
                            <Table celled striped compact={false} size='small' inverted color='grey' selectable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '50px'}} textAlign="center"/>
                                        <Table.HeaderCell>Category Name</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        categories.map((_category, index) =>
                                            <Table.Row key={_category.category_id} onClick={() => setCategoryID(_category.category_id)}>
                                                <Table.Cell style={{width: '50px'}}
                                                            textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                                <Table.Cell style={{width: '50px'}} textAlign="center">
                                                    <Button
                                                        primary size='mini' icon='edit' compact
                                                        onClick={() => {
                                                            setShowCategory(true)
                                                            setCategory(_category)
                                                        }}/>
                                                </Table.Cell>
                                                <Table.Cell>{_category.category_name}</Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                    </div>

                    <div className="col-12 col-md-9 p-1 h-100">
                        <div className="window_content">
                            <Table celled striped compact={false} size='small' inverted color='grey' selectable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '30px'}} textAlign="center">No.</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '250px'}}>Test Name</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '100px'}}>Result Type</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '50px'}}>Units</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '70px'}} textAlign="center">Time (min)</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        tests
                                            .filter((_test) => _test.category_id === categoryID)
                                            .map((_test, index) =>
                                                <Table.Row
                                                    key={_test.test_id}
                                                    onClick={() => {
                                                        setTest(_test)
                                                        setShowTest(true)
                                                    }}>
                                                    <Table.Cell style={{width: '30px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                                    <Table.Cell style={{width: '250px'}}>
                                                        {`${_test.test_name}(${_test.test_abbr})`}
                                                    </Table.Cell>
                                                    <Table.Cell style={{width: '100px'}}>{_test.results_type}</Table.Cell>
                                                    <Table.Cell style={{width: '50px'}}>{_test.results_units}</Table.Cell>
                                                    <Table.Cell style={{width: '70px'}} textAlign="center">{_test.time_taken}</Table.Cell>
                                                </Table.Row>
                                            )
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
