import React from "react";
import {Input} from "semantic-ui-react";

export function MyInput(
    props: {
        change?: (name: string, value: string) => void, placeholder: string, name: string, value: string,
        disabled?: boolean, style?: React.CSSProperties, type?: string, enter?: () => void, className?: string
    }) {
    return (
        <Input
            placeholder={props.placeholder} fluid size="mini" name={props.name} value={props.value}
            disabled={props.disabled !== undefined && props.disabled}
            style={props.style} type={props.type} className={props.className}
            onChange={((event, data) => {
                if (props.change !== undefined) {
                    props.change(event.target.name, data.value)
                }
            })}
            onKeyDown={(event: KeyboardEvent) => {
                if (event.key === 'Enter' && props.enter !== undefined) {
                    props.enter()
                }
            }}/>
    )
}
