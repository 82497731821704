import moment from "moment";
import {CompanyInfo, Fetch, InputFile, SaleOrder} from "./Models";

import pharma_logo from "../../assets/images/logos/tena.png"
import medsafe_logo from "../../assets/images/logos/medsafe.png"
import {Table} from "semantic-ui-react";
import React from "react";

export default class Utils {
    static COMPANY: "PHARMA" | "MEDSAFE" | "TEST" = "MEDSAFE"

    static company_data = (): CompanyInfo => {
        //const image: {} = medsafe_logo
        if (Utils.COMPANY === "PHARMA") {
            return {logo: pharma_logo, url: 'https://labapi.tennaandpharmalab.com/'}
        } else if (Utils.COMPANY === "MEDSAFE") {
            return {logo: medsafe_logo, url: 'https://hospital.api.medsafehospital.com/'}
        }
        return {logo: medsafe_logo, url: 'http://127.0.0.1:8001/'}
    }

    static BASE_URL = Utils.company_data().url

    static today = (initial?: Date) => {
        if (initial === undefined) {
            initial = new Date()
        }
        const year = initial.getFullYear()
        let month = initial.getMonth() + 1
        const date = initial.getDate()
        return `${year}-${month < 10 ? "0" + month : month}-${date < 10 ? "0" + date : date}`
    }

    static has_center_role(params: { roles: any, role: string }) {
        return params.roles.hasOwnProperty(params.role) && params.roles[params.role] === 1
    }


    static format_post_params(data: any) {
        const form = new FormData();
        Object.entries(data).forEach(([prop, val]) => {
            if (val !== null) {
                if (val instanceof InputFile) {
                    console.log(val)
                    if (val.file !== undefined) {
                        form.append(prop, val.file as File)
                    }
                } else {
                    form.append(prop, val as string)
                }
            }
        })
        return form
    }

    public static in_valid_email(email: string): boolean {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return !regex.test(email);
    }

    public static invalid_name(name: string): boolean {
        //const regex = /^[a-zA-Z]+(?:-[a-zA-Z]+)*$/;
        const regex = /^([a-zA-Z ]{2,100})$/;
        return !regex.test(name);
    }

    public static invalid_user_name(name: string): boolean {
        const regex = /^[a-zA-Z]{2,30}$/;
        return !regex.test(name);
    }

    public static invalid_text(name: string): boolean {
        //const regex = /^[a-zA-Z0-9 ]{2,500}$/;
        //return !regex.test(name);
        return name.length < 5
    }

    static has_roles = (roles: string[], check: string[]) => {
        for (let index = 0; index < check.length; index++) {
            if (roles.includes(check[index])) {
                return true
            }
        }
        return false
    }

    public static invalid_contact(contact: string): boolean {
        const formatted = contact.toString().replace(/\D/g, '').toString()
        if (formatted.indexOf("256") === 0 && formatted.length !== 12) {
            return true
        }
        return formatted.length < 6
    }

    public static localise_date(string: string): string {
        if (string === "") {
            return ""
        }
        return moment(string, "YYYY-MM-DD").format("ddd, DD MMM YYYY")
    }

    public static localise_date_time(string: string): string {
        if (string === "") {
            return ""
        }
        return moment(string, "YYYY-MM-DD hh:mm:ss").format("ddd, DD MMM YYYY hh:mm A")
    }

    public static is_valid_number(value: string): boolean {
        return ((value != null) && (value !== '') && !isNaN(Number(value.toString())));
    }

    public static comma_number(value: number, pre?: string, post?: string): string {
        const prefix = pre === undefined ? '' : pre
        const postfix = post === undefined ? '' : post
        return prefix + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + postfix
    }

    static row_number(index: number, fetch?: Fetch) {
        let number = (index + 1)
        if (fetch !== undefined) {
            number = (index + 1) + (fetch.limit * (fetch.page - 1))
        }
        return number
    }
}
