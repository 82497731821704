import {ToastsStore} from "react-toasts";
import React from "react";
import {Loading} from "../../../../../utils/components/Loading";
import StockTypes from "../StockTypes";
import {Stock, StockType, StockUnit, Supplier} from "../../../../../utils/utils/Models";
import {Button, Modal} from "semantic-ui-react";
import {MyInput} from "../../../../../utils/components/MyInput";
import {MySelect} from "../../../../../utils/components/MySelect";
import Utils from "../../../../../utils/utils/Utils";
import {ApiRequest} from "../../../../../utils/ApiRequest";
import StockSuppliers from "./StockSuppliers";
import StockUnits from "../StockUnits";

export const initialStock: Stock = {
    current_quantity: 0, generic_name: "", stock_name: "", stock_type: 0, unit_id: 0,
    threshold_quantity: '', stock_id: 0, sale_price: 0
}

export default function EditStock(params: {
    show: boolean, stock: Stock, suppliers: Supplier[], stockTypes: StockType[], stockUnits: StockUnit[], close: () => void,
    updateStock: (stocks: Stock, isNew: boolean) => void,
    updateStockUnits: (stockUnits: StockUnit[]) => void,
    updateStockTypes: (stockTypes: StockType[]) => void,
    updateStockSuppliers: (suppliers: Supplier[]) => void
}) {
    const [loading, setLoading] = React.useState({show: false, message: ""})
    const [showTypes, setShowTypes] = React.useState(false)
    const [showUnits, setShowUnits] = React.useState(false)
    const [showSuppliers, setShowSuppliers] = React.useState(false)

    const [stock, setStock] = React.useState(params.stock)
    const handle_change = (name: string, value: string | number) => {
        setStock({...stock, [name]: value})
    }

    const save_stock = () => {
        if (stock.stock_name.trim().length < 2) {
            ToastsStore.error('Enter a valid stock name')
        } else if (stock.generic_name.trim().length < 2) {
            ToastsStore.error('Enter a valid stock generic name')
        } else if (stock.stock_type === 0) {
            ToastsStore.error('Select type of stock')
        } else if (!Utils.is_valid_number(stock.sale_price.toString().trim()) || parseFloat(stock.sale_price.toString().trim()) < 0) {
            ToastsStore.error("Enter valid stock sale price")
        } else if (!Utils.is_valid_number(stock.threshold_quantity.toString().trim())) {
            ToastsStore.error("Enter valid low stock threshold")
        } else {
            setLoading({show: true, message: 'Saving stock, please wait'})
            ApiRequest.save_stock({
                stock_id: stock.stock_id,
                stock_name: stock.stock_name,
                generic_name: stock.generic_name.toString(),
                unit_id: stock.unit_id,
                stock_type: stock.stock_type,
                threshold_quantity: parseFloat(stock.threshold_quantity.toString().trim()),
                sale_price: parseFloat(stock.sale_price.toString().trim())
            })
                .then((response) => {
                    setLoading({message: "", show: false})
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        params.updateStock({...stock, stock_id: response.data['stock_id']}, stock.stock_id === 0)
                        setStock({...stock, stock_id: response.data['stock_id']})
                        ToastsStore.success("Stock saved successfully")
                    } else {
                        ToastsStore.error("Error while saving stock supplier, please retry")
                    }
                })
                .catch(() => {
                    setLoading({message: "", show: false})
                    ToastsStore.error("Error while saving stock supplier, please retry")
                })
        }
    }

    React.useEffect(() => {
        setStock(params.stock)
    }, [params.show, params.stock])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            <StockSuppliers show={showSuppliers} close={() => setShowSuppliers(false)}
                            suppliers={params.suppliers} setSuppliers={params.updateStockSuppliers}/>

            <StockTypes
                show={showTypes} category={'Pharmacy'} close={() => setShowTypes(false)} types={params.stockTypes}
                setStockTypes={params.updateStockTypes}/>

            <StockUnits show={showUnits} units={params.stockUnits} close={() => setShowUnits(false)} setUnits={params.updateStockUnits}/>

            <Modal open={params.show} basic size='mini' centered={false} closeOnDimmerClick={false} closeOnDocumentClick={false}>
                <div className="modal_container">
                    <div className="form_container">
                        <span className="label mt-0">Item Name</span>
                        <MyInput placeholder="Enter item name" name="stock_name" value={stock.stock_name} change={handle_change}/>

                        <span className="label">Common Name</span>
                        <MyInput placeholder="Enter common name" name="generic_name" value={stock.generic_name} change={handle_change}/>

                        {/*STOCK TYPE*/}
                        <span className="label">Stock Type</span>
                        <div style={{display: 'flex'}}>
                            <div style={{display: 'flex', flex: 1, marginRight: '10px'}}>
                                <MySelect
                                    name="category_id" value={stock.stock_type} placeholder="Select stock type"
                                    change={(value) => handle_change('stock_type', value as string)}
                                    options={[{text: "Select stock type", value: 0}, ...params.stockTypes.map((_) => ({text: _.type_name, value: _.type_id}))]}/>
                            </div>
                            <div style={{display: 'flex'}}>
                                <Button primary size='mini' icon='add' compact onClick={() => setShowTypes(true)}/>
                            </div>
                        </div>

                        {/*STOCK UNITS*/}
                        <span className="label">Item Units</span>
                        <div style={{display: 'flex'}}>
                            <div style={{display: 'flex', flex: 1, marginRight: '10px'}}>
                                <MySelect
                                    name="category_id" value={stock.unit_id} placeholder="Select a stock unit"
                                    change={(value) => handle_change('unit_id', value as string)}
                                    options={params.stockUnits.map((stockUnit) => ({text: stockUnit.unit_name, value: stockUnit.unit_id}))}/>
                            </div>
                            <div style={{display: 'flex'}}>
                                <Button primary size='mini' icon='add' compact onClick={() => setShowUnits(true)}/>
                            </div>
                        </div>

                        <span className="label">Unit Sale Price</span>
                        <MyInput placeholder="Enter sale price per item" name="sale_price" value={stock.sale_price.toString()} change={handle_change}/>

                        <span className="label">Threshold Quantity</span>
                        <MyInput placeholder="Enter threshold" name="threshold_quantity" value={stock.threshold_quantity.toString()} change={handle_change}/>

                        <span className="label">Available Quantity</span>
                        <span className="label_text">{stock.current_quantity.toLocaleString()}</span>
                    </div>

                    <div className="button_container">
                        <div className="row m-0">
                            <div className="col-2 pl-0 pr-1">
                                <Button negative icon="close" size="mini" fluid onClick={params.close}/>
                            </div>
                            <div className="col-5 px-0">
                                <Button negative icon="trash" labelPosition="left" size="mini" fluid content={'Clear Data'}
                                        onClick={() => setStock(initialStock)}/>
                            </div>
                            <div className="col-5 pl-1 pr-0">
                                <Button positive icon="save" labelPosition="left" size="mini" fluid
                                        content="Save Stock" onClick={save_stock}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
