import {ChangeEvent} from "react";
import {InputFile} from "./Models";

export async function readInput(event: ChangeEvent<HTMLInputElement>) {
    return new Promise<InputFile>((resolve, reject) => {
        const files = event.target.files;
        if (files !== null && files.length > 0) {
            const file: File | null = files.item(0);
            const reader = new FileReader();
            reader.readAsDataURL(file as File);
            reader.onload = function (event) {
                const target = event.target;
                if (target !== null) {
                    resolve(new InputFile(target.result as string, file as File))
                } else {
                    reject(null)
                }
            };
            reader.onerror = error => reject(error);
        } else {
            reject(null)
        }
    });
}
