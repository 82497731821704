import React from "react";
import {Button, Modal} from "semantic-ui-react"
import {ApiRequest} from "../utils/ApiRequest"
import {ToastsStore} from "react-toasts"
import {getUser} from "../utils/store/userSlice";
import {useAppSelector} from "../utils/store/store";
import Utils from "../utils/utils/Utils";

export default function Password(params: { close: () => void, open: boolean }) {
    const user = useAppSelector(getUser)

    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState("")
    const [credential, setLoginData] = React.useState({confirm: "", password: "", current: ""})

    const verify_account = () => {
        if (credential.password.length < 6) {
            setError("Password must be at least 6 characters")
        } else if (credential.password !== credential.confirm) {
            setError("Passwords do not match")
        } else if (credential.current === credential.confirm) {
            setError("Old and current passwords cannot be the same")
        } else if (credential.confirm === user.mobile_contact) {
            setError("Password cannot be the same as your contact")
        } else {
            setError("")
            setLoading(true)

            ApiRequest.change_password({old_password: credential.current, new_password: credential.confirm})
                .then((response) => {
                    setLoading(false)
                    if (response.data.hasOwnProperty("code")) {
                        const code: number = response.data.code
                        if (code === 1) {
                            ToastsStore.success("Password updated successfully")
                            params.close()
                        } else if (code === 2) {
                            setError("Employee account not be found, please enter valid current credentials")
                        }
                    } else {
                        setError("We could not update account password, please retry")
                    }
                })
                .catch(() => {
                    setLoading(false)
                    setError("We could not update account password, please retry")
                })
        }
    }

    return (
        <Modal size='mini' open={params.open} centered={true} closeOnDimmerClick={false} closeOnEscape={false}
               closeOnDocumentClick={false} closeOnTriggerBlur={false}>
            <div className="ui large form login_form">
                <div className="ui stacked secondary segment">
                    <div className="login_logo">
                        <img src={Utils.company_data().logo} alt=""/>
                    </div>
                    <div className="field">
                        <div className="ui left icon input">
                            <i className="lock icon"/>
                            <input
                                type="password" placeholder="Create account password" value={credential.password}
                                onChange={(event) => setLoginData({...credential, password: event.target.value})}/>
                        </div>
                    </div>
                    <div className="field">
                        <div className="ui left icon input">
                            <i className="lock icon"/>
                            <input
                                type="password" placeholder="Confirm account password" value={credential.confirm}
                                onChange={(event) => setLoginData({...credential, confirm: event.target.value})}
                                onKeyDown={(event: any) => {
                                    if (event.keyCode === 13) {
                                        verify_account()
                                    }
                                }}/>
                        </div>
                    </div>

                    <div className="row m-0">
                        <Button
                            className="ui button fluid primary" size='mini' loading={loading}
                            disabled={loading} onClick={() => verify_account()} content="Update Password"/>
                    </div>
                </div>
                <div className={`ui error message ${error === "" ? '' : 'show'}`}>{error}</div>
            </div>
        </Modal>
    )
}
