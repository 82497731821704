import {SyntheticEvent} from "react";

export interface Fetch {
    start: number
    limit: number
    total: number
    page: number
    pages: number
    load?: boolean
}

export class InputFile {
    file_src: string;
    file: File | undefined;

    constructor(file_path: string, file?: File) {
        this.file_src = file_path;
        this.file = file
    }
}

export interface CompanyInfo {
    logo: string
    url: string
}

export interface ConfirmAction {
    open: boolean
    cancelButton: string
    confirmButton: string
    content: string
    onConfirm?: (event?: SyntheticEvent, data?: object) => void
    onCancel?: (event?: SyntheticEvent, data?: object) => void
}

export const initialConfirm: ConfirmAction = {cancelButton: "Cancel", confirmButton: "Proceed", content: "", open: false}


export interface SelectData {
    text: string
    value: string | number
}

export type selectType = string | number | boolean | (string | number | boolean)[] | undefined

export interface ClientInfo {
    id: number
    name: string
    no: string
    dp: string
    age: number
    dob: string
    phone: string
    id_: string
    gender: string
    company: number
}

export interface SystemUser {
    user_id: number
    branch_id: number
    username: string
}

export interface Stock {
    stock_id: number
    unit_id: number
    stock_type: number
    stock_name: string
    generic_name: string
    threshold_quantity: string | number
    current_quantity: number,
    sale_price: string | number
}

export type StockType = {
    type_id: number
    type_name: string
    type_category: 'Pharmacy'
}

export type StockUnit = {
    unit_id: number
    unit_name: string
}


export interface Supplier {
    supplier_id: number
    supplier_name: string
    contact: string
    email: string
    address: string
}

export interface StockSupply {
    supply_id: number
    supplier_id: number
    user_id: number
    bill_no: string
    bill_date: string
    username: string
    supply_items: SupplyItem[]
    supply_cost: number | string
    profit_margin: number
    supply_date: string
    time_created: string
    time_updated: string
    supply_balance: number | string
    payment_type: 'Cash' | 'Credit'
}

export interface SupplyItem {
    stock_id: number
    stock_name: string
    purchase_price: number | string
    purchase_quantity: number | string
    profit_margin: number | string
    min_sale_price: number | string
    sale_price: number | string
}

export interface SaleOrder {
    sale_id: number
    ordered_by: number
    username: string
    items: SaleOrderItem[]
    total_price: number | string
    total_discount: number | string
    client_id: number
    client_name: string
    sale_type: 'Cash' | 'Credit' | 'Insurance'
    time_ordered: string
}

export interface SaleOrderItem {
    name: string
    stock_id: number
    quantity: number | string
    unit_price: number
    available: number | undefined
}
