import React from "react";
import {Button, Table} from "semantic-ui-react";
import Utils from "../../../utils/utils/Utils";
import {ApiRequest} from "../../../utils/ApiRequest";
import {ToastsStore} from "react-toasts";
import {DisplayPDF} from "../../../utils/components/DisplayPDF";
import {MyDateInput} from "../../../utils/components/MyDateInput";
import {Loading} from "../../../utils/components/Loading";
import {MyInput} from "../../../utils/components/MyInput";
import {MatchedUser} from "../../../nav/SideBar";
import JSPrinter from "../../../utils/JSPrinter";
import {ClientInfo} from "../../../utils/utils/Models";
import ClientDisplay from "../ClientDisplay";
import avatar from "../../../assets/images/avatar.png";
import MessageContent from "../messaging/MessageContent";

interface Bill {
    bill_amount: number
    bill_id: number
    client_id: number
    client: string
    date_created: string
    discount: number
    group_name: string
    total_paid: number
    tests: { sample_id: number, sample_status: "Verified" | 'Pending' | 'Results', sample_no: string, test_name: string }[]
}

export default function ClientResults(params: { matchedUser: MatchedUser }) {
    const [loading, setLoading] = React.useState({show: false, message: ""})

    const [search, setSearch] = React.useState({min_date: Utils.today(), max_date: Utils.today(), name: ''})
    const handle_search = (name: string, value: string) => {
        setSearch({...search, [name]: value})
    }

    const [bills, setBills] = React.useState(Array<Bill>())
    const [clients, setClients] = React.useState(Array<ClientInfo>())
    const [clientInfo, setClientInfo] = React.useState<ClientInfo | undefined>(undefined)

    const load_results = (client_id?: number) => {
        setLoading({show: true, message: "Loading sample results, please wait"})
        ApiRequest.get_sample_results({
            min_date: search.min_date, max_date: search.max_date,
            name: search.name.trim(), client_id: client_id
        })
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setBills(response.data['bills'])
                    setClients(response.data.clients)
                } else {
                    ToastsStore.error("Error while loading sample results, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while loading sample results, please retry")
            })
    }

    const [showPDF, setShowPDF] = React.useState({show: false, url: ''})
    const print_report = (bill_id: number) => {
        setLoading({show: true, message: "Printing sample results, please wait"})
        ApiRequest.print_bill_results({bill_id: bill_id})
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setShowPDF({url: response.data.url, show: true})
                } else {
                    ToastsStore.error("Error while loading sample results, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while loading sample results, please retry")
            })
    }

    const [email, setEmail] = React.useState({show: false, bill_id: 0, message: ""})
    const email_results = (bill_id: number, message: string) => {
        setLoading({show: true, message: "Emailing sample results, please wait"})
        ApiRequest.email_bill_results({bill_id: bill_id, message: message})
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code")) {
                    if (response.data.code === 1) {
                        ToastsStore.success("Results have been sent successfully via email")
                    } else if (response.data.code === 2) {
                        ToastsStore.error("Client email is invalid")
                    }
                } else {
                    ToastsStore.error("Error while emailing sample results, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while loading emailing results, please retry")
            })
    }

    const print_sample_report = (sample_id: number) => {
        setLoading({show: true, message: "Printing sample results, please wait"})
        ApiRequest.print_sample_results({sample_id: sample_id})
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setShowPDF({url: response.data.url, show: true})
                } else {
                    ToastsStore.error("Error while loading sample results, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while loading sample results, please retry")
            })
    }

    const display_bill = (index: number, bill: Bill) => {
        const client: ClientInfo | undefined = clients.filter((aClient) => aClient.id === bill.client_id)[0]
        const balance = bill.bill_amount - bill.discount - bill.total_paid
        const rowSpan = bill.tests.length

        return bill.tests.map((test, _index) =>
            _index === 0 ?
                <Table.Row key={`${bill.bill_id}_${test.sample_id}`}>
                    <Table.Cell style={{width: '100px'}} textAlign="center" rowSpan={rowSpan}>
                        <Button
                            primary size='mini' icon='print' compact
                            disabled={balance > 0 || bill.tests.filter((aTest) => aTest.sample_status !== 'Verified').length > 0}
                            onClick={() => print_report(bill.bill_id)}/>
                        <Button
                            primary size='mini' icon='mail' compact
                            disabled={balance > 0 || bill.tests.filter((aTest) => aTest.sample_status !== 'Verified').length > 0}
                            onClick={() => setEmail({
                                show: true, bill_id: bill.bill_id, message: "Thanks for testing with Medsafe Hospital"
                            })}/>
                    </Table.Cell>
                    <Table.Cell style={{width: '40px'}} textAlign="center" rowSpan={rowSpan}>{Utils.row_number(index)}</Table.Cell>
                    <Table.Cell style={{width: '80px'}} textAlign="center" rowSpan={rowSpan}>{bill.bill_id}</Table.Cell>
                    <Table.Cell style={{width: '80px'}} textAlign="center" rowSpan={rowSpan}>
                        <img
                            onClick={() => setClientInfo(client)} className={"table_avatar"} alt="Passport"
                            src={`${(client === undefined || client.dp === '') ? avatar : `${Utils.BASE_URL}${client.dp}`}`}/>
                    </Table.Cell>
                    <Table.Cell style={{width: '160px'}} rowSpan={rowSpan}>{bill.client}</Table.Cell>
                    <Table.Cell style={{width: '100px'}} rowSpan={rowSpan}>{client?.no}</Table.Cell>
                    <Table.Cell style={{width: '180px'}} rowSpan={rowSpan}>{Utils.localise_date_time(bill.date_created)}</Table.Cell>
                    <Table.Cell style={{width: '130px'}} rowSpan={rowSpan}>{bill.group_name}</Table.Cell>
                    <Table.Cell style={{width: '90px'}}>
                        <Button
                            primary size='mini' icon='barcode' compact
                            onClick={() => JSPrinter.print_barcode(test.sample_no)}/>
                        <Button
                            primary size='mini' icon='print' compact disabled={balance > 0 || test.sample_status !== 'Verified'}
                            onClick={() => print_sample_report(test.sample_id)}/>
                    </Table.Cell>
                    <Table.Cell style={{width: '150px'}}>{test.test_name}</Table.Cell>
                    <Table.Cell style={{width: '130px'}}>{test.sample_no}</Table.Cell>
                    <Table.Cell style={{width: '90px'}}>{test.sample_status}</Table.Cell>

                    <Table.Cell style={{width: '100px'}} rowSpan={rowSpan}>{Utils.comma_number(bill.bill_amount)}</Table.Cell>
                    <Table.Cell style={{width: '100px'}} rowSpan={rowSpan}>{Utils.comma_number(balance, '')}</Table.Cell>
                </Table.Row>
                :
                <Table.Row key={`${bill.bill_id}_${test.sample_id}`}>
                    <Table.Cell style={{width: '90px'}}>
                        <Button
                            primary size='mini' icon='barcode' compact
                            onClick={() => JSPrinter.print_barcode(test.sample_no)}/>
                        <Button
                            primary size='mini' icon='print' compact disabled={balance > 0 || test.sample_status !== 'Verified'}
                            onClick={() => print_sample_report(test.sample_id)}/>
                    </Table.Cell>
                    <Table.Cell style={{width: '150px'}}>{test.test_name}</Table.Cell>
                    <Table.Cell style={{width: '130px'}}>{test.sample_no}</Table.Cell>
                    <Table.Cell style={{width: '90px'}}>{test.sample_status}</Table.Cell>
                </Table.Row>
        )
    }

    React.useEffect(() => {
        if (params.matchedUser.client_id !== 0) {
            load_results(params.matchedUser.client_id)
        }
    }, [params.matchedUser.time])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            <DisplayPDF show={showPDF.show} url={showPDF.url} close={() => setShowPDF({url: '', show: false})}/>

            <ClientDisplay close={() => setClientInfo(undefined)} client={clientInfo}/>

            <MessageContent
                show={email.show} message={email.message}
                close={(message) => {
                    if (message !== undefined) {
                        email_results(email.bill_id, message)
                    }
                    setEmail({show: false, message: "", bill_id: 0})
                }}/>

            <div className="content_bar">
                <div className="search_bar">
                    <div>
                        <MyDateInput
                            value={search.min_date} name="min_date" placeholder="Min Date"
                            change={handle_search} maxDate={search.max_date}/>
                    </div>

                    <div>
                        <MyDateInput
                            value={search.max_date} name="max_date" placeholder="Min Date"
                            change={handle_search} minDate={search.min_date}/>
                    </div>

                    <div style={{width: '250px'}}>
                        <MyInput
                            placeholder="Search by name, contact, email or bill no"
                            name="name" value={search.name} change={handle_search} enter={() => load_results()}/>
                    </div>
                </div>

                <div className="content_buttons">
                    <Button primary size='mini' icon='search' labelPosition="left" content="Load Results"
                            onClick={() => load_results()}/>
                </div>
            </div>

            <div className="content_container">
                <div className="window_content">
                    <Table celled striped compact={false} size='small' inverted color='grey' selectable structured style={{width: '1500px'}}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '100px'}} textAlign="center"/>
                                <Table.HeaderCell style={{width: '40px'}} textAlign="center">No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '80px'}} textAlign="center">Bill No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '80px'}} textAlign="center"/>
                                <Table.HeaderCell style={{width: '160px'}}>Client Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '100px'}}>Client No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '180px'}}>Date Created</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '130px'}}>Billing Group</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '90px'}}></Table.HeaderCell>
                                <Table.HeaderCell style={{width: '150px'}}>Test Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '130px'}}>Sample No</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '90px'}}>Test Status</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '100px'}}>Bill Amount</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '100px'}}>Bill Balance</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {bills.map((bill, index) => display_bill(index, bill))}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </>
    )
}
