import React from "react";
import {Button, Form, Modal, TextArea} from "semantic-ui-react";
import {ToastsStore} from "react-toasts";
import {ApiRequest} from "../../../../utils/ApiRequest";

export default function AuthorizeOrder(params: { bill_id: number, close: (authorized: boolean) => void }) {
    const [authorizing, setAuthorizing] = React.useState(false)
    const [reason, setReason] = React.useState("")

    const cancel_order = () => {
        if (reason.trim().length < 5) {
            ToastsStore.error("Enter a valid reason for authorising order")
        } else {
            setAuthorizing(true)
            ApiRequest.authorize_billing_order({bill_id: params.bill_id, reason: reason})
                .then((response) => {
                    setAuthorizing(false)
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            ToastsStore.success("Bill authorized successfully")
                            params.close(true)
                        } else if (response.data.code === 2) {
                            ToastsStore.error("Bill cannot be authorized, possibly was already authorized")
                        }
                    } else {
                        ToastsStore.error("Error while authorizing order, please retry")
                    }
                })
                .catch(() => {
                    setAuthorizing(false)
                    ToastsStore.error("Error while authorizing order, please retry")
                })
        }
    }

    React.useEffect(() => {
        setReason("")
    }, [params.bill_id])

    return (
        <>
            <Modal open={params.bill_id > 0} basic size='mini' centered={false} closeOnDimmerClick={false} closeOnDocumentClick={false}>
                <div className="modal_container">
                    <span className="form_header">AUTHORIZE (BILL NO: {params.bill_id})</span>

                    <div className="form_container">
                        <Form>
                            <span className="label mt-0">Enter reason for authorizing order</span>
                            <TextArea
                                placeholder='Write reason here...' value={reason} rows={5}
                                onChange={(event, data) => setReason(data.value as string)}/>
                        </Form>
                    </div>

                    <div className="button_container">
                        <div className="row m-0">
                            <div className="col-2 pl-0 pr-1">
                                <Button negative icon="close" size="mini" fluid disabled={authorizing}
                                        onClick={() => params.close(false)}/>
                            </div>
                            <div className="col-5 px-1">
                                <Button negative icon="trash" labelPosition="left" size="mini" fluid content='Clear Reason'
                                        onClick={() => setReason('')} disabled={authorizing}/>
                            </div>
                            <div className="col-5 px-0">
                                <Button positive icon="save" labelPosition="left" size="mini" fluid
                                        content="Authorize Order" onClick={cancel_order} disabled={authorizing} loading={authorizing}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
