import {Loading} from "../../../../utils/components/Loading";
import React from "react";
import {Button, Modal} from "semantic-ui-react";
import {MyDateInput} from "../../../../utils/components/MyDateInput";
import Utils from "../../../../utils/utils/Utils";
import {ApiRequest} from "../../../../utils/ApiRequest";
import {ToastsStore} from "react-toasts";

export default function PrintReports(params: { show: boolean, client_id: number, client_name: string, close: () => void }) {
    const [loading, setLoading] = React.useState({show: false, message: ""})

    const [search, setSearch] = React.useState({min_date: Utils.today(), max_date: Utils.today()})
    const handle_search = (name: string, value: string | string[]) => {
        setSearch({...search, [name]: value})
    }

    const [url, setUrl] = React.useState("")
    const load_reports = () => {
        setLoading({show: true, message: "Printing sample results, please wait"})
        ApiRequest.print_doctor_report({client_id: params.client_id, start_date: search.min_date, end_date: search.max_date})
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setUrl(response.data.url)
                } else {
                    ToastsStore.error("Error while loading sample results, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while loading sample results, please retry")
            })
    }

    React.useEffect(() => {
        setUrl("")
    }, [params.show])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>

            <Modal open={params.show} basic size='large' centered={false} closeOnDimmerClick={false} closeOnDocumentClick={false}>
                <div className="modal_container" style={{height: 'calc(100vh - 100px)', background: 'white'}}>
                    <div className="content_bar">
                        <div className="search_bar">
                            <div>
                                <MyDateInput
                                    value={search.min_date} name="min_date" placeholder="Min Date"
                                    change={handle_search} maxDate={search.max_date}/>
                            </div>

                            <div>
                                <MyDateInput
                                    value={search.max_date} name="max_date" placeholder="Min Date"
                                    change={handle_search} minDate={search.min_date} maxDate={Utils.today()}/>
                            </div>
                        </div>

                        <div className="content_buttons">
                            <Button primary size='mini' icon='close' labelPosition="left" content="Close Window" onClick={params.close}/>

                            <Button primary size='mini' icon='search' labelPosition="left" content="Load Reports" onClick={load_reports}/>
                        </div>
                    </div>

                    <div className="content_container">
                        {url !== "" && <iframe src={`${Utils.BASE_URL}${url}`} style={{width: '100%', height: '100%'}} frameBorder="0"/>}
                    </div>
                </div>
            </Modal>
        </>
    )
}
