import {useAppSelector} from "../../../utils/store/store";
import {getUser} from "../../../utils/store/userSlice";
import React from "react";
import Utils from "../../../utils/utils/Utils";
import {Branch} from "../../settings/BranchManagement";
import {MyDateInput} from "../../../utils/components/MyDateInput";
import {MySelect} from "../../../utils/components/MySelect";
import {Button, Table} from "semantic-ui-react";
import {ApiRequest} from "../../../utils/ApiRequest";
import {ToastsStore} from "react-toasts";
import {Loading} from "../../../utils/components/Loading";
import {DisplayPDF} from "../../../utils/components/DisplayPDF";
import moment from "moment/moment";
import {SystemUser} from "../../../utils/utils/Models";

interface Sale {
    sale_id: number
    orderer: string
    verifier: string
    items: SaleItem[]
    total_price: number
    total_discount: number
    client_name: string
    sale_type: 'Cash' | 'Credit' | 'Insurance'
    time_ordered: string
    time_verified: string
}

interface SaleItem {
    name: string
    stock_id: number
    quantity: number
    unit_price: number
    available: number
}

export default function SalesReport(params: { branches: Branch[], users: SystemUser[] }) {
    const user = useAppSelector(getUser)

    const [showPDF, setShowPDF] = React.useState({show: false, url: ''})
    const [loading, setLoading] = React.useState({show: false, message: ""})
    const [sales, setSales] = React.useState(Array<Sale>())
    const [search, setSearch] = React.useState<{
        branch_id: number, ordered_by: number, verified_by: number, min_date: string, max_date: string,
        sale_type: 'Cash' | 'Credit' | 'Insurance' | 'All'
    }>({
        branch_id: user.branch_id, max_date: Utils.today(), min_date: Utils.today(), verified_by: user.user_id, sale_type: 'All', ordered_by: 0
    })
    const handle_search = (name: string, value: string | number) => {
        setSearch({...search, [name]: value})
    }

    const search_orders = (output: 'pdf' | 'list') => {
        setLoading({message: "Loading sales list, please wait", show: true})
        ApiRequest.get_sales_reports({
            branch_id: search.branch_id, verified_by: search.verified_by, output: output, sale_type: search.sale_type,
            min_date: search.min_date, max_date: search.max_date, ordered_by: search.ordered_by
        })
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setSales(response.data['sales'])
                    if (output === 'pdf') {
                        setShowPDF({url: response.data.url, show: true})
                    }
                } else {
                    ToastsStore.error("Error while loading sales, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while loading sales, please retry")
            })
    }

    const display_sale = (aSale: Sale, index: number) => {
        const rowCount = aSale.items.length
        return aSale.items.map((aItem, aIndex) =>
            aIndex === 0 ?
                <Table.Row key={`${aSale.sale_id}_${aIndex}`}>
                    <Table.Cell rowSpan={rowCount} style={{width: '30px'}} textAlign="center">{index + 1}</Table.Cell>
                    <Table.Cell rowSpan={rowCount} style={{width: '120px'}}>{moment(aSale.time_verified).format("lll")}</Table.Cell>
                    <Table.Cell rowSpan={rowCount} style={{width: '120px'}}>{aSale.client_name}</Table.Cell>
                    <Table.Cell rowSpan={rowCount} style={{width: '120px'}}>{aSale.orderer}</Table.Cell>
                    <Table.Cell rowSpan={rowCount} style={{width: '120px'}}>{aSale.verifier}</Table.Cell>

                    <Table.Cell style={{width: '100px'}}>{aSale.items[aIndex].name}</Table.Cell>
                    <Table.Cell style={{width: '40px'}} textAlign={'center'}>{aSale.items[aIndex].quantity}</Table.Cell>
                    <Table.Cell style={{width: '60px'}} textAlign={'center'}>
                        {(aSale.items[aIndex].unit_price * aSale.items[aIndex].quantity).toLocaleString()}
                    </Table.Cell>

                    <Table.Cell rowSpan={rowCount} style={{width: '60px'}}>{aSale.sale_type}</Table.Cell>
                    <Table.Cell rowSpan={rowCount} style={{width: '60px'}} textAlign={'center'}>{aSale.total_discount.toLocaleString()}</Table.Cell>
                    <Table.Cell rowSpan={rowCount} style={{width: '60px'}} textAlign={'center'}>{aSale.total_price.toLocaleString()}</Table.Cell>
                    <Table.Cell rowSpan={rowCount} style={{width: '60px'}} textAlign={'center'}>{(aSale.total_price - aSale.total_discount).toLocaleString()}</Table.Cell>
                </Table.Row>
                :
                <Table.Row key={`${aSale.sale_id}_${aIndex}`}>
                    <Table.Cell style={{width: '100px'}}>{aSale.items[aIndex].name}</Table.Cell>
                    <Table.Cell style={{width: '40px'}} textAlign={'center'}>{aSale.items[aIndex].quantity}</Table.Cell>
                    <Table.Cell style={{width: '60px'}} textAlign={'center'}>
                        {(aSale.items[aIndex].unit_price * aSale.items[aIndex].quantity).toLocaleString()}
                    </Table.Cell>
                </Table.Row>
        )
    }

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({message: "", show: false})}/>
            <DisplayPDF show={showPDF.show} url={showPDF.url} close={() => setShowPDF({url: '', show: false})}/>

            <div className="content_bar">
                <div className="search_bar">
                    <div>
                        <MyDateInput
                            value={search.min_date} name="min_date" placeholder="Min Date"
                            change={handle_search} maxDate={search.max_date}/>
                    </div>

                    <div>
                        <MyDateInput
                            value={search.max_date} name="max_date" placeholder="Min Date"
                            change={handle_search} minDate={search.min_date} maxDate={Utils.today()}/>
                    </div>

                    <div style={{width: '200px'}}>
                        <MySelect
                            name="branch_id" value={search.branch_id} placeholder="Search by branch"
                            change={(value) => handle_search('branch_id', value as number)}
                            options={params.branches.map((aBranch) => ({text: aBranch.branch_name, value: aBranch.branch_id}))}/>
                    </div>

                    <div style={{width: '200px'}}>
                        <MySelect
                            name="verified_by" value={search.verified_by} placeholder="Search by dispatcher"
                            change={(value) => handle_search('verified_by', value as number)}
                            options={
                                params.users
                                    .filter((aUser) => aUser.branch_id === search.branch_id)
                                    .map((aUser) => ({text: aUser.username, value: aUser.user_id}))
                            }/>
                    </div>

                    <div>
                        <MySelect
                            name="sale_type" value={search.sale_type} placeholder="Select sale type"
                            change={(value) => handle_search('sale_type', value as string)}
                            options={[
                                {text: "View all sales", value: 'All'},
                                {text: "Cash Sales", value: 'Cash'},
                                {text: "Credit Sale", value: 'Credit'},
                                {text: "Insurance Payment", value: 'Insurance'}
                            ]}/>
                    </div>
                </div>

                <div className="content_buttons">
                    <Button primary size='mini' icon='file pdf' labelPosition="left" content="PDF Report" onClick={() => search_orders('pdf')}/>
                    <Button primary size='mini' icon='search' labelPosition="left" content="Search Sales" onClick={() => search_orders('list')}/>
                </div>
            </div>

            <div className="content_container">
                <div className="row h-100 m-0 w-100">
                    <div className="col-md-12 p-1 h-100">
                        <div className="window_content">
                            <Table celled striped compact={false} size='small' inverted color='grey' selectable structured>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell rowSpan={2} style={{width: '40px'}} textAlign="center">No.</Table.HeaderCell>
                                        <Table.HeaderCell rowSpan={2} style={{width: '120px'}}>Time Dispatched</Table.HeaderCell>
                                        <Table.HeaderCell rowSpan={2} style={{width: '120px'}}>Client Name</Table.HeaderCell>
                                        <Table.HeaderCell rowSpan={2} style={{width: '120px'}}>Ordered By</Table.HeaderCell>
                                        <Table.HeaderCell rowSpan={2} style={{width: '120px'}}>Verified By</Table.HeaderCell>

                                        <Table.HeaderCell colSpan={3} style={{width: '200px'}} textAlign="center">Items Sold</Table.HeaderCell>
                                        <Table.HeaderCell colSpan={4} style={{width: '240px'}} textAlign="center">Sales Information</Table.HeaderCell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '100px'}}>Item Name</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '40px'}} textAlign={'center'}>Quantity</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '60px'}} textAlign={'center'}>Sale Price</Table.HeaderCell>

                                        <Table.HeaderCell style={{width: '60px'}}>Order Type</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '60px'}} textAlign={'center'}>Discount</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '60px'}} textAlign={'center'}>Total Price</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '60px'}} textAlign={'center'}>Actual Price</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {sales.map((sale, index) => display_sale(sale, index))}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
