import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "./store";

export interface User {
    roles: string[]
    user_name: string
    user_id: number
    bearer_token: string
    branch_id: number
    mobile_contact: string
}

interface UserState {
    user: User
    fcm_token: { saved: boolean, token: string }
    counter: number
}

const initialState: UserState = {
    counter: 0,
    fcm_token: {saved: false, token: ""},
    user: {roles: [], user_id: 0, user_name: "", bearer_token: "", branch_id: 0, mobile_contact: ""}
}

const userSlice = createSlice({
    name: 'user', initialState,
    reducers: {
        updateUser: (state: Draft<typeof initialState>, action: PayloadAction<User>) => {
            state.user = action.payload
        },
        logoutUser: (state: Draft<typeof initialState>) => {
            state.user = {roles: [], user_id: 0, user_name: "", bearer_token: "", branch_id: 0, mobile_contact: ""}
            state.fcm_token.saved = false
        },
        updateWebToken: (state: Draft<typeof initialState>, action: PayloadAction<string>) => {
            state.user.bearer_token = action.payload
        },
        updateFCMToken: (state: Draft<typeof initialState>, action: PayloadAction<{ saved: boolean, token: string }>) => {
            state.fcm_token = action.payload
        },
        updateCounter: (state: Draft<typeof initialState>, action: PayloadAction<number>) => {
            state.counter = action.payload
        },
    },
});

// Selectors
export const getUser = (state: RootState) => state.user.user
export const getCounter = (state: RootState) => state.user.counter
export const getFCMToken = (state: RootState) => state.user.fcm_token

// Reducers and actions
export const {updateUser, updateCounter, updateWebToken, updateFCMToken, logoutUser} = userSlice.actions

export default userSlice.reducer
